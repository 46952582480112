import _ from "lodash";
import PropTypes from "prop-types";

import useEndDate from "./hooks/useEndDate";

import useFetch from "../../api/useFetch";
import { API_ENDPOINTS } from "../../api/constants";
import {
  ORDER_FLOW_TIME_FRAME_MONTHLY,
  ORDER_FLOW_TIME_FRAME_WEEKLY,
} from "../../utils/constants";
import LoadingSpinner from "../../utils/LoadingSpinner";

import OverallTradingPriceGraph from "./graph-components/OverallTradingPriceGraph";
import {
  TIME_FRAME_MONTHLY,
  TIME_FRAME_WEEKLY,
} from "./graph-components/constants";

/**
 * Renders the "Trading Price" graphs in Company Profile.
 */
const OverallTradingPriceGraphView = (props) => {
  const orderFlowQueryInfo = useFetch(
    `${API_ENDPOINTS.COMPANY_VOLUME_TRADING_DATA(props.companyId, {
      time_frame: props.timeFrame,
    })}?time_frame=${props.timeFrame}`
  );

  const tradingPriceEndDate = useEndDate(props.timeFrame);

  if (orderFlowQueryInfo.isLoading) {
    return <LoadingSpinner />;
  }

  const orderFlowData = orderFlowQueryInfo.cleanedData?.data;

  const graphTimeFrame =
    props.timeFrame === ORDER_FLOW_TIME_FRAME_MONTHLY
      ? TIME_FRAME_MONTHLY
      : TIME_FRAME_WEEKLY;

  const dollarWeightedAverage = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.overall_dwa,
  }));
  const simpleAverage = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.overall_simple_average,
  }));
  const zxIndexPrice = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.zx_index_price,
  }));
  const zxIndexValueTrailing = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.zx_index_value_trailing,
  }));
  const robustnessScore = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.robustness,
  }));

  return (
    <OverallTradingPriceGraph
      title="Price"
      hasDataAccess={props.hasDataAccess}
      includeRobustnessScoreAxis={props.includeRobustnessScoreAxis}
      endDate={tradingPriceEndDate}
      timeFrame={graphTimeFrame}
      dollarWeightedAverage={dollarWeightedAverage}
      simpleAverage={simpleAverage}
      showDollarWeightedAverage={props.showDollarWeightedAverage}
      showSimpleAverage={props.showSimpleAverage}
      zxIndexPrice={zxIndexPrice}
      zxIndexValueTrailing={zxIndexValueTrailing}
      robustnessScore={robustnessScore}
    />
  );
};

OverallTradingPriceGraphView.propTypes = {
  companyId: PropTypes.string.isRequired,
  hasDataAccess: PropTypes.bool,
  includeRobustnessScoreAxis: PropTypes.bool,
  showDollarWeightedAverage: PropTypes.bool,
  showSimpleAverage: PropTypes.bool,
  timeFrame: PropTypes.oneOf([
    ORDER_FLOW_TIME_FRAME_MONTHLY,
    ORDER_FLOW_TIME_FRAME_WEEKLY,
  ]),
};

OverallTradingPriceGraphView.defaultProps = {
  hasDataAccess: false,
  includeRobustnessScoreAxis: false,
  showDollarWeightedAverage: true,
  showSimpleAverage: true,
  timeFrame: ORDER_FLOW_TIME_FRAME_MONTHLY,
};

export default OverallTradingPriceGraphView;
