import React from "react";
import { DateTime } from "luxon";
import { ListStyles, ListItem, LinkTypes } from "yuka";
import PropTypes from "prop-types";

const CompanyArticle = ({ article }) => {
  const publishedOn = DateTime.fromISO(article.published_at);

  return (
    <ListItem
      listStyle={ListStyles.TWO_LINE}
      text={article.title}
      subtext={`${article.original_url} | ${publishedOn.toLocaleString(
        DateTime.DATE_SHORT
      )}`}
      url={article.original_url}
      linkType={LinkTypes.EXTERNAL_LINK}
      onClick={() => {}}
    />
  );
};

CompanyArticle.propTypes = {
  article: PropTypes.shape({
    published_at: PropTypes.string,
    original_url: PropTypes.string,
    title: PropTypes.string,
    source_name: PropTypes.string,
  }),
};

CompanyArticle.defaultProps = {
  article: null,
};

export default CompanyArticle;
