import _ from "lodash";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardStyles, HyperLink, LinkTypes, List } from "yuka";

import CompanyArticle from "./CompanyArticle";

import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";
import useFetch from "../../api/useFetch";
import { API_ENDPOINTS } from "../../api/constants";
import { StyledCenteredBody } from "../../utils/StyledComponents";

const CompanyNews = (props) => {
  useEffect(() => {
    if (props.companyName) {
      MixpanelEvents.viewCompanyNews(props.companyName);
    }
  }, [props.companyName]);

  // Query for company articles.
  const companyArticleQuery = useFetch(
    API_ENDPOINTS.COMPANY_ARTICLES(props.companyId)
  );
  const companyArticles = companyArticleQuery?.cleanedData?.results;

  const cardContent = _.isEmpty(companyArticles) ? (
    <StyledCenteredBody>Latest news not available</StyledCenteredBody>
  ) : (
    <List divider>
      {_.map(companyArticles, (article) => (
        <CompanyArticle key={article.id} article={article} />
      ))}
    </List>
  );

  return (
    <Card
      title="Latest News"
      cardStyle={
        _.isEmpty(companyArticles) ? CardStyles.PADDED : CardStyles.TITLE
      }
      tagline={
        <>
          <span>
            Convenience links for news & press releases are powered by{" "}
          </span>
          <HyperLink
            url="https://mention.com/en/"
            linkTypes={LinkTypes.EXTERNAL_LINK}
          >
            Mention
          </HyperLink>
          <span>.</span>
        </>
      }
    >
      {cardContent}
    </Card>
  );
};

CompanyNews.propTypes = {
  companyId: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
};

export default CompanyNews;
