import {
  Fonts,
  Modal,
  YukaColorPalette,
  ModalStyles,
  ColorPalette,
  Button,
  PlusIcon,
} from "yuka";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Form } from "react-final-form";
import PropTypes from "prop-types";

import ValuationPriceComponentRow from "./ValuationPriceComponentRow";
import ValuationComponentsHeader from "./ValuationComponentsHeader";

import {
  VALUATION_COMPONENT_FUNDING_ROUND,
  VALUATION_COMPONENT_OTHER,
  VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON,
  VALUATION_COMPONENT_REPORTED_MARKS,
  VALUATION_COMPONENT_ZX_INDEX_VALUE,
  VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING,
} from "../constants";

import ComputedValuationFooterRow from "./ComputedValuationFooterRow";
import IndexComparisonComponentRow from "./IndexComparisonComponentRow";
import ZXIndexPriceComponentRow from "./ZXIndexPriceComponentRow";
import FundingRoundPriceComponentRow from "./FundingRoundPriceComponentRow";
import FundMarksPriceComponentRow from "./FundMarksPriceComponentRow";
import { API_ENDPOINTS } from "../../api/constants";
import useWrite from "../../api/useWrite";
import useDelete from "../../api/useDelete";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";
import {
  componentTypeFieldName,
  currentValueFieldName,
  initialValueFieldName,
  nameFieldName,
  weightFieldName,
} from "../utils/valuationCalculatorFormNameUtils";
import ComponentRowDetailPanel from "./ComponentRowDetailPanel";
import ValuationCalculatorModalHeader from "./ValuationCalculatorModalHeader";

const StyledModal = styled(Modal)`
  width: 900px;
`;

const StyledModalContent = styled.div`
  background: ${YukaColorPalette.surface1};
  border-top: 1px solid ${ColorPalette.white15};
  width: 100%;
`;

const StyledTwoColumns = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`;

const StyledCriteriaList = styled.div`
  height: 460px;
  overflow: auto;
`;

const StyledAddCriterionButtonContainer = styled.div`
  padding: 16px;
`;

const getRenderedComponentRow = (componentType) => {
  switch (componentType) {
    case VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON:
      return IndexComparisonComponentRow;
    case VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING:
    case VALUATION_COMPONENT_ZX_INDEX_VALUE:
      return ZXIndexPriceComponentRow;
    case VALUATION_COMPONENT_FUNDING_ROUND:
      return FundingRoundPriceComponentRow;
    case VALUATION_COMPONENT_REPORTED_MARKS:
      return FundMarksPriceComponentRow;
    default:
      return ValuationPriceComponentRow;
  }
};

const ValuationCalculatorModal = ({
  onClose,
  companyId,
  companyName,
  previousValuations,
  valuation,
}) => {
  const scrollAreaRef = useRef(null);
  const todayDate = DateTime.now().toISODate();
  const valuationDate = valuation ? valuation.valuation_date : todayDate;

  const latestValuation = useMemo(() => {
    if (previousValuations && previousValuations.length) {
      return previousValuations[0];
    }
    return {};
  }, [previousValuations]);

  const defaultComponents = useMemo(() => {
    // When users are creating new valuations, we should default to include
    // The latest ZX index price, latest funding round, latest fund marks, and sector growth.
    // We'll need to configure the default weights in the future based on our formulas.
    return [
      {
        id: Math.random(),
        name: "Secondary price",
        valuation_component_type: VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING,
        weight: 0,
        effective_as_of: todayDate,
      },
      {
        id: Math.random(),
        name: "Primary financing",
        valuation_component_type: VALUATION_COMPONENT_FUNDING_ROUND,
        weight: 0,
      },
      {
        id: Math.random(),
        name: "Reported marks",
        valuation_component_type: VALUATION_COMPONENT_REPORTED_MARKS,
        weight: 0,
        effective_as_of: todayDate,
      },
      {
        id: Math.random(),
        name: "Index performance",
        valuation_component_type:
          VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON,
        weight: 0,
        effective_as_of: todayDate,
      },
    ];
  }, [todayDate]);

  const [customComponents, setCustomComponents] = useState([]);

  const activeWeightedComponents = useMemo(() => {
    if (valuation) {
      return valuation.components.map((component) => ({
        ...component,
        weight: component.weight * 100,
      }));
    }
    return defaultComponents.concat(customComponents);
  }, [valuation, defaultComponents, customComponents]);

  const selectedComponent = useMemo(
    () => activeWeightedComponents?.[0],
    [activeWeightedComponents]
  );
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const createValuation = useWrite(API_ENDPOINTS.CUSTOM_VALUATIONS(), false, {
    message: "Valuation created",
  });

  const deleteValuation = useDelete(API_ENDPOINTS.CUSTOM_VALUATIONS(), {
    message: "Valuation deleted",
  });

  const deleteModal = (
    <Modal
      onClose={() => setShowDeleteConfirmation(false)}
      ctaText="Delete"
      title="Confirm Delete"
      height={158}
      width={400}
      onClickCta={(event) => {
        MixpanelEvents.deleteCustomValuation(
          companyName,
          valuation.valuation_date
        );
        deleteValuation.mutate({ id: valuation.id });
        setShowDeleteConfirmation(false);
        onClose(event, true);
      }}
    >
      <span>Are you sure you want to delete this valuation?</span>
    </Modal>
  );

  const onClickAlt = () => {
    if (valuation) {
      return setShowDeleteConfirmation(true);
    }
  };

  useEffect(() => {
    // Auto scroll to the end if you're adding a new criterion.
    if (scrollAreaRef?.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current?.scrollHeight;
    }
  }, [activeWeightedComponents, scrollAreaRef]);

  const onSubmit = (values) => {
    const components = activeWeightedComponents.map((weightedComponent) => {
      const private_market_index_id =
        weightedComponent.valuation_component_type ===
        VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON
          ? values.selected_sector_index
          : null;
      const [name, weight, valuationComponentType] = [
        values[nameFieldName(weightedComponent.id)],
        values[weightFieldName(weightedComponent.id)] / 100,
        values[componentTypeFieldName(weightedComponent.id)],
      ];

      const componentData = {
        name,
        weight,
        valuation_component_type: valuationComponentType,
        effective_as_of: values.valuation_date,
        comparison_private_market_index_id: private_market_index_id,
        comparison_company_id: null, // Supported in future release.
      };

      if (
        valuationComponentType ===
        VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON
      ) {
        componentData.percentage_change =
          values[currentValueFieldName(weightedComponent.id)];
      } else {
        componentData.price_per_share =
          values[currentValueFieldName(weightedComponent.id)];
      }
      return componentData;
    });
    const postData = {
      company: companyId,
      valuation_date: values.valuation_date,
      computed_value: null, // Will be computed server-side.
      previous_valuation_value: values.previous_pps,
      previous_valuation_date: values.previous_date,
      components,
    };

    if (latestValuation) {
      postData.previous_valuation = latestValuation.id;
    }

    createValuation.mutate(postData);
    MixpanelEvents.createCustomValuation(companyName, values.valuation_date);
    onClose(null, true);
  };

  const defaultSectorIndex = useMemo(() => {
    if (valuation) {
      // See if there are any components in the valuation with the sector index.
      const sectorIndexComponent = valuation.components.find((component) =>
        Boolean(component.comparison_private_market_index_id)
      );
      if (sectorIndexComponent) {
        return sectorIndexComponent.comparison_private_market_index_id;
      }
    }
    // Will be handled downstream.
    return undefined;
  }, [valuation]);

  const initialValues = useMemo(
    () => ({
      selected_row: selectedComponent?.id,
      valuation_date: valuationDate,
      previous_pps: valuation
        ? valuation.previous_valuation_value
        : latestValuation.computed_value,
      previous_date: valuation
        ? valuation.previous_valuation_date
        : latestValuation.valuation_date,
      // Attempt to set the default sector index to match the company's sector.
      selected_sector_index: defaultSectorIndex,
    }),
    [
      selectedComponent?.id,
      valuationDate,
      valuation,
      latestValuation,
      defaultSectorIndex,
    ]
  );

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, handleSubmit, valid, submitting, form: { change } }) => {
        const addNewComponent = () => {
          MixpanelEvents.addCustomCriterion(companyName);
          const newId = Math.random();
          setCustomComponents([
            ...customComponents,
            {
              id: newId,
              name: null,
              valuation_component_type: VALUATION_COMPONENT_OTHER,
              weight: 0,
              effective_as_of: todayDate,
            },
          ]);
          change("selected_row", newId);
        };
        const removeComponent = (componentId) => {
          MixpanelEvents.removeCustomCriterion(
            companyName,
            values[nameFieldName(componentId)]
          );
          // Find the component in the list.
          setCustomComponents(
            customComponents.filter((component) => component.id !== componentId)
          );
          // Change all corresponding field values to undefined.
          change(nameFieldName(componentId), undefined);
          change(weightFieldName(componentId), undefined);
          change(currentValueFieldName(componentId), undefined);
          change(componentTypeFieldName(componentId), undefined);
          change(initialValueFieldName(componentId), undefined);
          // Reset selected component to the top.
          change("selected_row", selectedComponent?.id);
        };
        return (
          <StyledModal
            modalStyle={ModalStyles.MINIMAL}
            cancelText={valuation ? "Close" : "Cancel"}
            ctaText="Save"
            ctaDisabled={valuation || !valid || submitting}
            onClickCta={valuation ? null : handleSubmit}
            altText="Delete"
            onClickAlt={valuation ? onClickAlt : null}
            title={
              <span>
                Valuation Calculator{" "}
                <Fonts.Headline2theme50>for</Fonts.Headline2theme50>{" "}
                {companyName}
              </span>
            }
            onClose={onClose}
          >
            <ValuationCalculatorModalHeader
              companyName={companyName}
              valuation={valuation}
              previousValuations={previousValuations}
            />
            <StyledModalContent>
              <form onSubmit={handleSubmit}>
                <StyledTwoColumns>
                  <StyledCriteriaList ref={scrollAreaRef}>
                    <ValuationComponentsHeader />
                    {activeWeightedComponents.map(
                      (weightedComponent, index) => {
                        const Component = getRenderedComponentRow(
                          weightedComponent.valuation_component_type
                        );
                        const initialValue =
                          weightedComponent.price_per_share ||
                          weightedComponent.percentage_change ||
                          null;

                        return (
                          <Component
                            id={weightedComponent.id}
                            key={`weighted_component_${index}`}
                            companyId={companyId}
                            companyName={companyName}
                            componentType={
                              weightedComponent.valuation_component_type
                            }
                            name={weightedComponent.name}
                            weight={weightedComponent.weight}
                            helpText={weightedComponent.help_text}
                            editable={!initialValue}
                            initialValue={initialValue}
                          />
                        );
                      }
                    )}
                    {!valuation && (
                      <StyledAddCriterionButtonContainer>
                        <Button
                          leadingIcon={PlusIcon}
                          onClick={addNewComponent}
                        >
                          Add criterion
                        </Button>
                      </StyledAddCriterionButtonContainer>
                    )}
                  </StyledCriteriaList>
                  <ComponentRowDetailPanel
                    companyName={companyName}
                    companyId={companyId}
                    editable={!valuation}
                    removeComponent={removeComponent}
                  />
                </StyledTwoColumns>
                <ComputedValuationFooterRow
                  onDelete={onClickAlt}
                  onClose={onClose}
                  onSubmit={handleSubmit}
                  companyName={companyName}
                  valuation={valuation}
                />
              </form>
            </StyledModalContent>
            {showDeleteConfirmation && deleteModal}
          </StyledModal>
        );
      }}
    </Form>
  );
};

ValuationCalculatorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  previousValuations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      computed_value: PropTypes.string,
      valuation_date: PropTypes.string,
    })
  ),
  valuation: PropTypes.shape({
    id: PropTypes.number,
    computed_value: PropTypes.string,
    valuation_date: PropTypes.string,
    previous_valuation_date: PropTypes.string,
    previous_valuation_value: PropTypes.string,
    components: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        price_per_share: PropTypes.string,
        percentage_change: PropTypes.string,
        valuation_component_type: PropTypes.number,
      })
    ),
  }),
};

ValuationCalculatorModal.defaultProps = {
  companyName: "",
  previousValuations: [],
  valuation: null,
};

export default ValuationCalculatorModal;
