import { Card, ValueCard, Fonts } from "yuka";
import { DateTime } from "luxon";
import styled from "styled-components";

import { shortMoneyFormat } from "../utils/displayFormatUtils";
import PropTypes from "prop-types";
import useValuationMetadata from "./hooks/useValuationMetadata";

const StyledValuationStatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.$columns}, 1fr);
  gap: 16px;

  > * {
    flex: 1;
  }
`;

const LoadingCard = styled(Card)`
  height: 107px; // The full height of the populated ValueCard.
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ValuationStats = ({ companyId, latestValuation }) => {
  const {
    orderFlowReport,
    orderFlowReportIsLoading,
    reportedMarks,
    reportedMarksIsLoading,
    fundingRound,
    fundingRoundIsLoading,
  } = useValuationMetadata(companyId);

  const latestZXIndexPrice = orderFlowReport?.zx_index_value_trailing;
  const latestRobustness = orderFlowReport?.robustness;

  return (
    <StyledValuationStatsContainer $columns={latestValuation ? 4 : 3}>
      {latestValuation && (
        <ValueCard
          headline="Your Latest Valuation"
          value={shortMoneyFormat(latestValuation?.computed_value)}
          supportingText={
            latestValuation?.valuation_date
              ? `As of ${DateTime.fromISO(
                  latestValuation?.valuation_date
                ).toFormat("MM/dd/yyyy")}`
              : "--"
          }
          unit="USD / share"
        />
      )}
      {!orderFlowReportIsLoading ? (
        <ValueCard
          headline="ZX Index Value (Trailing)"
          value={shortMoneyFormat(latestZXIndexPrice)}
          supportingText={
            latestRobustness ? `Robustness Score ${latestRobustness}` : "--"
          }
          unit="USD / share"
        />
      ) : (
        <LoadingCard>
          <Fonts.Body1theme50>Loading...</Fonts.Body1theme50>
        </LoadingCard>
      )}
      {!fundingRoundIsLoading ? (
        <ValueCard
          headline="Last Funding Round"
          value={shortMoneyFormat(fundingRound?.price_per_share)}
          supportingText={`${fundingRound?.event || "--"} - ${
            fundingRound?.date
              ? DateTime.fromISO(fundingRound?.date).toFormat("MM/dd/yyyy")
              : "--"
          }`}
          unit="USD / share"
        />
      ) : (
        <LoadingCard>
          <Fonts.Body1theme50>Loading...</Fonts.Body1theme50>
        </LoadingCard>
      )}
      {!reportedMarksIsLoading ? (
        <ValueCard
          headline="Reported Marks"
          value={shortMoneyFormat(reportedMarks?.[1]?.weighted_avg)}
          supportingText={`Report Period ${reportedMarks?.[0] || "--"}`}
          unit="USD / share"
        />
      ) : (
        <LoadingCard>
          <Fonts.Body1theme50>Loading...</Fonts.Body1theme50>
        </LoadingCard>
      )}
    </StyledValuationStatsContainer>
  );
};

ValuationStats.propTypes = {
  companyId: PropTypes.string.isRequired,
  latestValuation: PropTypes.shape({
    computed_value: PropTypes.number,
    valuation_date: PropTypes.string,
  }),
};

ValuationStats.defaultProps = {
  latestValuation: null,
};

export default ValuationStats;
