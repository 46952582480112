import _ from "lodash";
import { DateTime } from "luxon";
import { BadgeStyles } from "yuka";

import { monthTimeDifference, weekTimeDifference } from "./timeFrameUtils";
import { TIME_FRAME_MONTHLY, TIME_FRAME_WEEKLY } from "./constants";

const ONE_MILLION = 1000000;
const MONTHS_IN_YEAR = 12;
const WEEKS_IN_YEAR = 52;

/**
 * In order to prevent graphs from dropping to 0 when a datapoint is missing,
 * we split the array into sub-arrays whenever we encounter a 0 y-value.
 *
 * @param {Array<object>} data - Graph data in the shape { x, y }
 * @returns {Array<Array<object>>}
 */
const splitZeroSeparator = (data) => {
  const returnArray = [];
  let currSubarray = [];

  _.forEach(data, (d) => {
    if ((d.y === 0 || _.isNull(d.y)) && !_.isEmpty(currSubarray)) {
      returnArray.push(_.clone(currSubarray));
      currSubarray = [];
    } else if (d.y !== 0 && !_.isNull(d.y)) {
      currSubarray.push(d);
    }
  });

  if (!_.isEmpty(currSubarray)) {
    returnArray.push(_.clone(currSubarray));
  }

  return returnArray;
};

const NUM_DATA_POINTS_PREVIEW = 3;
/**
 * If the user does not have pro data access, we should ensure we're only showing the
 * first 3 months worth of data on the graph.
 *
 * @param {Array<object>} graphArrayPoints
 * @param {boolean} hasProDataAccess
 * @returns {Array<object>}
 */
const filterHasDataAccess = (graphArrayPoints, hasProDataAccess) => {
  if (!hasProDataAccess) {
    return _.take(graphArrayPoints, NUM_DATA_POINTS_PREVIEW);
  }
  return graphArrayPoints;
};

/**
 * Takes an array of `OrderFlowReport` objects and transforms it into graph objects { x, y }
 * where x is the number of months ago and y is the value extracted from the report
 * with the given key `propertyName`.
 *
 * @typedef {object} DataPoint
 * @property {string} date - The date of the data point
 * @property {number} value - The value of the data point
 *
 * @param {Array<DataPoint>} data
 * @param {string} timeFrame
 * @param {string} endDate
 * @param {boolean} hasDataAccess
 * @param {boolean} inMillions
 * @returns {Array<object>}
 */
const createGraphArray = (
  data,
  timeFrame,
  endDate,
  hasDataAccess = false,
  inMillions = false
) =>
  filterHasDataAccess(
    _.orderBy(
      _.map(data, (d) => {
        let timeDifferenceFunc;

        switch (timeFrame) {
          case TIME_FRAME_MONTHLY:
            timeDifferenceFunc = monthTimeDifference;
            break;
          case TIME_FRAME_WEEKLY:
            timeDifferenceFunc = weekTimeDifference;
            break;
          default:
            timeDifferenceFunc = monthTimeDifference;
        }

        return {
          ...d,
          x: timeDifferenceFunc(DateTime.fromISO(d.date), endDate),
          y: _.isNull(d.value)
            ? null
            : Number(d.value) * (inMillions ? ONE_MILLION : 1),
        };
      }),
      "x"
    ),
    hasDataAccess
  );

// These two functions should be 1:1 with splitZeroSeparator and fillEmpty respectively
const isSplitGraphEmpty = (dataArray) => _.every(dataArray, _.isEmpty);
const isFilledGraphEmpty = (dataArray) =>
  _.every(dataArray, (arr) =>
    _.every(arr, (dataPoint) => _.isNil(dataPoint.y))
  );

// Used for hover interaction
const getMonthlyHoverPointsArray = (endDate, yPlaceholder = 0) => {
  const points = _.times(MONTHS_IN_YEAR, (monthsAgo) => {
    const date = DateTime.fromISO(endDate).minus({ months: monthsAgo });
    return {
      x: monthTimeDifference(date, endDate),
      y: yPlaceholder,
    };
  });

  return _.orderBy(points, "x");
};

const getWeeklyHoverPointsArray = (endDate, yPlaceholder = 0) => {
  const points = _.times(WEEKS_IN_YEAR, (weeksAgo) => {
    const date = DateTime.fromISO(endDate).minus({ weeks: weeksAgo });
    return {
      x: weekTimeDifference(date, endDate),
      y: yPlaceholder,
    };
  });

  return _.orderBy(points, "x");
};

/*
 * Given a full robustness score data set, and a specific time frame tick value, finds the
 * robustness score y-value for which the x-value is the same as the time frame tick value.
 *
 * Used to get the robustness score value in the chart for a specific time frame.
 */
const getRobustnessTickValue = (robustnessScore, timeframeTickValue) => {
  if (!timeframeTickValue && timeframeTickValue !== 0) {
    return null;
  }
  // Get the uninterrupted list of robustness scores.
  const completeRobustnessList = _.flatten(robustnessScore);
  const foundRobustnessEntry = _.find(completeRobustnessList, {
    x: timeframeTickValue,
  });
  return foundRobustnessEntry || null;
};

/*
 * Given a value, use some thresholds determine the correct counter style.
 */
const getCounterStyleByValue = (value) => {
  const yellowThreshold = 3;
  const greenThreshold = 5;
  if (!value) {
    return BadgeStyles.DARK_GRAY;
  }
  if (value >= greenThreshold) {
    return BadgeStyles.BUY_GREEN;
  }
  if (value >= yellowThreshold) {
    return BadgeStyles.YELLOW;
  }
  return BadgeStyles.DARK_GRAY;
};

export {
  createGraphArray,
  getCounterStyleByValue,
  getMonthlyHoverPointsArray,
  getRobustnessTickValue,
  getWeeklyHoverPointsArray,
  isFilledGraphEmpty,
  isSplitGraphEmpty,
  splitZeroSeparator,
};
