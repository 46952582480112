import _ from "lodash";
import PropTypes from "prop-types";

import CompanyProfileLineGraph from "./CompanyProfileLineGraph";

import {
  GRAPH_COLORS,
  TIME_FRAME_MONTHLY,
  TIME_FRAME_WEEKLY,
} from "./constants";

/**
 * Renders a Trading Price graph showing:
 *   1. ZX Index Value
 *   2. Dollar-Weighted Average
 *   3. Simple Average
 */
const OverallTradingPriceGraph = (props) => (
  <CompanyProfileLineGraph
    title={props.title}
    dataSeriesArray={_.compact([
      {
        name: "ZX Index Value (Current)",
        color: GRAPH_COLORS.BLUE_500,
        tooltip:
          "Derived by applying proprietary weightings to the order flow data collected for the stated period (both bids and asks), based on the order type (firm order or indication of interest) and whether the order has been executed, with greater weight given to more recent orders.",
        graphData: props.zxIndexPrice,
      },
      {
        name: "ZX Index Value (Trailing)",
        color: GRAPH_COLORS.PINK_300,
        tooltip:
          "Derived by applying proprietary weightings to closed transactions, with greater weight given to more recent transactions and those with higher volume. This metric is designed to provide a view on where the most recent transaction has closed.",
        graphData: props.zxIndexValueTrailing,
      },
      props.showDollarWeightedAverage && {
        name: "Dollar-Weighted Average",
        color: GRAPH_COLORS.LIGHT_BLUE,
        tooltip:
          "Sum product of volume and price for all executed and unexecuted transactions",
        graphData: props.dollarWeightedAverage,
      },
      props.showSimpleAverage && {
        name: "Simple Average",
        color: GRAPH_COLORS.MAGENTA,
        tooltip:
          "Simple average of the prices  for all executed and unexecuted transactions",
        graphData: props.simpleAverage,
      },
    ])}
    includeRobustnessScoreAxis={props.includeRobustnessScoreAxis}
    legendHeadline="Price"
    onNearestX={props.onNearestX}
    robustnessScore={props.robustnessScore}
    timeFrame={props.timeFrame}
    endDate={props.endDate}
    hasDataAccess={props.hasDataAccess}
  />
);

OverallTradingPriceGraph.propTypes = {
  endDate: PropTypes.string.isRequired,
  onNearestX: PropTypes.func,
  timeFrame: PropTypes.oneOf([TIME_FRAME_MONTHLY, TIME_FRAME_WEEKLY]),
  hasDataAccess: PropTypes.bool,
  includeRobustnessScoreAxis: PropTypes.bool,
  dollarWeightedAverage: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
    )
  ).isRequired,
  simpleAverage: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
    )
  ).isRequired,
  zxIndexPrice: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
    )
  ).isRequired,
  zxIndexValueTrailing: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
    )
  ),
  robustnessScore: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
    )
  ).isRequired,
  showDollarWeightedAverage: PropTypes.bool,
  showSimpleAverage: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

OverallTradingPriceGraph.defaultProps = {
  hasDataAccess: false,
  includeRobustnessScoreAxis: false,
  onNearestX: _.noop,
  showDollarWeightedAverage: true,
  showSimpleAverage: true,
  timeFrame: TIME_FRAME_MONTHLY,
};

export default OverallTradingPriceGraph;
