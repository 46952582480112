import React from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";

import {
  Button,
  ButtonStyles,
  Card,
  CardStyles,
  InputComponent,
  InputRow,
} from "yuka";

import { FinalFormField } from "../../hdYuka";

import {
  ActionSpan,
  Caption2HyperLink,
  StyledLoginHeader,
  StyledLoginHeaderContainer,
  StyledLoginSubHeader,
} from "../StyledComponents";

import LoadingSpinner from "../../utils/LoadingSpinner";
import AxiosInstance from "../../api/AxiosInstance";
import { API_ENDPOINTS } from "../../api/constants";
import { ROUTING_PATH } from "../../routes/constants";

const ResetPasswordRequest = () => {
  const navigate = useNavigate();
  const validate = (value) => (value ? undefined : "Required");
  const onSubmit = async (values) => {
    return AxiosInstance.post(
      API_ENDPOINTS.RESET_PASSWORD_SEND_EMAIL(),
      JSON.stringify(values)
    )
      .then(() => {
        navigate(ROUTING_PATH.RESET_PASSWORD_CONFIRM());
      })
      .catch(({ response: { data } }) => {
        return {
          // put error message underneath email input box
          email: data.email ? data.email : "Unknown error occurred",
        };
      });
  };

  return (
    <React.Fragment>
      <StyledLoginHeaderContainer>
        <StyledLoginHeader>Forgot your password?</StyledLoginHeader>
        <StyledLoginSubHeader>
          Please enter your email address, and we will send instructions on how
          to reset your password.
        </StyledLoginSubHeader>
      </StyledLoginHeaderContainer>
      <Card cardStyle={CardStyles.PADDED}>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, submitting }) => (
            <React.Fragment>
              {submitting && <LoadingSpinner />}
              <form onSubmit={handleSubmit}>
                <InputRow>
                  <FinalFormField
                    id="email"
                    type="text"
                    name="email"
                    placeholder="Email"
                    component={InputComponent}
                    validate={validate}
                  />
                </InputRow>
                <ActionSpan>
                  <Caption2HyperLink url={ROUTING_PATH.LOGIN()}>
                    Back to Login
                  </Caption2HyperLink>
                  <Button
                    buttonStyle={ButtonStyles.CTA}
                    disabled={submitting}
                    type="submit"
                  >
                    Reset Password
                  </Button>
                </ActionSpan>
              </form>
            </React.Fragment>
          )}
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default ResetPasswordRequest;
