import { DateTime } from "luxon";
import _ from "lodash";

import { TIME_FRAME_MONTHLY, TIME_FRAME_WEEKLY } from "./constants";

// Helper for `getTimeFrameParams` to define graph params for different time frames
const _getTimeFrameParams = (endDate) => ({
  monthly: {
    startDate: DateTime.fromISO(endDate).minus({
      years: 1,
    }),
    minBoundaryXPoint: 0.5,
    maxBoundaryXPoint: 12.5,
    tickMarks: 12,
    tickFormatValue: (value) => ({
      months: value,
    }),
  },
  weekly: {
    startDate: DateTime.fromISO(endDate).minus({
      years: 1,
    }),
    minBoundaryXPoint: -1,
    maxBoundaryXPoint: 53,
    tickMarks: 52,
    tickFormatValue: (value) => ({
      weeks: value,
    }),
    tickValues: [1, 6, 11, 16, 21, 26, 31, 36, 41, 46, 51],
  },
});

/**
 * Object that contains parameters for a given time frame.
 *
 * @param {string} timeFrameConstant
 * @param {string} endDate
 *
 * @typedef {object} TimeFrameParams
 * @property {DateTime} startDate - the earliest date at which the graph will start
 * @property {number} minBoundaryXPoint - the minimum boundary point of the graph on the left
 * @property {number} maxBoundaryXPoint - the maximum boundary point of the graph on the right
 * @property {Function} tickFormatValue - the parameters used to create the x-axis tick marks
 */
const getTimeFrameParams = (timeFrameConstant, endDate) => {
  const params = _getTimeFrameParams(endDate);

  switch (timeFrameConstant) {
    case TIME_FRAME_MONTHLY:
      return params.monthly;
    case TIME_FRAME_WEEKLY:
      return params.weekly;
    default:
      return params.monthly;
  }
};

// finds the difference between 12 months ago from given endDate
// and a given dateTime in months
const monthTimeDifference = (dateTime, endDate) => {
  const compareDate = _.isString(dateTime)
    ? DateTime.fromISO(dateTime)
    : dateTime;
  const startDate = DateTime.fromISO(endDate)
    .startOf("month")
    .minus({ months: 12 });
  const difference = compareDate.startOf("month").diff(startDate, ["months"]);
  return difference.months;
};

// fids the difference between 52 weeks ago from given endDate
// and a given dateTime in weeks
const weekTimeDifference = (dateTime, endDate) => {
  const SATURDAY = 6;
  const compareDate = _.isString(dateTime)
    ? DateTime.fromISO(dateTime)
    : dateTime;
  const startDate = DateTime.fromISO(endDate)
    .startOf("week")
    .minus({ weeks: 52 });
  const difference = compareDate.startOf("week").diff(startDate, ["weeks"]);
  return dateTime.weekday < SATURDAY ? difference.weeks : difference.weeks + 1;
};

/**
 * Returns the weekly reporting period string based on a given time difference
 * Used to calculate the weekly reporting period string to display in the graph hover tooltip
 *
 * @param {number} timeDifference
 * @param {string} endDate
 * @returns {string}
 */
const calculateWeeklyReportingPeriod = (timeDifference, endDate) => {
  const weeklyParams = getTimeFrameParams(TIME_FRAME_WEEKLY, endDate);

  // startOf("week") sets the date to the monday of that week, so minus 2 days to get to saturday
  const startDateDisplay = weeklyParams.startDate
    .startOf("week")
    .minus({ days: 2 })
    .plus({ weeks: timeDifference })
    .toLocaleString(DateTime.DATE_SHORT);
  const endDateDisplay = weeklyParams.startDate
    .startOf("week")
    .plus({ days: 4, weeks: timeDifference })
    .toLocaleString(DateTime.DATE_SHORT);

  return `${startDateDisplay} - ${endDateDisplay}`;
};

export {
  calculateWeeklyReportingPeriod,
  getTimeFrameParams,
  monthTimeDifference,
  weekTimeDifference,
};
