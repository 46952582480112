import React, { useCallback, useContext, useEffect } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";

import { Button, ButtonStyles, InputRow, LinkTypes, LockIcon } from "yuka";

import { FinalFormField } from "../hdYuka";

import { ActionSpan, Caption2HyperLink } from "./StyledComponents";
import PropTypes from "prop-types";

import LoadingSpinner from "../utils/LoadingSpinner";
import AxiosInstance from "../api/AxiosInstance";
import { API_ENDPOINTS } from "../api/constants";
import { ROUTING_PATH } from "../routes/constants";
import { AuthContext } from "../auth";
import useLocalStorage from "../utils/useLocalStorage";
import useUnfocusOnSubmit from "../utils/useUnfocusOnSubmit";

const REMEMBER_2FA_KEY = "remember_2fa_token";

const LoginForm = ({ setCredentials }) => {
  const { authenticate, user } = useContext(AuthContext);
  const { getItem } = useLocalStorage();

  const onSubmit = useCallback(
    (values) => {
      const remember2faToken = getItem(REMEMBER_2FA_KEY);
      const combinedValues = {
        ...values,
        remember_2fa_token: remember2faToken,
      };

      return AxiosInstance.post(
        API_ENDPOINTS.LOGIN(),
        JSON.stringify(combinedValues)
      )
        .then(({ data }) => {
          if (data.require_2fa) {
            // If 2FA is required, stash the credentials and switch to TokenForm
            setCredentials(values);
          } else {
            authenticate(data, true);
          }
        })
        .catch(({ response: { data } }) => {
          let errorMsg = "Unknown error occurred";
          if (data) {
            errorMsg = data.detail || errorMsg;
          }
          return {
            password: errorMsg, // put error message underneath password input box
          };
        });
    },
    [authenticate, getItem, setCredentials]
  );
  const [wrappedOnSubmit, submitRef] = useUnfocusOnSubmit(onSubmit);
  const validate = (value) => (value ? undefined : "Required");
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate(ROUTING_PATH.COMPANIES());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={wrappedOnSubmit}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          {submitting && <LoadingSpinner />}
          <InputRow>
            <FinalFormField
              id="email"
              name="email"
              placeholder="Email"
              type="text"
              validate={[validate]}
            />
          </InputRow>
          <InputRow>
            <FinalFormField
              displayIndicator={false}
              id="password"
              name="password"
              placeholder="Password"
              trailingIcon={LockIcon}
              type="password"
              validate={[validate]}
            />
          </InputRow>
          <ActionSpan>
            <Caption2HyperLink
              linkType={LinkTypes.LOCAL_LINK}
              url={ROUTING_PATH.RESET_PASSWORD()}
            >
              Forgot your password?
            </Caption2HyperLink>
            <Button
              ref={submitRef}
              disabled={submitting}
              buttonStyle={ButtonStyles.CTA}
              type="submit"
            >
              Log in
            </Button>
          </ActionSpan>
        </form>
      )}
    </Form>
  );
};

LoginForm.propTypes = {
  setCredentials: PropTypes.func.isRequired,
};

export default LoginForm;
