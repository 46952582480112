import PropTypes from "prop-types";
import styled from "styled-components";

import { Fonts } from "yuka";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FundingRoundsStat = ({ mainStatistic, supportingText }) => (
  <StyledContainer>
    <Fonts.Headline3theme80>{mainStatistic}</Fonts.Headline3theme80>
    <Fonts.Body1theme50>{supportingText}</Fonts.Body1theme50>
  </StyledContainer>
);

FundingRoundsStat.propTypes = {
  mainStatistic: PropTypes.elementType.isRequired,
  supportingText: PropTypes.elementType,
};

FundingRoundsStat.defaultProps = {
  supportingText: null,
};

export default FundingRoundsStat;
