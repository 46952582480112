import { useFormState } from "react-final-form";
import React, { useMemo } from "react";
import { Fonts, YukaColorPalette } from "yuka";
import styled from "styled-components";

import { StyledRow } from "./StyledComponents";
import useComponents from "./useComponents";

// Simulate a table's header.
const StyledStickyHeader = styled.div`
  position: sticky;
  top: 0;
  background: ${YukaColorPalette.surface1};
  z-index: 1;
`;

const ValuationComponentsHeader = () => {
  const { values } = useFormState();
  const components = useComponents(values);

  const sumOfWeights = useMemo(
    () =>
      Object.keys(components).reduce(
        (acc, key) => acc + (components[key].weight || 0),
        0
      ),
    [components]
  );

  return (
    <StyledStickyHeader>
      <StyledRow $noInteraction>
        <Fonts.Overlinetheme50>Included criteria</Fonts.Overlinetheme50>
        <Fonts.Overlinetheme50>Value</Fonts.Overlinetheme50>
        <Fonts.Overlinetheme50>
          Weighting (
          {sumOfWeights !== 100 ? (
            <Fonts.Overlinesell>{sumOfWeights}%</Fonts.Overlinesell>
          ) : (
            <Fonts.Overlinetheme50>100%</Fonts.Overlinetheme50>
          )}
          /100%)
        </Fonts.Overlinetheme50>
      </StyledRow>
    </StyledStickyHeader>
  );
};

export default ValuationComponentsHeader;
