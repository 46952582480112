import { StyledDetailPanelFields } from "./StyledComponents";
import { FinalFormField, Fonts } from "yuka";
import PropTypes from "prop-types";
import { useFormState } from "react-final-form";

import {
  debouncedModifyCustomComponentName,
  debouncedModifyCustomComponentValue,
} from "./debouncedMixpanelEvents";
import { useComponent } from "./useComponents";

import useDebouncedState from "../../utils/useDebouncedState";
import {
  currentValueFieldName,
  nameFieldName,
} from "../utils/valuationCalculatorFormNameUtils";

const CustomComponentField = ({ companyName, editable, componentId }) => {
  const { values } = useFormState();
  // These will be used for mixpanel tracking.
  const { name, currentValue } = useComponent(values, componentId);
  const [previousName, setPreviousName] = useDebouncedState(name);
  const [previousValue, setPreviousValue] = useDebouncedState(currentValue);

  return (
    <StyledDetailPanelFields>
      <Fonts.Body1theme50>
        You can customize your own criterion to include in the valuation
        calculation.
      </Fonts.Body1theme50>
      <FinalFormField
        small
        disabled={!editable}
        name={nameFieldName(componentId)}
        label="Criterion name *"
        placeholder="Untitled"
        onChange={(value) => {
          debouncedModifyCustomComponentName(companyName, previousName, value);
          setPreviousName(value);
        }}
      />
      <FinalFormField
        small
        disabled={!editable}
        name={currentValueFieldName(componentId)}
        type="number"
        label="Value *"
        placeholder="Enter a price"
        onChange={(value) => {
          debouncedModifyCustomComponentValue(
            companyName,
            name,
            previousValue,
            value
          );
          setPreviousValue(value);
        }}
      />
    </StyledDetailPanelFields>
  );
};

CustomComponentField.propTypes = {
  companyName: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  componentId: PropTypes.number,
};

export default CustomComponentField;
