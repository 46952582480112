import React from "react";
import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import styled from "styled-components";
import {
  BarLineChartIcon,
  caption2,
  Card,
  CardStyles,
  ColorPalette,
  FontColors,
  headline3,
  LinkExternalIcon,
  LinkTypes,
  List,
  ListItem,
  ListStyles,
  Thumbnail,
} from "yuka";
import _ from "lodash";
import { DateTime } from "luxon";
import LoadingSpinner from "../utils/LoadingSpinner";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const StyledCard = styled(Card)`
  height: fit-content;
  width: 100%;
`;

const StyledDate = styled.span`
  ${caption2}
  ${FontColors.theme50}
  white-space: nowrap;
`;

const StyledPageLayout = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  gap: 16px;
  padding-top: 16px;
  height: 100%;

  > :first-child {
    flex-grow: 2;
  }

  > :last-child {
    flex-grow: 1;
  }
`;

const StyledTitle = styled.div`
  ${headline3}
  ${FontColors.theme100}
  border-bottom: 1px solid;
  border-color: ${ColorPalette.white15};
  padding-bottom: 24px;
`;

const SECONDARY_MARKET_MONTHLY_MOVERS = 10;
const INSTITUTIONAL_MARKS_MONTHLY_MOVERS = 20;

const Insights = () => {
  React.useEffect(() => {
    MixpanelEvents.viewInsights();
  }, []);

  const insightArticleQueryInfo = useFetch(API_ENDPOINTS.INSIGHT_ARTICLE());
  const insightArticles = insightArticleQueryInfo?.cleanedData?.data;

  const [monthlyMoverArticles, pressReportArticles] = _.partition(
    insightArticles,
    (article) => {
      return (
        article.article_type === SECONDARY_MARKET_MONTHLY_MOVERS ||
        article.article_type === INSTITUTIONAL_MARKS_MONTHLY_MOVERS
      );
    }
  );

  return (
    <React.Fragment>
      <StyledTitle>
        <span>Insights</span>
      </StyledTitle>
      {insightArticleQueryInfo.isLoading ? (
        <LoadingSpinner absolute={false} />
      ) : (
        <StyledPageLayout>
          <StyledCard
            cardStyle={CardStyles.SECTIONED}
            tagline="ZX Monthly Movers summarizes notable movements in the holding values of venture-backed private companies"
            title="ZX Monthly Movers"
          >
            <List divider>
              {_.map(monthlyMoverArticles, (article) => (
                <ListItem
                  leadingIcon={BarLineChartIcon}
                  linkType={LinkTypes.EXTERNAL_LINK}
                  listStyle={ListStyles.TWO_LINE}
                  onClick={() =>
                    MixpanelEvents.openMonthlyMoversArticle(article.title)
                  }
                  subtext={article.subtitle}
                  text={article.title}
                  trailingContent={
                    <StyledDate>
                      {`Published on ${DateTime.fromISO(
                        article.posted_on
                      ).toLocaleString(DateTime.DATE_SHORT)}`}
                    </StyledDate>
                  }
                  url={article.url}
                />
              ))}
            </List>
          </StyledCard>
          <StyledCard
            cardStyle={CardStyles.SECTIONED}
            title="Reports & Press Releases"
          >
            <List divider>
              {_.map(pressReportArticles, (article) => (
                <ListItem
                  avatar={
                    <Thumbnail
                      src={article.thumbnail_kn_url}
                      alt={article.title}
                      size="32px"
                    />
                  }
                  linkType={LinkTypes.EXTERNAL_LINK}
                  listStyle={ListStyles.TWO_LINE}
                  onClick={() =>
                    MixpanelEvents.openReportOrPressRelease(article.title)
                  }
                  subtext={`Posted on ${DateTime.fromISO(
                    article.posted_on
                  ).toLocaleString(DateTime.DATE_SHORT)}`}
                  text={article.title}
                  trailingContent={<LinkExternalIcon />}
                  url={article.url}
                />
              ))}
            </List>
          </StyledCard>
        </StyledPageLayout>
      )}
    </React.Fragment>
  );
};

export default Insights;
