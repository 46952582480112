import React, { useContext, useRef } from "react";
import { Form } from "react-final-form";

import { Button, ButtonStyles, InputRow } from "yuka";

import PropTypes from "prop-types";

import { FinalFormField } from "../hdYuka";

import LoadingSpinner from "../utils/LoadingSpinner";
import { API_ENDPOINTS } from "../api/constants";
import { AuthContext } from "../auth";
import useLocalStorage from "../utils/useLocalStorage";
import { ONE_MINUTE } from "../utils/constants";
import AxiosInstance from "../api/AxiosInstance";

const REMEMBER_2FA_KEY = "remember_2fa_token";
const REMEMBER_2FA_TTL = 30 * 24 * 60 * ONE_MINUTE;

const TokenForm = ({ credentials, setCredentials }) => {
  const otpTokenRef = useRef();
  const validate = (value) => (value ? undefined : "Required");
  const { authenticate } = useContext(AuthContext);
  const { setItem } = useLocalStorage();

  const onSubmit = (values) => {
    const combinedValues = { ...credentials, ...values };

    return AxiosInstance.post(
      API_ENDPOINTS.TOKEN_LOGIN(),
      JSON.stringify(combinedValues)
    )
      .then(({ data }) => {
        // useLocalStorage to store a 30 day signed token to remember device
        if (data.remember_2fa_token) {
          setItem(REMEMBER_2FA_KEY, data.remember_2fa_token, REMEMBER_2FA_TTL);
        }
        otpTokenRef.current.blur();
        setCredentials(null);
        authenticate(data, true);
      })
      .catch(({ response: { data } }) => {
        let errorMsg = "Unknown error occurred";
        otpTokenRef.current.blur();
        if (data?.Error) {
          errorMsg = data.Error[0] || errorMsg;
        }
        return { otp_token: errorMsg };
      });
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting, dirty }) => (
        <form onSubmit={handleSubmit}>
          {submitting && <LoadingSpinner />}
          <InputRow>
            <FinalFormField
              label="Please enter the tokens generated by your token generator"
              name="otp_token"
              placeholder="Token"
              type="text"
              validate={[validate]}
              ref={otpTokenRef}
            />
          </InputRow>
          <InputRow>
            <FinalFormField
              id="remember_2fa"
              name="remember_2fa"
              type="checkbox"
              label="Remember this device for 30 days"
            />
          </InputRow>
          <Button
            disabled={submitting}
            onClick={() => {
              setCredentials(null);
            }}
          >
            Cancel
          </Button>
          <Button
            buttonStyle={ButtonStyles.CTA}
            disabled={submitting || !dirty}
            type="submit"
          >
            Log in
          </Button>
        </form>
      )}
    </Form>
  );
};

TokenForm.propTypes = {
  credentials: PropTypes.object.isRequired,
  setCredentials: PropTypes.func.isRequired,
};

export default TokenForm;
