import _ from "lodash";
import {
  RestoreDefaultsButton,
  StyledDetailPanelFields,
} from "./StyledComponents";
import { FinalFormField, Fonts } from "yuka";
import {
  currentValueFieldName,
  initialValueFieldName,
} from "../utils/valuationCalculatorFormNameUtils";
import PropTypes from "prop-types";
import { useFormState } from "react-final-form";
import useLatestHDFundingRound from "./useLatestHDFundingRound";
import { DateTime } from "luxon";
import { VALUATION_COMPONENT_FUNDING_ROUND } from "../constants";
import { COMPONENT_TYPE_MAP } from "./constants";
import { useComponent } from "./useComponents";
import { debouncedModifyDefaultComponentValue } from "./debouncedMixpanelEvents";

const LastFundingRoundField = ({
  companyName,
  companyId,
  editable,
  resetValue,
  componentId,
}) => {
  const { values } = useFormState();

  const { name, initialValue } = useComponent(values, componentId);

  const [hdFundingRound] = useLatestHDFundingRound(
    companyId,
    values.valuation_date,
    true
  );

  return (
    <StyledDetailPanelFields>
      <Fonts.Body1theme50>
        If available, we use the cost of a single share during the most recent
        investment round as our default value.
        {hdFundingRound && hdFundingRound.price_per_share && (
          <Fonts.Body1theme50>
            Our sources indicate that the most recent primary financing round
            was
            <Fonts.Body1theme80> {hdFundingRound.event} </Fonts.Body1theme80>
            in
            <Fonts.Body1theme80>
              {" "}
              {DateTime.fromISO(hdFundingRound.date).toFormat("LLLL, yyyy")}
            </Fonts.Body1theme80>
          </Fonts.Body1theme50>
        )}
      </Fonts.Body1theme50>
      {editable && (
        <Fonts.Body1theme50>
          You can override the default value by entering your own number below:
        </Fonts.Body1theme50>
      )}
      <FinalFormField
        small
        disabled={!editable}
        initialValue={values[initialValueFieldName(componentId)]}
        name={currentValueFieldName(componentId)}
        type="number"
        label="Financing round *"
        placeholder="Enter a price"
        onChange={(value) => {
          if (value !== initialValue && (value || initialValue)) {
            debouncedModifyDefaultComponentValue(
              companyName,
              name,
              COMPONENT_TYPE_MAP[VALUATION_COMPONENT_FUNDING_ROUND],
              initialValue,
              value
            );
          }
        }}
      />
      {editable && (
        <RestoreDefaultsButton onClick={resetValue}>
          Restore default
        </RestoreDefaultsButton>
      )}
    </StyledDetailPanelFields>
  );
};

LastFundingRoundField.propTypes = {
  companyName: PropTypes.string,
  companyId: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  resetValue: PropTypes.func,
  componentId: PropTypes.number,
};

LastFundingRoundField.defaultProps = {
  resetValue: _.noop,
};

export default LastFundingRoundField;
