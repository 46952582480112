import styled from "styled-components";
import _ from "lodash";
import React, { useState } from "react";
import { FloatingNavigation, ListItem, ListStyles, NavigationItem } from "yuka";

import AccountInformation from "./AccountInformation";
import EditPasswordForm from "./EditPasswordForm";
import EditEmailForm from "./EditEmailForm";
import TwoFactorAuthentication from "./TwoFactorAuthentication";
import { StyledRow } from "./StyledComponents";

import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import { fullDisplayName } from "../user/utils";
import LoadingSpinner from "../utils/LoadingSpinner";

import UserAvatar from "../utils/UserAvatar";

const StyledAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 48px 15% 0;
  gap: 64px;
`;

const CardContainer = styled.div`
  flex-grow: 1;
`;

const PAGES = {
  ACCOUNT: "account",
  PASSWORD: "password",
  EMAIL: "email",
  TWO_FACTOR_AUTH: "2fa",
};

/**
 * Returns the Account information component
 *
 * @returns {React.Element}
 */
const AccountSettings = () => {
  const [page, setPage] = useState(PAGES.ACCOUNT);
  const userQueryInfo = useFetch(
    API_ENDPOINTS.USER(),
    {},
    { keepPreviousData: true }
  );

  if (userQueryInfo.isError) return <div>An error occurred</div>;
  if (userQueryInfo.isLoading) return <LoadingSpinner />;

  const user = userQueryInfo.cleanedData;

  return (
    <StyledAccountContainer>
      <ListItem
        listStyle={ListStyles.TWO_LINE}
        text={`${fullDisplayName(user)} / Account`}
        subtext="Manage your account info and security"
        avatar={<UserAvatar user={user} />}
      />
      <StyledRow>
        <FloatingNavigation>
          <NavigationItem
            selected={page === PAGES.ACCOUNT}
            onClick={() =>
              page !== PAGES.ACCOUNT ? setPage(PAGES.ACCOUNT) : _.noop
            }
          >
            Account Information
          </NavigationItem>
          <NavigationItem
            selected={page === PAGES.EMAIL}
            onClick={() =>
              page !== PAGES.EMAIL ? setPage(PAGES.EMAIL) : _.noop
            }
          >
            Email
          </NavigationItem>
          <NavigationItem
            selected={page === PAGES.PASSWORD}
            onClick={() =>
              page !== PAGES.PASSWORD ? setPage(PAGES.PASSWORD) : _.noop
            }
          >
            Password
          </NavigationItem>
          <NavigationItem
            selected={page === PAGES.TWO_FACTOR_AUTH}
            onClick={() =>
              page !== PAGES.TWO_FACTOR_AUTH
                ? setPage(PAGES.TWO_FACTOR_AUTH)
                : _.noop
            }
          >
            Two-Factor Authentication
          </NavigationItem>
        </FloatingNavigation>
        <CardContainer>
          {page === PAGES.ACCOUNT && <AccountInformation user={user} />}
          {page === PAGES.EMAIL && <EditEmailForm user={user} />}
          {page === PAGES.PASSWORD && <EditPasswordForm userId={user.id} />}
          {page === PAGES.TWO_FACTOR_AUTH && (
            <TwoFactorAuthentication user={user} />
          )}
        </CardContainer>
      </StyledRow>
    </StyledAccountContainer>
  );
};

export default AccountSettings;
