import React, { useMemo } from "react";
import styled from "styled-components";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

import {
  ArrowLeftIcon,
  Button,
  ColorPalette,
  IconButton,
  IconButtonStyles,
  MinusIcon,
  PlusIcon,
  StyledCaption2,
  StyledHeadline1,
  Thumbnail,
  FontColors,
} from "yuka";

import useFetch from "../api/useFetch";

import { percentFormat } from "../utils/displayFormatUtils";
import convertNumToReadable from "../utils/convertNumToReadable";

import { ROUTING_PATH } from "../routes/constants";
import { API_ENDPOINTS } from "../api/constants";
import PropTypes from "prop-types";

import CompanyHeaderStat from "./CompanyHeaderStat";
import convertRelativeRankingToString from "./utils/convertRelativeRankingToString";
import useCompanyFetch from "./utils/useCompanyFetch";

import useDelete from "../api/useDelete";
import useWrite from "../api/useWrite";
import {
  MIXPANEL_SOURCE_COMPANY_PROFILE,
  ORDER_FLOW_TIME_FRAME_MONTHLY,
  ORDER_FLOW_TIME_FRAME_WEEKLY,
} from "/src/utils/constants";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const CompanyProfileHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 12px;
`;

const StyledCompanyHeaderContainer = styled.div`
  padding: 0 0 16px 0;
  border-bottom: 1px solid ${ColorPalette.white15};
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledCompanyName = styled(StyledHeadline1)`
  margin-bottom: 4px;
`;

const StyledStatsRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;
`;

const StyledDivider = styled(StyledCaption2)`
  ${FontColors.theme30}
`;

const ONE_MILLION = 1000000;

const CompanyHeader = (props) => {
  const navigate = useNavigate();
  const onClickBack = () => {
    navigate(ROUTING_PATH.COMPANIES());
  };

  const backButtonRef = React.useRef(null);

  const company = useCompanyFetch(props.companyId);
  const watchlistQueryInfo = useFetch(API_ENDPOINTS.WATCHLIST_COMPANIES());
  const watchlistDelete = useDelete(API_ENDPOINTS.WATCHLIST_COMPANIES(), {
    silent: true,
  });
  const watchlistUpdate = useWrite(API_ENDPOINTS.WATCHLIST_COMPANIES(), false, {
    silent: true,
  });

  const isWatchlisted = useMemo(
    () =>
      watchlistQueryInfo.isSuccess
        ? _.filter(watchlistQueryInfo.cleanedData, { company: props.companyId })
            .length
        : false,
    [watchlistQueryInfo, props.companyId]
  );

  const onWatchlistClick = () => {
    if (isWatchlisted) {
      MixpanelEvents.removeCompanyFromWatchlist(
        MIXPANEL_SOURCE_COMPANY_PROFILE,
        company.cleanedData.data.name
      );
      watchlistDelete.mutate({ id: props.companyId });
    } else {
      MixpanelEvents.addCompanyToWatchlist(
        MIXPANEL_SOURCE_COMPANY_PROFILE,
        company.cleanedData.data.name
      );
      watchlistUpdate.mutate({ company: props.companyId });
    }
  };

  const WatchlistIcon = isWatchlisted ? MinusIcon : PlusIcon;

  const headerStatsQueryInfo = useFetch(
    API_ENDPOINTS.HEADER_STATS(props.companyId),
    { time_frame: props.timeFrame }
  );

  // eslint-disable-next-line camelcase
  const { relative_ranking, bid_offer_ratio, total_volume, robustness_score } =
    headerStatsQueryInfo.isLoading || headerStatsQueryInfo.isError
      ? {}
      : headerStatsQueryInfo.cleanedData;

  if (company.isError || company.isLoading) {
    return (
      <StyledCompanyHeaderContainer>
        <IconButton
          buttonStyle={IconButtonStyles.OUTLINE}
          ref={backButtonRef}
          icon={ArrowLeftIcon}
          onClick={onClickBack}
        />
      </StyledCompanyHeaderContainer>
    );
  }
  return (
    <StyledCompanyHeaderContainer>
      <IconButton
        buttonStyle={IconButtonStyles.OUTLINE}
        ref={backButtonRef}
        icon={ArrowLeftIcon}
        onClick={onClickBack}
      />
      <CompanyProfileHeaderInfo>
        <Thumbnail src={company.cleanedData.data.main_picture} size="48px" />
        <div>
          <StyledCompanyName>
            {company.isLoading ? "" : company.cleanedData.data.name}
          </StyledCompanyName>
          <StyledStatsRow>
            <CompanyHeaderStat
              label="Ranking"
              currentValue={
                relative_ranking?.current
                  ? convertRelativeRankingToString(relative_ranking?.current)
                  : "--"
              }
            />
            <StyledDivider>|</StyledDivider>
            <CompanyHeaderStat
              label="Bid / Ask Ratio"
              currentValue={
                bid_offer_ratio?.current && bid_offer_ratio.current >= 0
                  ? percentFormat(bid_offer_ratio.current * 100, 0)
                  : "--"
              }
            />
            <StyledDivider>|</StyledDivider>
            <CompanyHeaderStat
              label="$ Volume"
              currentValue={
                total_volume?.current && total_volume.current >= 0
                  ? `$${convertNumToReadable(
                      total_volume.current * ONE_MILLION,
                      0
                    )}`
                  : "--"
              }
            />
            <StyledDivider>|</StyledDivider>
            <CompanyHeaderStat
              label="ZXData Robustness Score"
              currentValue={
                robustness_score?.current && robustness_score.current >= 0
                  ? robustness_score.current.toString()
                  : "--"
              }
            />
          </StyledStatsRow>
        </div>
      </CompanyProfileHeaderInfo>
      <Button leadingIcon={WatchlistIcon} onClick={onWatchlistClick}>
        {`${isWatchlisted ? "Remove from" : "Add to"} Watchlist`}
      </Button>
    </StyledCompanyHeaderContainer>
  );
};

CompanyHeader.propTypes = {
  companyId: PropTypes.string.isRequired,
  timeFrame: PropTypes.oneOf([
    ORDER_FLOW_TIME_FRAME_MONTHLY,
    ORDER_FLOW_TIME_FRAME_WEEKLY,
  ]),
};

CompanyHeader.defaultProps = {
  timeFrame: ORDER_FLOW_TIME_FRAME_MONTHLY,
};

export default CompanyHeader;
