import {
  ChevronRightIcon,
  ColorPalette,
  FinalFormField,
  Fonts,
  FunctionIcon,
  PercentIcon,
  YukaColorPalette,
} from "yuka";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useForm, useFormState } from "react-final-form";

import LogoPlaceholderIcon from "../../superchart/LogoPlaceholderIcon";
import {
  percentFormat,
  shortMoneyFormat,
} from "../../utils/displayFormatUtils";
import {
  EMPTY_VALUE_PLACEHOLDER,
  VALUATION_COMPONENT_FUNDING_ROUND,
  VALUATION_COMPONENT_OTHER,
  VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON,
  VALUATION_COMPONENT_REPORTED_MARKS,
  VALUATION_COMPONENT_ZX_INDEX_VALUE,
  VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING,
} from "../constants";
import { COMPONENT_TYPE_MAP } from "./constants";
import roundDecimal from "../../utils/roundDecimal";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";
import { HALF_SECOND } from "../../utils/constants";
import useDebouncedState from "../../utils/useDebouncedState";
import {
  componentTypeFieldName,
  currentValueFieldName,
  initialValueFieldName,
  nameFieldName,
  weightFieldName,
} from "../utils/valuationCalculatorFormNameUtils";
import { StyledRow } from "./StyledComponents";
import { useComponent } from "./useComponents";

const StyledComponentLabel = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const StyledLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledWeightWithArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const StyledArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  height: 32px;
  flex: 0 0 32px;
  background: ${({ $isSelected }) =>
    $isSelected ? ColorPalette.white15 : "transparent"};
`;

const debouncedModifyWeight = _.debounce(
  (companyName, fieldName, fieldType, previousValue, newValue) => {
    MixpanelEvents.modifyValuationWeightField(
      companyName,
      fieldName,
      fieldType,
      previousValue,
      newValue
    );
  },
  HALF_SECOND,
  { leading: false, trailing: true }
);

/**
 * Renders a row describing an individual component in a weighted valuation sum. Supports the basic
 * component structure, with a label, a value and a weight field. Renders the `children` prop
 * directly in-line with the label. Typically, this prop will be used for a form value, such as a
 * Select.
 */
const ValuationPriceComponentRow = ({
  id,
  name,
  weight,
  companyName,
  initialValue,
  children,
  helpText,
  errorText: propsErrorText,
  componentType,
  editable,
}) => {
  const { change } = useForm();
  const { values } = useFormState();

  const isSelected = values.selected_row === id;

  const selectRow = useCallback(() => {
    if (!isSelected) {
      change("selected_row", id);
    }
  }, [id, isSelected, change]);

  const {
    name: nameFromForm,
    weight: weightFromForm,
    currentValue,
    initialValue: initialValueFromForm,
  } = useComponent(values, id);

  // Used for debouncing mixpanel events.
  const [previousWeight, setPreviousWeight] = useDebouncedState(weightFromForm);

  useEffect(() => {
    // Default weight to props, or 0 if props.weight is falsey.
    if (_.isNil(weightFromForm)) {
      change(weightFieldName(id), weight || 0);
    }
    // Default form values that won't change.
    if (!nameFromForm) {
      change(nameFieldName(id), name);
    }
    change(componentTypeFieldName(id), componentType);
    // Propagate the initial value to the form values so the "Reset to defaults" button downstream
    // can properly work.
    if (initialValueFromForm !== initialValue) {
      change(initialValueFieldName(id), initialValue);
      // Propagates the current value to the form values so the calculator header can compute the
      // overall weighted sum.
      change(
        currentValueFieldName(id),
        initialValue === null ? null : Number(roundDecimal(initialValue, 6))
      );
    }
  }, [
    change,
    id,
    initialValue,
    initialValueFromForm,
    name,
    nameFromForm,
    weight,
    weightFromForm,
    componentType,
  ]);

  const renderedValue = useMemo(() => {
    if (!currentValue && currentValue !== 0) {
      return EMPTY_VALUE_PLACEHOLDER;
    }
    switch (componentType) {
      case VALUATION_COMPONENT_ZX_INDEX_VALUE:
      case VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING:
      case VALUATION_COMPONENT_REPORTED_MARKS:
      case VALUATION_COMPONENT_FUNDING_ROUND:
      case VALUATION_COMPONENT_OTHER:
        return shortMoneyFormat(currentValue, 2, true);
      case VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON:
        return percentFormat(currentValue * 100, 2);
      default:
        return EMPTY_VALUE_PLACEHOLDER;
    }
  }, [currentValue, componentType]);

  const errorText = useMemo(() => {
    if (!weightFromForm) {
      return null;
    }
    if (propsErrorText) {
      return propsErrorText;
    }
    if ((!currentValue && currentValue !== 0) || !nameFromForm) {
      return "Missing required information";
    }
    return null;
  }, [propsErrorText, currentValue, nameFromForm, weightFromForm]);

  return (
    <StyledRow $isSelected={isSelected} onClick={selectRow}>
      <StyledComponentLabel>
        <LogoPlaceholderIcon
          icon={FunctionIcon}
          backgroundColor={ColorPalette.white80}
          color={YukaColorPalette.surface2}
        />
        <StyledLabel>
          {!nameFromForm ? (
            <Fonts.Body1theme50>Untitled</Fonts.Body1theme50>
          ) : (
            <Fonts.Body1theme80>{nameFromForm}</Fonts.Body1theme80>
          )}
          {errorText && <Fonts.Caption2sell>{errorText}</Fonts.Caption2sell>}
          {helpText && !errorText && (
            <Fonts.Caption2theme50>{helpText}</Fonts.Caption2theme50>
          )}
        </StyledLabel>
        {children}
      </StyledComponentLabel>
      <Fonts.Body1theme80>{renderedValue}</Fonts.Body1theme80>
      <StyledWeightWithArrow>
        {editable ? (
          <FinalFormField
            small
            onChange={(value) => {
              if (!_.isNil(previousWeight)) {
                // Don't trigger unless the weight is not being initialized from null/undefined.
                debouncedModifyWeight(
                  companyName,
                  name,
                  COMPONENT_TYPE_MAP[componentType],
                  previousWeight,
                  value
                );
              }
              setPreviousWeight(value);
            }}
            name={weightFieldName(id)}
            type="number"
            placeholder="0"
            trailingIcon={PercentIcon}
          />
        ) : (
          <Fonts.Body1theme80>
            {percentFormat(Number(weightFromForm), 2)}
          </Fonts.Body1theme80>
        )}
        <StyledArrowContainer $isSelected={isSelected}>
          <ChevronRightIcon color={ColorPalette.white50} />
        </StyledArrowContainer>
      </StyledWeightWithArrow>
    </StyledRow>
  );
};

ValuationPriceComponentRow.propTypes = {
  companyId: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  pricePerShare: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  percentageChange: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  weight: PropTypes.number,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  errorText: PropTypes.string,
  helpText: PropTypes.string,
  editable: PropTypes.bool,
  componentType: PropTypes.number.isRequired,
};

ValuationPriceComponentRow.defaultProps = {
  helpText: null,
  editable: true,
  isSelected: false,
};

export default ValuationPriceComponentRow;
