import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styled from "styled-components";

import {
  Button,
  ButtonStyles,
  FilterIcon,
  FilterChip,
  Select,
  YukaThemeProvider,
  StyledHeadline2,
} from "yuka";

import { useDropdown } from "../hdYuka";

import {
  COMPANY_LIST_FILTERS,
  COMPANY_LIST_DETAILED_FILTERS,
} from "./constants";

const StyledFilterContainer = styled.div`
  display: flex;
  padding-bottom: 24px;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 16px;
  width: 380px;

  > :first-child {
    margin-bottom: 8px;
  }
`;

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CompanyFilters = ({
  selectedFilter,
  setSelectedFilter,
  detailedFilters,
  setDetailedFilters,
}) => {
  const [robustnessFilter, setRobustnessFilter] = useState(
    detailedFilters.robustness || 0
  );
  const [zxIndexValueChangeFilter, setZxIndexValueChangeFilter] = useState(
    detailedFilters.zx_index_value_change || ""
  );
  const [lastRoundPriceChangeFilter, setLastRoundPriceChangeFilter] = useState(
    detailedFilters.last_round_price_diff || ""
  );

  const { ROBUSTNESS, ZX_INDEX_VALUE, LAST_ROUND_PRICE } =
    COMPANY_LIST_DETAILED_FILTERS;

  const applyFilters = () => {
    // Apply filters to be used by the parent component for the company list request
    const appliedFilters = _.compact([
      robustnessFilter && { robustness: robustnessFilter },
      zxIndexValueChangeFilter && {
        zx_index_value_change: zxIndexValueChangeFilter,
      },
      lastRoundPriceChangeFilter && {
        last_round_price_diff: lastRoundPriceChangeFilter,
      },
    ]);

    setDetailedFilters(_.merge(...appliedFilters));
  };

  const resetFilters = () => {
    setRobustnessFilter(0);
    setZxIndexValueChangeFilter("");
    setLastRoundPriceChangeFilter("");
  };

  // eslint-disable-next-line no-unused-vars
  const cleanFilters = () => {
    // if filters were modified but not applied, reset them to their actual values
    setRobustnessFilter(detailedFilters.robustness);
    setZxIndexValueChangeFilter(detailedFilters.zx_index_value_change);
    setLastRoundPriceChangeFilter(detailedFilters.last_round_price_diff);
  };

  const renderedDropdownContent = ({ toggleIsOpen }) => (
    <DropdownContainer>
      <StyledHeadline2>Filters</StyledHeadline2>
      <Select
        name={ROBUSTNESS.name}
        options={ROBUSTNESS.options}
        placeholder={ROBUSTNESS.defaultOption}
        onChange={setRobustnessFilter}
        value={robustnessFilter || null}
        noSort
      />
      <Select
        name={ZX_INDEX_VALUE.name}
        options={ZX_INDEX_VALUE.options}
        placeholder={ZX_INDEX_VALUE.defaultOption}
        onChange={setZxIndexValueChangeFilter}
        value={zxIndexValueChangeFilter || null}
        noSort
      />
      <Select
        name={LAST_ROUND_PRICE.name}
        options={LAST_ROUND_PRICE.options}
        placeholder={LAST_ROUND_PRICE.defaultOption}
        onChange={setLastRoundPriceChangeFilter}
        value={lastRoundPriceChangeFilter || null}
        noSort
      />
      <FormFooter>
        <Button onClick={resetFilters}>Clear All</Button>
        <Button
          buttonStyle={ButtonStyles.CTA}
          onClick={() => {
            applyFilters();
            toggleIsOpen();
          }}
        >
          Apply
        </Button>
      </FormFooter>
    </DropdownContainer>
  );

  const [dropdownElement, triggerRef, toggleDisplay] = useDropdown(
    renderedDropdownContent
  );

  const onClickFilter = () => {
    cleanFilters();
    toggleDisplay();
  };

  return (
    <YukaThemeProvider theme={{ componentVersion: 2 }}>
      <StyledFilterContainer>
        {_.map(COMPANY_LIST_FILTERS, (label) => (
          <FilterChip
            key={label}
            selected={selectedFilter === label}
            onClick={() => setSelectedFilter(label)}
            text={label}
          />
        ))}
        <FilterChip
          leadingIcon={FilterIcon}
          ref={triggerRef}
          text={`Filters (${Object.keys(detailedFilters).length})`}
          onClick={onClickFilter}
        />
        {dropdownElement}
      </StyledFilterContainer>
    </YukaThemeProvider>
  );
};

CompanyFilters.propTypes = {
  selectedFilter: PropTypes.string,
  setSelectedFilter: PropTypes.func,
  detailedFilters: PropTypes.object,
  setDetailedFilters: PropTypes.func,
};

CompanyFilters.defaultProps = {
  selectedFilter: COMPANY_LIST_FILTERS.MOST_ACTIVE,
  setSelectedFilter: _.noop,
  detailedFilters: {},
  setDetailedFilters: _.noop,
};

export default CompanyFilters;
