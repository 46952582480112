import _ from "lodash";

import useFetch from "../../../api/useFetch";
import { API_ENDPOINTS } from "../../../api/constants";
import { ORDER_FLOW_TIME_FRAME_MONTHLY } from "../../../utils/constants";

const useEndDate = (timeFrame) => {
  const orderFlowCurrentPeriodQuery = useFetch(API_ENDPOINTS.CURRENT_PERIOD());

  const orderFlowCurrentPeriod = _.first(
    orderFlowCurrentPeriodQuery.cleanedData?.data
  );

  const endDate =
    timeFrame === ORDER_FLOW_TIME_FRAME_MONTHLY
      ? orderFlowCurrentPeriod?.monthly_date
      : orderFlowCurrentPeriod?.weekly_date;

  return endDate;
};

export default useEndDate;
