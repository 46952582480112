import useFetch from "../../api/useFetch";
import { API_ENDPOINTS } from "../../api/constants";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { SECURITY_TYPE_MARKET_INDICES } from "../ValuationCalculator/constants";

/**
 * Fetches information used by the valuation calculator components.
 * - Latest orderflow report (attempts to fetch weekly data)
 * - Latest reported marks data
 * - Most recent funding round
 * @param companyId - The companyID to fetch data for.
 *
 * @return {object}
 */
const useValuationMetadata = (companyId) => {
  const companyReportedMarksValuationQuery = useFetch(
    API_ENDPOINTS.COMPANY_REPORTED_MARKS_VALUATION(companyId)
  );

  const orderFlowQuery = useFetch(
    API_ENDPOINTS.COMPANY_PRICE_VOLUME_ORDER_FLOW(),
    {
      // Slight buffer to be safe.
      start_date: DateTime.now().minus({ weeks: 4 }).toISODate(),
      end_date: DateTime.now().minus({ days: 1 }).toISODate(),
      company: companyId,
    }
  );

  const hdFundingRoundsQuery = useFetch(API_ENDPOINTS.HD_FUNDING_ROUNDS(), {
    company: companyId,
    "page[size]": 1,
  });

  const reportedMarksData = useMemo(() => {
    if (!companyReportedMarksValuationQuery.isSuccess) {
      return null;
    }
    // Find the most recent date with data for reported marks.
    const mostRecentDateWithData = Object.keys(
      companyReportedMarksValuationQuery.data.data
    )
      .sort((a, b) => new Date(b) - new Date(a))
      .filter((dateKey) =>
        Boolean(
          companyReportedMarksValuationQuery.data.data[dateKey].weighted_avg
        )
      )?.[0];

    return [
      mostRecentDateWithData
        ? DateTime.fromISO(mostRecentDateWithData).toFormat("LLL, yyyy")
        : DateTime.now().toFormat("LLL, yyyy"),
      companyReportedMarksValuationQuery.data?.data?.[mostRecentDateWithData],
    ];
  }, [
    companyReportedMarksValuationQuery.isSuccess,
    companyReportedMarksValuationQuery.data?.data,
  ]);

  const sectorQuery = useFetch(API_ENDPOINTS.COMPANY_SECTORS(), {
    company: companyId,
  });

  const privateMarketIndicesQuery = useFetch(
    API_ENDPOINTS.COMPANY_AND_SECURITY_SEARCH(),
    {
      "page[size]": 10000,
      security_type: SECURITY_TYPE_MARKET_INDICES,
      removed: false,
    }
  );

  const mostRecentFundingRound = hdFundingRoundsQuery.cleanedData?.data?.[0];

  return {
    companySector: sectorQuery.cleanedData?.data?.[0],
    sectors: sectorQuery.cleanedData?.data,
    privateMarketIndices: privateMarketIndicesQuery.cleanedData?.data,
    orderFlowReport: orderFlowQuery.cleanedData?.data?.[0],
    orderFlowReportIsLoading: orderFlowQuery.isLoading,
    reportedMarks: reportedMarksData,
    reportedMarksIsLoading: companyReportedMarksValuationQuery.isLoading,
    fundingRound: mostRecentFundingRound,
    fundingRoundIsLoading: hdFundingRoundsQuery.isLoading,
  };
};

export default useValuationMetadata;
