import { Fonts, Button, ButtonStyles, InputRow, body1 } from "yuka";
import { Form } from "react-final-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import { useContext } from "react";

import { FinalFormField } from "../hdYuka";

import useWrite from "../api/useWrite";
import { API_ENDPOINTS } from "../api/constants";
import { cleanJsonApiData } from "../api/utils";
import { mapComparisonExchangeToType } from "./utils";
import ProfileContext from "./ProfileContext";
import { INDICATOR_TYPES } from "./constants";

const FormContainer = styled.div`
  width: 400px;
`;

const StyledEditsSummary = styled.div`
  margin-bottom: 8px;
  ${body1};
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

/**
 * Renders a dropdown for managing a saved profile
 * Has two states: creation of a new profile and editing an existing profile
 * This dropdown lets user create, edit, and delete a profile
 *
 * This component should be rendered using the `useDropdown` hook
 *
 * @param {object} props
 * @returns {Element}
 */
const ManageProfileDropdown = (props) => {
  const createProfileQuery = useWrite(API_ENDPOINTS.PORTFOLIOS());
  const { profile, setProfile } = useContext(ProfileContext);
  const editProfileQuery = useWrite(
    API_ENDPOINTS.PORTFOLIO_DETAIL(profile?.apiId),
    true
  );

  if (!props.keyCompany) {
    return null;
  }

  const onSubmit = (values) => {
    const indicatorParams = Object.values(INDICATOR_TYPES).reduce(
      (acc, obj) => {
        acc[obj.apiDataKey] = false;
        return acc;
      },
      {}
    );

    _.forEach(props.indicators, (indicator) => {
      indicatorParams[indicator.apiDataKey] = true;
    });

    const comparisonsParam = _.map(props.comparisons, (comp) => ({
      id: comp.id,
      type: mapComparisonExchangeToType(comp.exchange),
      exchange: comp.exchange,
      symbol: comp.name,
    }));

    const queryValues = {
      ...values,
      ...indicatorParams,
      comparisons: comparisonsParam,
      primary: props.keyCompany,
    };

    const onSuccess = (profile) => {
      const cleanedProfileData = cleanJsonApiData(profile.data).data;
      setProfile(cleanedProfileData);
      props.toggleIsOpen();
    };

    _.isNil(profile)
      ? createProfileQuery.mutate(queryValues, { onSuccess })
      : editProfileQuery.mutate(queryValues, { onSuccess });
  };

  const superchartComparisonIds = _.map(props.comparisons, "id");
  const profileComparisonIds = _.isNil(profile)
    ? []
    : _.map(profile.comparisons, "id");

  const profileIndicators = profile
    ? Object.values(INDICATOR_TYPES).reduce((acc, obj) => {
        if (profile[obj.apiDataKey]) acc.push(obj.name);
        return acc;
      }, [])
    : [];
  const indicatorIds = _.map(props.indicators, "id");

  const comparisonsAdded = _.difference(
    superchartComparisonIds,
    profileComparisonIds
  ).length;
  const comparisonsRemoved = _.difference(
    profileComparisonIds,
    superchartComparisonIds
  ).length;

  const indicatorsAdded = _.difference(indicatorIds, profileIndicators).length;

  const indicatorsRemoved = _.difference(
    profileIndicators,
    indicatorIds
  ).length;

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting, dirty }) => (
        <form onSubmit={handleSubmit}>
          <FormContainer>
            {props.isProfileDirty && (
              <StyledEditsSummary>
                {comparisonsAdded > 0 && (
                  <div>
                    <Fonts.Body1theme100>
                      {comparisonsAdded}
                    </Fonts.Body1theme100>{" "}
                    <Fonts.Body1theme50>
                      compan{comparisonsAdded === 1 ? "y" : "ies"} added to this
                      profile
                    </Fonts.Body1theme50>
                  </div>
                )}
                {comparisonsRemoved > 0 && (
                  <div>
                    <Fonts.Body1theme100>
                      {comparisonsRemoved}
                    </Fonts.Body1theme100>{" "}
                    <Fonts.Body1theme50>
                      compan{comparisonsRemoved === 1 ? "y" : "ies"} removed
                      from this profile
                    </Fonts.Body1theme50>
                  </div>
                )}
                {indicatorsAdded > 0 && (
                  <div>
                    <Fonts.Body1theme100>{indicatorsAdded}</Fonts.Body1theme100>{" "}
                    <Fonts.Body1theme50>
                      indicator{indicatorsAdded === 1 ? "" : "s"} added to this
                      profile
                    </Fonts.Body1theme50>
                  </div>
                )}
                {indicatorsRemoved > 0 && (
                  <div>
                    <Fonts.Body1theme100>
                      {indicatorsRemoved}
                    </Fonts.Body1theme100>{" "}
                    <Fonts.Body1theme50>
                      indicator{indicatorsRemoved === 1 ? "" : "s"} removed from
                      this profile
                    </Fonts.Body1theme50>
                  </div>
                )}
              </StyledEditsSummary>
            )}
            <InputRow>
              <FinalFormField
                label="Name this profile"
                name="name"
                required
                placeholder="Enter profile name"
                initialValue={profile?.name}
              />
            </InputRow>
            <ButtonRow>
              {profile && (
                <Button
                  buttonStyle={ButtonStyles.DEFAULT}
                  onClick={() => {
                    props.toggleIsOpen();
                    props.setShowDeleteModal(true);
                  }}
                >
                  Delete Saved Profile
                </Button>
              )}
              <Button
                buttonStyle={ButtonStyles.CTA}
                type="submit"
                disabled={submitting || (!dirty && !props.isProfileDirty)}
              >
                Submit
              </Button>
            </ButtonRow>
          </FormContainer>
        </form>
      )}
    </Form>
  );
};

ManageProfileDropdown.propTypes = {
  toggleIsOpen: PropTypes.func.isRequired,
  keyCompany: PropTypes.string,
  comparisons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      exchange: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  indicators: PropTypes.arrayOf(
    PropTypes.shape({
      apiDataKey: PropTypes.string,
    })
  ),
  isProfileDirty: PropTypes.bool.isRequired,
  setShowDeleteModal: PropTypes.func.isRequired,
};

ManageProfileDropdown.defaultProps = {
  comparisons: [],
  indicators: [],
};

export default ManageProfileDropdown;
