import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ColorPalette,
  Thumbnail,
  YukaThemeProvider,
} from "yuka";

import { useDropdown } from "../hdYuka";

import useFetch from "../api/useFetch";
import useDelete from "../api/useDelete";
import useWrite from "../api/useWrite";
import { API_ENDPOINTS } from "../api/constants";
import { ROUTING_PATH } from "../routes/constants";
import CompanySearchDropdown from "../company/CompanySearchDropdown";
import { shortMoneyFormat } from "../utils/displayFormatUtils";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import { MIXPANEL_SOURCE_SUPERCHART } from "../utils/constants";
import SuperchartHeaderStats from "./SuperchartHeaderStats";
import CompanySearchButton from "./CompanySearchButton";

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 48px;
  padding-bottom: 16px;
`;

const SuperchartHeader = ({ company }) => {
  const navigate = useNavigate();

  const watchlistQueryInfo = useFetch(API_ENDPOINTS.WATCHLIST_COMPANIES());
  const watchlistDelete = useDelete(API_ENDPOINTS.WATCHLIST_COMPANIES());
  const watchlistUpdate = useWrite(API_ENDPOINTS.WATCHLIST_COMPANIES());

  const {
    apiId: companyId,
    zx_index_value: zxIndexValue,
    robustness,
  } = company;

  const isWatchlisted = useMemo(
    () =>
      watchlistQueryInfo.isSuccess
        ? _.filter(watchlistQueryInfo.cleanedData, { company: companyId })
            .length
        : false,
    [watchlistQueryInfo, companyId]
  );

  const onWatchlistClick = companyId
    ? () => {
        if (isWatchlisted) {
          MixpanelEvents.removeCompanyFromWatchlist(
            MIXPANEL_SOURCE_SUPERCHART,
            company.name
          );
          watchlistDelete.mutate({ id: companyId });
        } else {
          MixpanelEvents.addCompanyToWatchlist(
            MIXPANEL_SOURCE_SUPERCHART,
            company.name
          );
          watchlistUpdate.mutate({ company: companyId });
        }
      }
    : null;

  const renderContents = ({ toggleIsOpen }) => (
    <CompanySearchDropdown toggleDropdown={toggleIsOpen} />
  );

  const [dropdownElement, dropdownRef, toggleDropdown] = useDropdown(
    renderContents,
    { id: "KeyCompanySearchDropdown" }
  );

  const openDropdown = useCallback(() => {
    MixpanelEvents.openKeyCompanySearchDropdown();
    toggleDropdown();
  }, [toggleDropdown]);

  return (
    <StyledHeaderContainer>
      <YukaThemeProvider theme={{ surfaceLevel: 2 }}>
        <div ref={dropdownRef}>
          <CompanySearchButton
            selected={Boolean(dropdownElement)}
            leadingIcon={
              !_.isEmpty(company) ? (
                <Thumbnail src={company.main_picture} size="32px" />
              ) : null
            }
            title={
              !_.isEmpty(company) ? company.name : "Select a private company"
            }
            trailingIcon={
              dropdownElement ? (
                <ChevronUpIcon color={ColorPalette.white50} />
              ) : (
                <ChevronDownIcon color={ColorPalette.white50} />
              )
            }
            onClick={openDropdown}
          />
          {dropdownElement}
        </div>
      </YukaThemeProvider>
      <SuperchartHeaderStats
        zxIndexValue={zxIndexValue ? shortMoneyFormat(zxIndexValue) : null}
        robustnessScore={robustness}
        profileAction={
          companyId ? () => navigate(ROUTING_PATH.COMPANY(companyId)) : null
        }
        watchlistAction={onWatchlistClick}
        isWatchlisted={Boolean(isWatchlisted)}
      />
    </StyledHeaderContainer>
  );
};

SuperchartHeader.propTypes = {
  company: PropTypes.shape({
    apiId: PropTypes.string,
    name: PropTypes.string,
    main_picture: PropTypes.string,
    robustness: PropTypes.number,
    zx_index_value: PropTypes.number,
  }),
};

SuperchartHeader.defaultProps = {
  company: {},
};

export default SuperchartHeader;
