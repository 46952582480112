import { useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import { GraphSection } from "./StyledComponents";
import useEndDate from "./hooks/useEndDate";

import useFetch from "../../api/useFetch";
import { API_ENDPOINTS } from "../../api/constants";
import { ORDER_FLOW_TIME_FRAME_MONTHLY } from "../../utils/constants";
import LoadingSpinner from "../../utils/LoadingSpinner";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";

import RelativeActivityGraph from "./graph-components/RelativeActivityGraph";

/**
 * Renders the "Relative Activity" graph in Company Profile.
 */
const RelativeActivityGraphView = (props) => {
  const orderFlowQueryInfo = useFetch(
    `${API_ENDPOINTS.COMPANY_RELATIVE_ACTIVITY_DATA(props.companyId, {
      time_frame: ORDER_FLOW_TIME_FRAME_MONTHLY,
    })}?time_frame=${ORDER_FLOW_TIME_FRAME_MONTHLY}`
  );

  useEffect(() => {
    if (props.companyName) {
      MixpanelEvents.viewCompanyRelativeActivityGraph(props.companyName);
    }
  }, [props.companyName]);

  const endDate = useEndDate(ORDER_FLOW_TIME_FRAME_MONTHLY);

  if (orderFlowQueryInfo.isLoading) {
    return <LoadingSpinner />;
  }

  const orderFlowData = orderFlowQueryInfo.cleanedData?.data;

  const rankingTotalInterest = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.ranking_total_interest,
  }));
  const rankingNumTickets = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.ranking_num_tickets,
  }));
  const rankingCompanyViews = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.ranking_company_views,
  }));

  return (
    <GraphSection>
      <RelativeActivityGraph
        hasDataAccess={true}
        endDate={endDate}
        rankingTotalInterest={rankingTotalInterest}
        rankingNumTickets={rankingNumTickets}
        rankingCompanyViews={rankingCompanyViews}
      />
    </GraphSection>
  );
};

RelativeActivityGraphView.propTypes = {
  companyId: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  hasDataAccess: PropTypes.bool,
};

RelativeActivityGraphView.defaultProps = {
  hasDataAccess: false,
};

export default RelativeActivityGraphView;
