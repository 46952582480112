import PropTypes from "prop-types";
import styled from "styled-components";
import { Fonts } from "yuka";

const StyledCellContents = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 8px;
`;

/**
 * Cell renderer for cell with text followed by subtext. `getMainText` and `getSubtext` will be functions passed to the column,
 * which will be called with the props passed to the cell.
 *
 * @returns {Element}
 */
const SubtextCellRenderer = (props) => {
  const mainText = props.column?.getMainText(props);
  const subtext = props.column?.getSubtext(props);
  return mainText ? (
    <StyledCellContents>
      {mainText}
      {subtext && <Fonts.Overlinetheme50> {subtext}</Fonts.Overlinetheme50>}
    </StyledCellContents>
  ) : (
    <Fonts.Body1theme30>--</Fonts.Body1theme30>
  );
};
SubtextCellRenderer.propTypes = {
  column: PropTypes.shape({
    getMainText: PropTypes.func.isRequired,
    getSubtext: PropTypes.func.isRequired,
  }).isRequired,
};

SubtextCellRenderer.displayName = "SubtextCellRenderer";

export default SubtextCellRenderer;
