const DATAVERSE_ENJOYERS = [
  "sam.macpherson@zanbato.com",
  "kchang@zanbato.com",
  "jessica.yang@zanbato.com",
  "lazar.vukoje@zanbato.com",
  "scott.kwang@zanbato.com",
  "kevin.leung@zanbato.com",
  "andrew.yoon@zanbato.com",
  "maurice.royce@zanbato.com",
];

// eslint-disable-next-line import/prefer-default-export
export { DATAVERSE_ENJOYERS };
