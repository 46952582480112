import React, { useEffect, createContext, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTING_PATH, isUnauthenticatedRoute } from "./routes/constants";
import { API_ENDPOINTS } from "./api/constants";
import AxiosInstance from "./api/AxiosInstance";
import LoadingSpinner from "./utils/LoadingSpinner";
import { AGREEMENT_NOT_SIGNED_ERROR } from "./agreements/constants";
import { ONE_SECOND, ONE_MINUTE } from "./utils/constants";
import { useDispatch, ACTIONS } from "./routes/StateProvider";

const AuthContext = createContext(null);

const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";
const REFRESH_TIMEOUT = ONE_MINUTE * 59; // access token expires in 60 minutes

/**
 * Wrapper around all the routes in HD to provide the basic authentication mechanisms.
 *
 * Checks if user is authenticated by hitting a basic /user endpoint,
 * redirects to login page if necessary.
 *
 * Provides an authentication function through React context for the login form to use. Sets Axios
 * authentication header from token acquired through login form or local storage.
 *
 * Refreshes JWT access token on a fixed cadence in line with the token lifetime defined
 * in the backend.
 *
 * @param {React.Component} children - the child components to render
 * @returns {React.Element}
 */
const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [nextLocation, setNextLocation] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  let refreshTimeout = null;

  const setToken = (newToken) => {
    if (newToken.access) {
      localStorage.setItem(ACCESS_TOKEN, newToken.access);
      AxiosInstance.defaults.headers.Authorization = `Bearer ${newToken.access}`;
    }
    if (newToken.refresh) {
      localStorage.setItem(REFRESH_TOKEN, newToken.refresh);
    }
    if (refreshTimeout) clearTimeout(refreshTimeout);
    refreshTimeout = window.setTimeout(refreshAccessToken, REFRESH_TIMEOUT);
  };

  const setUserData = () => {
    AxiosInstance.get(API_ENDPOINTS.USER())
      .then((response) => {
        setUser(response.data);
      })
      .catch(() => {
        if (!isUnauthenticatedRoute(location.pathname)) {
          navigate(ROUTING_PATH.LOGIN());
        }
        setUser(null);
      })
      .finally(() => {
        setLoadingAuth(false);
      });
  };

  const authenticate = (newToken, redirect = false) => {
    setToken(newToken);
    setUserData();
    if (redirect) {
      navigate(nextLocation || ROUTING_PATH.COMPANIES());
      setNextLocation(null);
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    delete AxiosInstance.defaults.headers.Authorization;
    navigate(ROUTING_PATH.LOGIN());
  };

  useEffect(() => {
    // On first load, attempt to set access token from local storage
    // in case user is already logged in from before
    authenticate({
      access: localStorage.getItem(ACCESS_TOKEN),
      refresh: localStorage.getItem(REFRESH_TOKEN),
    });

    if (!localStorage.getItem(ACCESS_TOKEN)) {
      const nextLocation = !isUnauthenticatedRoute(location.pathname)
        ? location.pathname
        : null;
      setNextLocation(nextLocation);
    }

    AxiosInstance.interceptors.response.use(
      (response) => {
        return response; // propagate success response
      },
      (error) => {
        // 401 means auth check failed on the server side, redirect to login
        // This will probably only be activated when a users token expires
        // mid-session.
        if (error.response.status === 401) {
          setUser(null);
          // Do not prompt the toast or redirect if the user is trying to log in.
          if (!error.response.config.url.startsWith(API_ENDPOINTS.LOGIN())) {
            dispatch({
              type: ACTIONS.addToast,
              message:
                "You have been logged out of your session due to inactivity. Redirecting to login page in 5 seconds...",
            });
            setTimeout(() => navigate(ROUTING_PATH.LOGIN()), ONE_SECOND * 5);
          }
        } else if (error.response.status === 403) {
          if (error.response.data.detail in AGREEMENT_NOT_SIGNED_ERROR) {
            const agreement =
              AGREEMENT_NOT_SIGNED_ERROR[error.response.data.detail];
            navigate(ROUTING_PATH.AGREEMENT(agreement));
          }
          // Be careful of redirecting to a page that could lead to repeated
          // 403 errors resulting in continuous redirects
        }
        return Promise.reject(error);
      }
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshAccessToken = () => {
    const refresh = localStorage.getItem(REFRESH_TOKEN);
    if (refresh) {
      AxiosInstance.post(API_ENDPOINTS.REFRESH(), { refresh })
        .then((response) => {
          authenticate(response.data);
        })
        .catch(() => {
          if (refreshTimeout) {
            clearTimeout(refreshTimeout);
            refreshTimeout = null;
          }
        });
    }
  };

  return (
    <AuthContext.Provider value={{ authenticate, user, logout, setUserData }}>
      {loadingAuth ? <LoadingSpinner /> : children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthContext, AuthProvider };
