import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CardStyles, Card, headline2 } from "yuka";

import {
  StyledCardHeaderTooltipText,
  StyledTooltipTextHeader,
  StyledCenteredBody,
} from "../../utils/StyledComponents";
import { useDropdown } from "../../hdYuka";

import useFetch from "../../api/useFetch";
import { API_ENDPOINTS } from "../../api/constants";
import CompanyDocumentsList from "./CompanyDocumentsList";
import LoadingSpinner from "../../utils/LoadingSpinner";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";

const RELATION_CONNECTED = "connected";
const RELATION_SUBJECT = "subject";
const TYPE_COMPANY = "company";

const AlsoMentionedHeaderText = styled.div`
  ${headline2}
  padding-left: 16px;
`;

const CompanyDocumentsCard = (props) => {
  const [showAllConnected, setShowAllConnected] = useState(false);

  const documentsQuery = useFetch(
    API_ENDPOINTS.SACRA_DOCUMENTS(props.companyId)
  );
  const reportsQuery = useFetch(
    API_ENDPOINTS.CONTRARY_REPORTS(props.companyId)
  );

  const disclaimerTooltipText = () => (
    <StyledCardHeaderTooltipText>
      <StyledTooltipTextHeader>Disclaimer</StyledTooltipTextHeader>
      <p>
        ZXData is providing a link to independent third-party research reports,
        such as the Sacra reports, solely as a courtesy to ZXData subscribers.
        ZXData is not recommending the use of, or reliance on, such reports and
        has not prepared or contributed to any such independent third-party
        research reports, nor is Zanbato affiliated with the entities producing
        such reports. Zanbato compensates the third party research providers for
        access to these reports. ZXData makes no warranties or representations
        of any kind, and takes no responsibility for the accuracy, completeness,
        timeliness, authenticity, or continued availability and/or updating of
        the independent third-party research reports. Any recommendation,
        opinion or advice regarding securities or markets contained in such
        independent third-party research reports does not necessarily reflect
        the views of ZXData. To the fullest extent provided by law, in no event
        shall Zanbato or its affiliates be liable for damages of any kind
        arising out of or in connection with the independent third-party
        research reports.
      </p>
    </StyledCardHeaderTooltipText>
  );

  const [dropdownElement, tooltipRef, toggleTooltip] = useDropdown(
    disclaimerTooltipText
  );

  let tableContent = null;

  const contraryReportMapper = (doc) => ({
    id: doc.title, // ContraryReport.title has a unique constraint.
    link: doc.url,
    relation: RELATION_SUBJECT,
    date: doc.article_updated_on,
    title: doc.title,
    type: TYPE_COMPANY,
  });
  const sacraDocumentMapper = (doc) => ({
    ...doc,
    id: `SacraReport-${doc.id}`,
  });
  const contraryReports =
    reportsQuery?.cleanedData?.map(contraryReportMapper) || [];
  const sacraDocuments =
    documentsQuery?.cleanedData?.documents.map(sacraDocumentMapper) || [];

  const documentSorter = (a, b) => {
    // Sort the documents by date, newest (largest date) first.
    if (a.date < b.date) return 1;
    if (a.date > b.date) return -1;
    return 0;
  };
  const documents = contraryReports.concat(sacraDocuments).sort(documentSorter);

  if (documentsQuery.isLoading || reportsQuery.isLoading) {
    tableContent = (
      <div>
        <LoadingSpinner absolute={false} />
      </div>
    );
  } else {
    const subjectDocuments = documents?.filter(
      (document) => document.relation === RELATION_SUBJECT
    );
    const connectedDocuments = documents?.filter(
      (document) => document.relation === RELATION_CONNECTED
    );

    tableContent = !documents.length ? (
      <StyledCenteredBody>
        No reports or interviews available
      </StyledCenteredBody>
    ) : (
      <>
        {subjectDocuments.length ? (
          <CompanyDocumentsList documents={subjectDocuments} showAll={true} />
        ) : (
          <StyledCenteredBody>
            No reports or interviews available
          </StyledCenteredBody>
        )}

        <AlsoMentionedHeaderText>
          {documents ? props.companyName + " also mentioned in..." : null}
        </AlsoMentionedHeaderText>

        {connectedDocuments.length ? (
          <CompanyDocumentsList
            documents={connectedDocuments}
            showAll={showAllConnected}
            setShowAll={setShowAllConnected}
          />
        ) : (
          <StyledCenteredBody>
            This company hasn't been mentioned in other articles
          </StyledCenteredBody>
        )}
      </>
    );
  }

  return (
    <Card
      title="Company Reports and Interviews"
      tagline="Powered by Sacra and Contrary"
      linkText="Disclaimer"
      linkProps={{
        ref: tooltipRef,
        onClick: () => {
          MixpanelEvents.openTooltip("Company Profile Sacra Disclaimer");
          toggleTooltip();
        },
      }}
      cardStyle={CardStyles.SECTIONED}
    >
      {dropdownElement}
      {tableContent}
    </Card>
  );
};

CompanyDocumentsCard.propTypes = {
  companyId: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
};

export default CompanyDocumentsCard;
