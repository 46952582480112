import { percentFormat } from "../utils/displayFormatUtils";
import getPercentChange from "../utils/getPercentChange";
import _ from "lodash";
import { QUERY_PARAMS } from "./constants";

const getLastRoundPriceDiff = (last_round_price, zx_index_value) => {
  const lastRoundPriceDiff = percentFormat(
    getPercentChange(zx_index_value, last_round_price),
    0
  );
  return zx_index_value > 0
    ? `${zx_index_value > last_round_price ? "+" : " "}${lastRoundPriceDiff}`
    : null;
};

/**
 * Transforms the filters object by converting range query string values to their corresponding min and max values defined in QUERY_PARAMS.
 *
 * @param {object} filters - The filters object where each key-value pair represents a filter field and value
 * @returns {object}
 */
const convertRangeQueryParams = (filters) => {
  return _.reduce(
    filters,
    (transformedFilters, value, key) => {
      if (_.isString(value) && QUERY_PARAMS[value]) {
        transformedFilters[key + "_min"] = QUERY_PARAMS[value].min;
        transformedFilters[key + "_max"] = QUERY_PARAMS[value].max;
      } else {
        transformedFilters[key] = value;
      }
      return transformedFilters;
    },
    {}
  );
};

export { getLastRoundPriceDiff, convertRangeQueryParams };
