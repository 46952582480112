import _ from "lodash";

import { useMemo } from "react";

import useFetch from "../../api/useFetch";
import { API_ENDPOINTS } from "../../api/constants";

/*
 * Retrieves HDFundingRound objects for the company that do not occur in the future to the
 * provided valuation date. Possibly returns undefined if there are no such objects.
 */
const useLatestHDFundingRound = (
  companyId,
  valuationDate,
  queryEnabled = false
) => {
  const hdFundingRoundsQuery = useFetch(
    API_ENDPOINTS.HD_FUNDING_ROUNDS(),
    {
      company: companyId,
      "page[size]": 10000,
    },
    {
      enabled: queryEnabled,
    }
  );

  return useMemo(() => {
    // Finds the HDFundingRound whose date is most recent prior to the valuation date.
    const hdFundingRounds = hdFundingRoundsQuery.cleanedData?.data || [];
    return [
      _.maxBy(hdFundingRounds, (round) =>
        round.date && new Date(round.date) <= new Date(valuationDate)
          ? new Date(round.date).getTime()
          : -1
      ),
      hdFundingRoundsQuery.isLoading,
    ];
  }, [valuationDate, hdFundingRoundsQuery.cleanedData?.data]);
};

export default useLatestHDFundingRound;
