import { Card, Fonts } from "yuka";
import PropTypes from "prop-types";

/**
 * Card to display alternative state of a component, for when it's loading or in error.
 *
 * @param children
 * @return {JSX.Element}
 */
const AlternativeCardState = ({ cardTitle, children }) => {
  return (
    <Card title={cardTitle}>
      <Fonts.Body1theme30>{children}</Fonts.Body1theme30>
    </Card>
  );
};

AlternativeCardState.propTypes = {
  children: PropTypes.node.isRequired,
  cardTitle: PropTypes.string,
};

AlternativeCardState.defaultProps = {
  cardTitle: "",
};

export default AlternativeCardState;
