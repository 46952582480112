import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

import {
  Button,
  ButtonStyles,
  Card,
  HyperLink,
  PlusIcon,
  Table,
  CurrencyCell,
  DateCell,
} from "yuka";

import ValuationStats from "./ValuationStats";
import { StyledTableContainer } from "./graphs/graph-components/StyledComponents";
import { ValuationCalculatorModal } from "./ValuationCalculator";
import useFetch from "../api/useFetch";
import { API_ENDPOINTS } from "../api/constants";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const StyledValuationLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CompanyValuation = ({ companyId, companyName }) => {
  const [modalShowing, setModalShowing] = useState(false);
  const [viewingValuation, setViewingValuation] = useState(null);
  const companyValuationsQuery = useFetch(API_ENDPOINTS.CUSTOM_VALUATIONS(), {
    company: companyId,
  });

  useEffect(() => {
    if (companyName) {
      MixpanelEvents.viewCompanyValuation(companyName);
    }
  }, [companyName]);

  const valuations = useMemo(() => {
    if (
      companyValuationsQuery.isSuccess &&
      companyValuationsQuery.cleanedData.length
    ) {
      return companyValuationsQuery.cleanedData;
    }
    return [];
  }, [companyValuationsQuery.isSuccess, companyValuationsQuery.cleanedData]);

  const latestValuation = useMemo(() => {
    if (valuations && valuations.length) {
      return valuations[0];
    }
    return {};
  }, [valuations]);

  const tableColumns = [
    {
      id: "valuationDate",
      accessor: "valuation_date",
      header: "Valuation Date",
      cellRenderer: DateCell,
      dateFormatter: (date) => DateTime.fromISO(date).toFormat("M/d/yyyy"),
      width: 420,
    },
    {
      id: "valuation",
      accessor: "computed_value",
      header: "Valuation",
      cellRenderer: CurrencyCell,
      width: 590,
    },
    {
      id: "viewDetails",
      accessor: (valuation) => valuation,
      header: "",
      width: 170,
      cellRenderer: ({ value }) => {
        return (
          <HyperLink
            onClick={() => {
              MixpanelEvents.openCustomValuationModal(companyName, false);
              setViewingValuation(value);
              setModalShowing(true);
            }}
          >
            View details
          </HyperLink>
        );
      },
    },
  ];

  return (
    <StyledValuationLayout>
      <ValuationStats companyId={companyId} latestValuation={latestValuation} />
      <Card
        title="My valuation history"
        headerTrailingContent={
          <Button
            buttonStyle={ButtonStyles.CTA}
            leadingIcon={PlusIcon}
            onClick={() => {
              MixpanelEvents.openCustomValuationModal(companyName, true);
              setModalShowing(true);
            }}
          >
            New valuation
          </Button>
        }
      >
        <StyledTableContainer>
          <Table
            isLoading={companyValuationsQuery.isLoading}
            usePercentageColumnWidths
            columns={tableColumns}
            data={companyValuationsQuery.cleanedData}
          />
        </StyledTableContainer>
      </Card>
      {modalShowing && (
        <ValuationCalculatorModal
          onClose={(event, skipMixpanel = false) => {
            if (!skipMixpanel) {
              MixpanelEvents.closeValuationModal(
                companyName,
                !viewingValuation
              );
            }
            setModalShowing(false);
            setViewingValuation(null);
          }}
          companyName={companyName}
          companyId={companyId}
          valuation={viewingValuation}
          previousValuations={valuations}
        />
      )}
    </StyledValuationLayout>
  );
};

CompanyValuation.propTypes = {
  companyName: PropTypes.string,
  companyId: PropTypes.string.isRequired,
};

CompanyValuation.defaultProps = {
  companyName: "",
};

export default CompanyValuation;
