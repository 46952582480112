import styled, { css } from "styled-components";
import { BuildingIcon, ColorPalette } from "yuka";
import React from "react";
import PropTypes from "prop-types";

const StyledThumbnailPlaceholderContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.$background &&
    css`
      background: ${props.$background};
    `}
  border-radius: 6px;
`;

/**
 * A simple component that wraps a Yuka icon in a grey box. Used as a placeholder for a
 * company/security logo.
 *
 * @param icon
 * @return {JSX.Element}
 */
const LogoPlaceholderIcon = ({ icon: Icon, color, backgroundColor }) => (
  <StyledThumbnailPlaceholderContainer $background={backgroundColor}>
    <Icon size={18} color={color} />
  </StyledThumbnailPlaceholderContainer>
);

LogoPlaceholderIcon.propTypes = {
  icon: PropTypes.elementType,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

LogoPlaceholderIcon.defaultProps = {
  icon: BuildingIcon,
  backgroundColor: ColorPalette.white50,
  color: ColorPalette.black50,
};

export default LogoPlaceholderIcon;
