import React from "react";
import { Button, Card, CardStyles, LinkTypes, StyledBody2 } from "yuka";

import {
  StyledLoginHeaderContainer,
  StyledLoginHeader,
} from "../StyledComponents";
import { ROUTING_PATH } from "../../routes/constants";

const ResetPassword = () => (
  <React.Fragment>
    <StyledLoginHeaderContainer>
      <StyledLoginHeader>Email sent!</StyledLoginHeader>
    </StyledLoginHeaderContainer>
    <Card cardStyle={CardStyles.PADDED}>
      <StyledBody2>
        We’ve emailed you instructions for setting your password. If an account
        exists with the email you entered, You should receive them shortly. If
        you don’t receive an email, please make sure you’ve entered the address
        you registered with, and check your spam folder.
      </StyledBody2>
      <Button linkType={LinkTypes.LOCAL_LINK} url={ROUTING_PATH.LOGIN()}>
        Back to Login
      </Button>
    </Card>
  </React.Fragment>
);

export default ResetPassword;
