// Styled
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";
import ErrorBoundary from "./ErrorBoundary";
import StateProvider from "./routes/StateProvider";
import AgreementProvider from "./routes/AgreementProvider";
import { MixpanelProvider } from "./utils/mixpanel/MixpanelUtils";

import { AuthProvider } from "./auth";
import MainRoutes from "./routes/component";

const StyledHDContainer = styled.div`
  color: rgba(255, 255, 255, 0.8);
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

const queryClient = new QueryClient();

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <StateProvider>
            <AuthProvider>
              <MixpanelProvider>
                <AgreementProvider>
                  <StyledHDContainer>
                    <ErrorBoundary>
                      <MainRoutes />
                    </ErrorBoundary>
                  </StyledHDContainer>
                </AgreementProvider>
              </MixpanelProvider>
            </AuthProvider>
          </StateProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("application")
  );
});
