import {
  FlexibleXYPlot,
  HorizontalGridLines,
  VerticalGridLines,
  VerticalBarSeries,
  XAxis,
  YAxis,
  LineSeries,
} from "react-vis";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { ColorPalette } from "yuka";

import LegendItem from "./LegendItem";
import NoDataPlaceholder from "./NoDataPlaceholder";
import {
  GraphContainer,
  ReportPeriodWrapper,
  StyledGraphCard,
  StyledGraphHeader,
  StyledTypography50,
} from "./StyledComponents";

import {
  AXIS_STYLE,
  FULL_OPACITY,
  GRIDLINE_STYLE,
  HALF_OPACITY,
  TIME_FRAME_MONTHLY,
} from "./constants";
import { percentFormat } from "/src/utils/displayFormatUtils";
import {
  createGraphArray,
  isFilledGraphEmpty,
  getMonthlyHoverPointsArray,
} from "./graphUtils";

/**
 * Renders the "Bid-Ask Ratio" graphs in Company Profile.
 *
 * @param {object} props
 * @returns {Element}
 */
const BidOfferRatioGraph = (props) => {
  const [volumeCrosshairValue, setVolumeCrosshairValue] = useState(null);
  const [countCrosshairValue, setCountCrosshairValue] = useState(null);

  // Calculate percent offer by using inverse of bid
  const percentOfferByVolume = useMemo(
    () =>
      _.map(props.percentBidByVolume, (d) => ({
        date: d.date,
        value: _.isNull(d.value) ? null : 1 - d.value,
      })),
    [props.percentBidByVolume]
  );
  const percentOfferByCount = useMemo(
    () =>
      _.map(props.percentBidByCount, (d) => ({
        date: d.date,
        value: _.isNull(d.value) ? null : 1 - d.value,
      })),
    [props.percentBidByCount]
  );

  // Multiply y values by 100 for percentage display on graph
  const bidByVolumeData = useMemo(
    () =>
      _.map(props.percentBidByVolume, (d) => ({
        date: d.date,
        value: d.value * 100,
      })),
    [props.percentBidByVolume]
  );
  const offerByVolumeData = useMemo(
    () =>
      _.map(percentOfferByVolume, (d) => ({
        date: d.date,
        value: d.value * 100,
      })),
    [percentOfferByVolume]
  );
  const bidByCountData = useMemo(
    () =>
      _.map(props.percentBidByCount, (d) => ({
        date: d.date,
        value: d.value * 100,
      })),
    [props.percentBidByCount]
  );
  const offerByCountData = useMemo(
    () =>
      _.map(percentOfferByCount, (d) => ({
        date: d.date,
        value: d.value * 100,
      })),
    [percentOfferByCount]
  );

  // map { date, value } paris to { x, y }
  const _bidByVolumeGraphData = createGraphArray(
    bidByVolumeData,
    TIME_FRAME_MONTHLY,
    props.endDate,
    props.hasDataAccess,
    false
  );
  const _offerByVolumeGraphData = createGraphArray(
    offerByVolumeData,
    TIME_FRAME_MONTHLY,
    props.endDate,
    props.hasDataAccess,
    false
  );
  const _bidByCountGraphData = createGraphArray(
    bidByCountData,
    TIME_FRAME_MONTHLY,
    props.endDate,
    props.hasDataAccess,
    false
  );
  const _offerByCountGraphData = createGraphArray(
    offerByCountData,
    TIME_FRAME_MONTHLY,
    props.endDate,
    props.hasDataAccess,
    false
  );

  // set opacity based on hover value
  const bidByVolumeGraphData = useMemo(
    () =>
      _.map(_bidByVolumeGraphData, (d) => ({
        ...d,
        opacity:
          volumeCrosshairValue && volumeCrosshairValue.x === d.x
            ? FULL_OPACITY
            : HALF_OPACITY,
      })),
    [_bidByVolumeGraphData, volumeCrosshairValue]
  );
  const offerByVolumeGraphData = useMemo(
    () =>
      _.map(_offerByVolumeGraphData, (d) => ({
        ...d,
        opacity:
          volumeCrosshairValue && volumeCrosshairValue.x === d.x
            ? FULL_OPACITY
            : HALF_OPACITY,
      })),
    [_offerByVolumeGraphData, volumeCrosshairValue]
  );
  const bidByCountGraphData = useMemo(
    () =>
      _.map(_bidByCountGraphData, (d) => ({
        ...d,
        opacity:
          countCrosshairValue && countCrosshairValue.x === d.x
            ? FULL_OPACITY
            : HALF_OPACITY,
      })),
    [_bidByCountGraphData, countCrosshairValue]
  );
  const offerByCountGraphData = useMemo(
    () =>
      _.map(_offerByCountGraphData, (d) => ({
        ...d,
        opacity:
          countCrosshairValue && countCrosshairValue.x === d.x
            ? FULL_OPACITY
            : HALF_OPACITY,
      })),
    [_offerByCountGraphData, countCrosshairValue]
  );

  // values must be null if the corresponding data point y-value is null
  const _bidVolumeValue = _.find(bidByVolumeGraphData, {
    x: volumeCrosshairValue?.x,
  })?.y;
  const bidVolumeValue = _.isNull(_bidVolumeValue) ? null : _bidVolumeValue;
  const offerVolumeValue = _.isNull(bidVolumeValue)
    ? null
    : 100 - bidVolumeValue;

  const _bidCountValue = _.find(bidByCountGraphData, {
    x: countCrosshairValue?.x,
  })?.y;
  const bidCountValue = _.isNull(_bidCountValue) ? null : _bidCountValue;
  const offerCountValue = _.isNull(bidCountValue) ? null : 100 - bidCountValue;

  const START_DATE = DateTime.fromISO(props.endDate).minus({ years: 1 });
  const emptyVolumeGraph = isFilledGraphEmpty([
    bidByVolumeGraphData,
    offerByVolumeGraphData,
  ]);
  const emptyCountGraph = isFilledGraphEmpty([
    bidByCountGraphData,
    offerByCountGraphData,
  ]);

  // used to ensure graph x-axis range is always 12 months even if
  // `props.percentBidByCount`/`props.percentBidByVolume` only contain 3 months worth of data
  // and to set crosshair values based on hovered region
  const hoverPointsArray = getMonthlyHoverPointsArray(props.endDate);

  const volumeReportPeriod = volumeCrosshairValue
    ? START_DATE.plus({
        months: volumeCrosshairValue.x,
      }).toLocaleString({
        month: "short",
        year: "2-digit",
      })
    : "--";

  const countReportPeriod = countCrosshairValue
    ? START_DATE.plus({
        months: countCrosshairValue.x,
      }).toLocaleString({
        month: "short",
        year: "2-digit",
      })
    : "--";

  return (
    <GraphContainer>
      <StyledGraphCard
        $isEmpty={emptyVolumeGraph}
        title={
          <StyledGraphHeader>
            <span>
              Bid-Ask Ratio <StyledTypography50>by</StyledTypography50> Volume
            </span>
            <ReportPeriodWrapper>
              <StyledTypography50>Report Period: </StyledTypography50>
              {volumeReportPeriod}
            </ReportPeriodWrapper>
          </StyledGraphHeader>
        }
        legend={[
          {
            text: (
              <LegendItem
                text={`Bid ${bidVolumeValue ? "" : "%"}`}
                value={bidVolumeValue ? percentFormat(bidVolumeValue, 0) : null}
              />
            ),
            color: ColorPalette.buy,
            tooltip: "Bid % calculated based on total interest",
          },
          {
            text: (
              <LegendItem
                text={`Ask ${offerVolumeValue ? "" : "%"}`}
                value={
                  offerVolumeValue ? percentFormat(offerVolumeValue, 0) : null
                }
              />
            ),
            color: ColorPalette.sell,
            tooltip: "Ask % calculated based on total interest",
          },
        ]}
      >
        {emptyVolumeGraph ? (
          <NoDataPlaceholder height="400px" />
        ) : (
          <FlexibleXYPlot
            margin={{ left: 0, right: 50 }}
            yDomain={[0, 100]}
            stackBy="y"
            onMouseLeave={() => {
              setVolumeCrosshairValue(null);
            }}
          >
            <LineSeries
              color="rgba(0, 0, 0, 0)"
              data={hoverPointsArray}
              onNearestX={(value) => {
                setVolumeCrosshairValue(value);
                props.onNearestX(value);
              }}
              strokeWidth={1}
            />
            <HorizontalGridLines style={GRIDLINE_STYLE} tickTotal={5} />
            <VerticalGridLines style={GRIDLINE_STYLE} tickTotal={12} />
            <VerticalBarSeries
              barWidth={0.5}
              color={ColorPalette.buy}
              data={bidByVolumeGraphData}
              onNearestX={(value) => {
                setVolumeCrosshairValue(value);
                props.onNearestX(value);
              }}
            />
            <VerticalBarSeries
              barWidth={0.5}
              color={ColorPalette.sell}
              data={offerByVolumeGraphData}
              onNearestX={(value) => {
                setVolumeCrosshairValue(value);
                props.onNearestX(value);
              }}
            />
            <XAxis
              style={AXIS_STYLE}
              tickSize={0}
              tickTotal={12}
              tickFormat={(value) =>
                START_DATE.plus({ months: value }).toLocaleString({
                  month: "short",
                  year: "2-digit",
                })
              }
            />
            <YAxis
              orientation="right"
              hideLine
              tickSize={0}
              tickTotal={5}
              tickFormat={(value) => `${value}%`}
              style={AXIS_STYLE}
            />
          </FlexibleXYPlot>
        )}
      </StyledGraphCard>
      <StyledGraphCard
        $isEmpty={emptyCountGraph}
        title={
          <StyledGraphHeader>
            <span>
              Bid-Ask Ratio <StyledTypography50>by</StyledTypography50> Tickets
            </span>
            <ReportPeriodWrapper>
              <StyledTypography50>Report Period: </StyledTypography50>
              {countReportPeriod}
            </ReportPeriodWrapper>
          </StyledGraphHeader>
        }
        legend={[
          {
            text: (
              <LegendItem
                text={`Bid ${bidCountValue ? "" : "%"}`}
                value={bidCountValue ? percentFormat(bidCountValue, 0) : null}
              />
            ),
            color: ColorPalette.buy,
            tooltip: "Bid % calculated based on number of tickets submitted",
          },
          {
            text: (
              <LegendItem
                text={`Ask ${offerCountValue ? "" : "%"}`}
                value={
                  offerCountValue ? percentFormat(offerCountValue, 0) : null
                }
              />
            ),
            color: ColorPalette.sell,
            tooltip: "Ask % calculated based on number of tickets submitted",
          },
        ]}
      >
        {emptyCountGraph ? (
          <NoDataPlaceholder height="400px" />
        ) : (
          <FlexibleXYPlot
            margin={{ left: 0, right: 50 }}
            yDomain={[0, 100]}
            stackBy="y"
            onMouseLeave={() => {
              setCountCrosshairValue(null);
            }}
          >
            <LineSeries
              color="rgba(0, 0, 0, 0)"
              data={hoverPointsArray}
              onNearestX={(value) => {
                setCountCrosshairValue(value);
                props.onNearestX(value);
              }}
              strokeWidth={1}
            />
            <HorizontalGridLines tickTotal={5} style={GRIDLINE_STYLE} />
            <VerticalGridLines tickTotal={12} style={GRIDLINE_STYLE} />
            <VerticalBarSeries
              barWidth={0.5}
              color={ColorPalette.buy}
              data={bidByCountGraphData}
              onNearestX={(value) => {
                setCountCrosshairValue(value);
                props.onNearestX(value);
              }}
            />
            <VerticalBarSeries
              barWidth={0.5}
              color={ColorPalette.sell}
              data={offerByCountGraphData}
              onNearestX={(value) => {
                setCountCrosshairValue(value);
                props.onNearestX(value);
              }}
            />
            <XAxis
              style={AXIS_STYLE}
              tickSize={0}
              tickTotal={12}
              tickFormat={(value) =>
                START_DATE.plus({ months: value }).toLocaleString({
                  month: "short",
                  year: "2-digit",
                })
              }
            />
            <YAxis
              orientation="right"
              hideLine
              tickSize={0}
              tickTotal={5}
              tickFormat={(value) => `${value}%`}
              style={AXIS_STYLE}
            />
          </FlexibleXYPlot>
        )}
      </StyledGraphCard>
    </GraphContainer>
  );
};

BidOfferRatioGraph.propTypes = {
  endDate: PropTypes.string.isRequired,
  onNearestX: PropTypes.func,
  hasDataAccess: PropTypes.bool,
  percentBidByVolume: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
  ).isRequired,
  percentBidByCount: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
  ).isRequired,
};

BidOfferRatioGraph.defaultProps = {
  onNearestX: _.noop,
  hasDataAccess: false,
};

export default BidOfferRatioGraph;
