import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Card,
  FontColors,
  YukaThemeProvider,
  NumberCell,
  BookmarkCell,
} from "yuka";

import { StyledError } from "../utils/StyledComponents";
import LoadingSpinner from "../utils/LoadingSpinner";
import { ROUTING_PATH } from "../routes/constants";
import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import useInfiniteFetch from "../api/useInfiniteFetch";
import useWrite from "../api/useWrite";
import useDelete from "../api/useDelete";
import {
  expandedMoneyFormat,
  percentFormat,
  shortMoneyFormat,
} from "../utils/displayFormatUtils";
import useDocumentTitle from "../utils/useDocumentTitle";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import { getLastRoundPriceDiff, convertRangeQueryParams } from "./utils";

import CompanyFilters from "./CompanyFilters";
import { COMPANY_LIST_FILTERS, LOADING_TEXT } from "./constants";
import LastRoundTooltip from "./tooltips/LastRoundTooltip";
import ZXIndexTooltip from "./tooltips/ZXIndexTooltip";
import SubtextCellRenderer from "./SubtextCellRenderer";

import {
  WEEKLY,
  MONEY_FORMAT_MIN_DECIMAL_PLACES,
  MONEY_FORMAT_MAX_DECIMAL_PLACES,
} from "../utils/constants";
import useFetches from "../api/useFetches";

const StyledCard = styled(Card)`
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 0;
  min-height: 0;
`;

const EmptyInfoContainer = styled.div`
  padding: 0 24px;
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  top: 95%;
`;

const StyledTableContainer = styled.div`
  overflow: hidden;
  margin-left: -16px;
  margin-right: -16px;
`;

const StyledTypography30 = styled.span`
  ${FontColors.theme30}
`;

const CompanyList = () => {
  useDocumentTitle("ZXData: Company List");
  const navigate = useNavigate();

  useEffect(() => {
    MixpanelEvents.viewCompanyList();
  }, []);

  const [selectedFilter, setSelectedFilter] = useState(
    COMPANY_LIST_FILTERS.MOST_ACTIVE
  );
  const [detailedFilters, setDetailedFilters] = useState({});

  useEffect(() => {
    const defaultFilters = {
      most_active: true,
      watchlist: false,
      all: false,
    };
    const filters = {
      filter_tab: selectedFilter,
      ...detailedFilters,
    };
    if (!_.isEqual(filters, defaultFilters)) {
      MixpanelEvents.filterCompanyList(filters);
    }
  }, [selectedFilter, detailedFilters]);

  // convert any range query params to separated min/max query params
  const transformedFilters = convertRangeQueryParams(detailedFilters);

  const companyQueryInfo = useInfiniteFetch(
    API_ENDPOINTS.COMPANY_LATEST_ORDER_FLOW(),
    {
      time_frame: WEEKLY,
      watchlist: selectedFilter === COMPANY_LIST_FILTERS.WATCHLIST,
      most_active: selectedFilter === COMPANY_LIST_FILTERS.MOST_ACTIVE,
      "page[size]": 30,
      ...transformedFilters,
    }
  );

  const watchlistQueryInfo = useFetch(API_ENDPOINTS.WATCHLIST_COMPANIES());
  const watchlistDelete = useDelete(API_ENDPOINTS.WATCHLIST_COMPANIES(), {
    silent: true,
  });
  const watchlistUpdate = useWrite(API_ENDPOINTS.WATCHLIST_COMPANIES(), false, {
    silent: true,
  });

  // map for quick lookup on watchlisted companies
  const watchlistMap = useMemo(() => {
    if (watchlistQueryInfo.isSuccess) {
      return watchlistQueryInfo.cleanedData.reduce((obj, watchlistItem) => {
        obj[watchlistItem.company] = watchlistItem;
        return obj;
      }, {});
    }
    return {};
  }, [watchlistQueryInfo.isSuccess, watchlistQueryInfo.cleanedData]);

  const companies = useMemo(() => {
    if (companyQueryInfo.isSuccess) {
      return companyQueryInfo.cleanedData.data;
    }
    return [];
  }, [companyQueryInfo.isSuccess, companyQueryInfo.cleanedData]);

  const hdFundingRoundsQuery = useFetches(
    companies?.map((company) => ({
      url: API_ENDPOINTS.HD_FUNDING_ROUNDS(),
      queryParams: {
        company: company.apiId,
        "page[size]": 1,
      },
    }))
  );

  // We'll determine the funding round related to each company based on ordering.
  const fundingRounds = useMemo(() => {
    if (hdFundingRoundsQuery.isAnySuccess) {
      return hdFundingRoundsQuery.cleanedData.map(
        (queryResult) => queryResult?.data?.[0]
      );
    }
    return [];
  }, [hdFundingRoundsQuery.isAnySuccess, hdFundingRoundsQuery.cleanedData]);

  const fundingRoundsMap = useMemo(() => {
    let result = {};
    for (let i = 0; i < companies.length; i++) {
      if (!fundingRounds[i] && hdFundingRoundsQuery.queryInfo[i].isLoading) {
        // Check to see if it's loading
        result[companies[i].apiId] = LOADING_TEXT;
      } else {
        result[companies[i].apiId] = fundingRounds[i];
      }
    }
    return result;
  }, [companies, fundingRounds]);

  if (companyQueryInfo.isLoading) {
    return (
      <StyledCard title="Company List">
        <CompanyFilters />
        <LoadingSpinner showContainer={false} absolute={false} />
      </StyledCard>
    );
  }

  if (companyQueryInfo.isError) {
    return (
      <StyledCard title="Company List">
        <CompanyFilters />
        <EmptyInfoContainer>
          <StyledError>An error has occurred</StyledError>
        </EmptyInfoContainer>
      </StyledCard>
    );
  }

  const onRowClick = ({ row: { index } }) => {
    const companyId = companies[index].apiId;

    navigate(ROUTING_PATH.COMPANY(companyId));
  };

  const tableColumns = [
    {
      id: "watchlist",
      accessor: (company) => {
        const isWatchlisted = company.apiId in watchlistMap;
        return isWatchlisted;
      },
      onBookmark: ({ row: { original: company }, event }) => {
        const companyId = company.apiId;
        if (companyId in watchlistMap) {
          watchlistDelete.mutate({ id: companyId });
        } else {
          watchlistUpdate.mutate({ company: companyId });
        }
        event?.stopPropagation();
      },
      cellRenderer: BookmarkCell,
      align: "center",
      width: 50,
      useFixedWidth: true,
      header: "",
    },
    {
      id: "name",
      accessor: "name",
      header: "Company",
      width: 23,
    },
    {
      id: "zx_index_value_change",
      accessor: (company) => ({
        zxIndexValue: company.zx_index_value,
        zxIndexValueChange: company.zx_index_value_percent_change,
        date: company.latest_order_flow_report_date,
      }),
      header: "ZX Index Value",
      width: 20,
      cellRenderer: SubtextCellRenderer,
      tooltip: ZXIndexTooltip,
      getMainText: ({ value }) =>
        value.zxIndexValue
          ? expandedMoneyFormat(
              value.zxIndexValue,
              MONEY_FORMAT_MIN_DECIMAL_PLACES,
              MONEY_FORMAT_MAX_DECIMAL_PLACES
            )
          : null,
      getSubtext: ({ value }) =>
        value.zxIndexValueChange
          ? (value.zxIndexValueChange > 0 ? "+" : "") +
            percentFormat(value.zxIndexValueChange * 100, 2)
          : null,
    },
    {
      id: "lastRoundPrice",
      accessor: (company) => company,
      header: "Last Round Price",
      width: 20,
      cellRenderer: SubtextCellRenderer,
      tooltip: LastRoundTooltip,
      getMainText: ({ value, fundingRoundsMap }) => {
        const fundingRound = fundingRoundsMap[value.apiId];
        if (fundingRound === LOADING_TEXT) {
          return LOADING_TEXT;
        }
        if (!fundingRound || !fundingRound.price_per_share) {
          return null;
        }
        return expandedMoneyFormat(
          fundingRound.price_per_share,
          MONEY_FORMAT_MIN_DECIMAL_PLACES,
          MONEY_FORMAT_MAX_DECIMAL_PLACES
        );
      },
      getSubtext: ({ value: company, fundingRoundsMap }) => {
        const fundingRound = fundingRoundsMap[company.apiId];
        if (
          fundingRound === LOADING_TEXT ||
          !fundingRound ||
          !fundingRound.price_per_share
        ) {
          return null;
        }
        return getLastRoundPriceDiff(
          fundingRound.price_per_share,
          company.zx_index_value
        );
      },
    },
    {
      id: "volume",
      accessor: "total_volume",
      header: "$ Volume (M)",
      width: 20,
      cellRenderer: NumberCell,
      formatter: (value) => shortMoneyFormat(value, 0),
    },
    {
      id: "robustnessScore",
      accessor: "robustness",
      header: "Robustness Score",
      width: 20,
      cellRenderer: NumberCell,
      formatter: (value) =>
        value || <StyledTypography30>--</StyledTypography30>,
    },
    {
      id: "bid_ask_ratio",
      accessor: "bid_ask_ratio",
      header: "Bid/Ask Ratio",
      width: 20,
      cellRenderer: NumberCell,
      formatter: (value) => percentFormat(value * 100, 0),
    },
  ];

  return (
    <StyledCard title="Company List">
      <CompanyFilters
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        detailedFilters={detailedFilters}
        setDetailedFilters={setDetailedFilters}
      />
      <YukaThemeProvider
        theme={{ componentVersion: 2, useTangeloTable: false }}
      >
        <StyledTableContainer>
          <Table
            data={companies}
            fundingRoundsMap={fundingRoundsMap}
            onRowClick={onRowClick}
            paginationFunc={
              companyQueryInfo.hasNextPage &&
              !companyQueryInfo.isFetchingNextPage
                ? companyQueryInfo.fetchNextPage
                : null
            }
            isPaginationLoading={companyQueryInfo.isFetchingNextPage}
            usePercentageColumnWidths
            columns={tableColumns}
          />
        </StyledTableContainer>
      </YukaThemeProvider>
      {companyQueryInfo.isFetchingNextPage && (
        <StyledLoadingSpinner absolute={true} />
      )}
    </StyledCard>
  );
};

export default CompanyList;
