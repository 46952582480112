import styled from "styled-components";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Card, FontColors, headline3 } from "yuka";

import useFetch from "../api/useFetch";
import useInfiniteFetch from "../api/useInfiniteFetch";
import { API_ENDPOINTS } from "../api/constants";
import LoadingSpinner from "../utils/LoadingSpinner";
import useDocumentTitle from "../utils/useDocumentTitle";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

import { ORDERING_FILTER_DEFAULT_VALUE } from "./constants";
import HeatMap from "./HeatMap";
import HeatMapFilters from "./HeatMapFilters";

const StyledMarketActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  // subtract height of global header
  // height: calc(100% - 88px);
`;

const StyledCard = styled(Card)`
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 0;
  min-height: 0;
`;

const StyledTitle = styled.div`
  ${headline3}
  ${FontColors.theme100}
  padding-bottom: 24px;
`;

const MarketActivityView = () => {
  useDocumentTitle("ZXData: Market Activity");
  const [selectedOrdering, setSelectedOrdering] = useState(
    ORDERING_FILTER_DEFAULT_VALUE
  );
  const [selectedSector, setSelectedSector] = useState(null);

  useEffect(() => {
    MixpanelEvents.viewMarketActivity();
  }, []);

  useEffect(() => {
    if (selectedSector) {
      MixpanelEvents.heatMapFilterSector(selectedSector);
    }
  }, [selectedSector]);

  const sectorsQuery = useFetch(API_ENDPOINTS.COMPANY_SECTORS(), {
    "page[size]": 100,
  });
  const latestReportsQuery = useInfiniteFetch(
    API_ENDPOINTS.COMPANY_LATEST_ORDER_FLOW_REPORTS(),
    {
      "page[size]": 500,
      ordering: selectedOrdering,
      ...(selectedSector ? { sector_name: selectedSector } : {}),
    },
    { keepPreviousData: true }
  );

  return (
    <React.Fragment>
      <StyledTitle>
        <span>Market Activity</span>
      </StyledTitle>
      <StyledMarketActivityContainer>
        <StyledCard title="Heat Map of ZX Index Value MoM Change">
          <HeatMapFilters
            selectedOrdering={selectedOrdering}
            selectedSector={selectedSector}
            setSelectedOrdering={setSelectedOrdering}
            setSelectedSector={setSelectedSector}
            sectorNames={_.map(sectorsQuery.cleanedData?.data, "name")}
          />
          {latestReportsQuery.isLoading || (
            <HeatMap reports={latestReportsQuery.cleanedData.data} />
          )}
          {latestReportsQuery.isFetchingNextPage && (
            <LoadingSpinner absolute={false} />
          )}
        </StyledCard>
      </StyledMarketActivityContainer>
    </React.Fragment>
  );
};

export default MarketActivityView;
