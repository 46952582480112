const LOADING_TEXT = "Loading...";

const COMPANY_LIST_FILTERS = {
  MOST_ACTIVE: "Most Active",
  WATCHLIST: "Watchlist",
  ALL: "All",
};

const ANY_POSITIVE = {
  min: 0,
  max: null,
};

const ONE_OR_MORE = {
  min: 1,
  max: null,
};

const FIVE_OR_MORE = {
  min: 5,
  max: null,
};

const TEN_OR_MORE = {
  min: 10,
  max: null,
};

const TWENTY_FIVE_OR_MORE = {
  min: 25,
  max: null,
};

const FIFTY_OR_MORE = {
  min: 50,
  max: null,
};

const ANY_NEGATIVE = {
  min: null,
  max: 0,
};

const NEGATIVE_ONE_OR_LESS = {
  min: null,
  max: -1,
};

const NEGATIVE_FIVE_OR_LESS = {
  min: null,
  max: -5,
};

const NEGATIVE_TEN_OR_LESS = {
  min: null,
  max: -10,
};

const NEGATIVE_TWENTY_FIVE_OR_LESS = {
  min: null,
  max: -25,
};

const NEGATIVE_FIFTY_OR_LESS = {
  min: null,
  max: -50,
};

const QUERY_PARAMS = {
  ANY_POSITIVE,
  ONE_OR_MORE,
  FIVE_OR_MORE,
  TEN_OR_MORE,
  TWENTY_FIVE_OR_MORE,
  FIFTY_OR_MORE,
  ANY_NEGATIVE,
  NEGATIVE_ONE_OR_LESS,
  NEGATIVE_FIVE_OR_LESS,
  NEGATIVE_TEN_OR_LESS,
  NEGATIVE_TWENTY_FIVE_OR_LESS,
  NEGATIVE_FIFTY_OR_LESS,
};

const COMPANY_LIST_DETAILED_FILTERS = {
  ROBUSTNESS: {
    name: "robustness",
    options: [
      { label: "2+", value: 2 },
      { label: "3+", value: 3 },
      { label: "4+", value: 4 },
      { label: "5+", value: 5 },
      { label: "6+", value: 6 },
      { label: "7+", value: 7 },
      { label: "8+", value: 8 },
      { label: "9+", value: 9 },
      { label: "10", value: 10 },
    ],
    defaultOption: "All Robustness Scores",
  },
  ZX_INDEX_VALUE: {
    name: "zx_index_value_change",
    options: [
      { label: "any +%", value: "ANY_POSITIVE" },
      { label: "+1%", value: "ONE_OR_MORE" },
      { label: "+5%", value: "FIVE_OR_MORE" },
      { label: "+10%", value: "TEN_OR_MORE" },
      { label: "any -%", value: "ANY_NEGATIVE" },
      { label: "-1%", value: "NEGATIVE_ONE_OR_LESS" },
      { label: "-5%", value: "NEGATIVE_FIVE_OR_LESS" },
      { label: "-10%", value: "NEGATIVE_TEN_OR_LESS" },
    ],
    defaultOption: "Any % ZX Index Value Change",
  },
  LAST_ROUND_PRICE: {
    name: "last_round_price_diff",
    options: [
      { label: "any +%", value: "ANY_POSITIVE" },
      { label: "+10%", value: "TEN_OR_MORE" },
      { label: "+25%", value: "TWENTY_FIVE_OR_MORE" },
      { label: "+50%", value: "FIFTY_OR_MORE" },
      { label: "any -%", value: "ANY_NEGATIVE" },
      { label: "-10%", value: "NEGATIVE_TEN_OR_LESS" },
      { label: "-25%", value: "NEGATIVE_TWENTY_FIVE_OR_LESS" },
      { label: "-50%", value: "NEGATIVE_FIFTY_OR_LESS" },
    ],
    defaultOption: "Any % Premium/Discount to Last Round",
  },
};

export {
  LOADING_TEXT,
  COMPANY_LIST_FILTERS,
  COMPANY_LIST_DETAILED_FILTERS,
  QUERY_PARAMS,
};
