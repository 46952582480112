import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";

/**
 * Tooltip for a company's ZX Index Value in Company List
 *
 * @returns {React.Element}
 */
const ZXIndexTooltip = (props) => {
  const zxIndexValue = props.column.getMainText(props);
  const zxIndexValueChange = props.column.getSubtext(props);
  const date = DateTime.fromISO(props.value?.date).toLocaleString(
    DateTime.DATE_SHORT
  );

  if (!zxIndexValue) {
    return null;
  }

  return (
    <React.Fragment>
      <p>
        {zxIndexValue} is the ZX Index Value as of {date}
      </p>
      {zxIndexValueChange && (
        <p>The index value has changed by {zxIndexValueChange} vs. last week</p>
      )}
    </React.Fragment>
  );
};

ZXIndexTooltip.propTypes = {
  column: PropTypes.shape({
    getMainText: PropTypes.func.isRequired,
    getSubtext: PropTypes.func.isRequired,
  }).isRequired,
  value: PropTypes.shape({
    date: PropTypes.string,
  }).isRequired,
};
export default ZXIndexTooltip;
