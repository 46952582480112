import React from "react";
import styled from "styled-components";
import { PageSection } from "yuka";

import CompanySearch from "./CompanySearch";

const StyledGlobalHeaderContainer = styled(PageSection)`
  padding: 16px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

/**
 * Global header
 *
 * @returns {React.Element}
 */
const GlobalHeader = () => {
  return (
    <StyledGlobalHeaderContainer>
      <CompanySearch />
    </StyledGlobalHeaderContainer>
  );
};

export default GlobalHeader;
