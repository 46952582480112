import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import styled, { css } from "styled-components";

import getLinkProps, { LinkTypes } from "./getLinkProps";

import { ColorPalette, YukaColorPalette } from "../StylingConstants";
import AlertDot from "./AlertDot";

const IconButtonStyles = {
  STANDARD: "STANDARD",
  OUTLINE: "OUTLINE",
  KEY_ACTION: "KEY_ACTION",
};

const StyledAlertDot = styled.div`
  position: absolute;
  height: 8px;
  width: 8px;

  ${props =>
    props.$buttonStyle === IconButtonStyles.KEY_ACTION
      ? css`
          right: 12px;
          top: 8px;
        `
      : css`
          right: 5px;
          top: 1px;
        `}
`;

const StyledButton = styled.button.attrs({ className: "icon-btn" })`
  // Apply selector using class name instead of & because styled components acts weird
  .btn + &,
  .icon-btn + & {
    margin-left: 12px;
  }
  position: relative;
  justify-content: center;
  align-items: center;
  border: 0px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  outline: none;

  .flex-input + & {
    // align icon buttons with our inputs
    // we move up from the bottom because flex-input may have a label above, so centering
    // and positioning from the top are not reliable
    align-self: flex-end;
    margin-bottom: 4px;
  }

  // 1 - Icon
  // icon size and color set in props

  // 2 - State layer
  ${props =>
    props.$buttonStyle === IconButtonStyles.KEY_ACTION
      ? css`
          border-radius: 12px;
          height: 44px;
          width: 44px;
          padding: 13px;
          background: ${props.$selected ? ColorPalette.blue200 : ColorPalette.white10};
        `
      : css`
          border-radius: 50%;
          height: 32px;
          width: 32px;
          padding: 8px;
          background: transparent;
        `}
  line-height: 16px;

  ${props => {
    if (props.disabled) {
      return props.$buttonStyle === IconButtonStyles.KEY_ACTION
        ? css`
            cursor: default;
            background: ${ColorPalette.white05};
          `
        : css`
            cursor: default;
            background: rgba(255, 255, 255, 0);
          `;
    } else {
      if (props.$buttonStyle === IconButtonStyles.KEY_ACTION) {
        if (props.$selected) {
          return css`
            &:hover {
              background: ${ColorPalette.blue300};
            }

            &:active {
              background: ${ColorPalette.blue300};

              svg path {
                fill: ${ColorPalette.blue700};
              }
              circle {
                fill: ${ColorPalette.blue700};
              }
            }
          `;
        } else {
          return css`
            &:hover {
              background: ${ColorPalette.white15};
            }

            &:active {
              background: rgba(255, 255, 255, 0.17);
            }
          `;
        }
      } else {
        return css`
          &:hover {
            background: ${YukaColorPalette.hoverTransparent};
          }

          &:active {
            background: ${YukaColorPalette.activeTransparent};
          }
        `;
      }
    }
  }}

  ${props => {
    if (props.$buttonStyle === IconButtonStyles.OUTLINE) {
      if (props.disabled) {
        return css`
          border: 1px solid ${ColorPalette.white15};
        `;
      }
      return css`
        border: 1px solid ${ColorPalette.white30};
      `;
    }
  }}
`;

/**
 * Wrapper component for SVG icon buttons.
 *
 * to (DEPRECATED) - This prop is a string or object that react-router can parse, and if specified, results in
 *   an `<a>` tag being rendered and managed by react router. Should be used for in-app routing
 *
 * href (DEPRECATED) - This prop is a string that is a valid href that is rendered as the corresponding
 *   attribute in an `<a>` tag managed as normal by the browser. Should be used for out of app
 *   routing.
 *
 * url - This prop is a string that is a valid href or a string/object that react-router can parse.
 *   This results in either an `<a>` tag or a `<Link>` being rendered.
 *
 * Either url, to, or href must be specified for `target`, `rel`, or `download` attributes to be applied
 *
 * onClick - This prop is a function handler that results in an html `<button>` being rendered if
 *   none of the above are specified. Must be set for `type` to be applied.
 *
 * buttonStyle - Render a standard or outline IconButton.
 *   If not supplied then we render a standard IconButton.
 *
 * LinkTypes guideline:
 * - `BUTTON` - when no navigation is performed but need a text link visually
 * - `DOWNLOAD` - when the link is directly to a document to be downloaded
 * - `LOCAL_LINK` - the link navigates within a SPA
 * - `EXTERNAL_LINK` - the link navigates away from the environment, opens in a new tab
 * - `LINK` - a traditional `<a>` tag without any special behaviors
 */
const IconButton = React.forwardRef((props, ref) => {
  let iconColor;
  if (props.disabled) {
    iconColor = ColorPalette.white15;
  } else if (props.buttonStyle === IconButtonStyles.KEY_ACTION && props.selected) {
    iconColor = ColorPalette.blue500;
  } else {
    iconColor = ColorPalette.white50;
  }

  const legacyProps = _.pick(props, [
    "newTab",
    "accent",
    "download",
    "href",
    "to",
    "active",
    "rel",
    "target",
  ]);
  const [computedLinkProps, renderAs] = getLinkProps(props.url, props.linkType, legacyProps);

  return (
    <StyledButton
      ref={ref}
      {...computedLinkProps}
      id={props.id}
      className={props.className}
      data-testid={props.dataTestid}
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.type}
      $selected={props.buttonStyle === IconButtonStyles.KEY_ACTION && props.selected}
      $buttonStyle={props.buttonStyle}
      as={renderAs}
      role={props.role}
    >
      <props.icon size={16} color={iconColor} />
      {props.hasAlert && (
        <StyledAlertDot $buttonStyle={props.buttonStyle}>
          <AlertDot />
        </StyledAlertDot>
      )}
    </StyledButton>
  );
});

IconButton.propTypes = {
  icon: PropTypes.func.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  dataTestid: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  url: PropTypes.string,
  type: PropTypes.string,
  linkType: PropTypes.oneOf([...Object.values(LinkTypes)]),
  selected: PropTypes.bool,
  hasAlert: PropTypes.bool,
  buttonStyle: PropTypes.oneOf(Object.values(IconButtonStyles)),
  /** Aria role */
  role: PropTypes.string,
};

IconButton.defaultProps = {
  className: "",
  dataTestid: "",
  selected: false,
  disabled: false,
  href: null,
  onClick: _.noop,
  to: null,
  url: null,
  type: "button",
  buttonStyle: IconButtonStyles.STANDARD,
};

IconButton.displayName = "IconButton";

export default IconButton;
export { IconButtonStyles };
