import { Button, ColorPalette, YukaColorPalette } from "yuka";

import styled, { css } from "styled-components";

const StyledRow = styled.div`
  position: relative;
  display: grid;
  // Simulate a table's columns.
  grid-template-columns: 50% 20% 30%;
  row-gap: 16px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid
    ${({ $isSelected }) =>
      $isSelected ? ColorPalette.white05 : ColorPalette.white15};
  background: ${({ $isSelected }) =>
    $isSelected ? ColorPalette.white10 : "transparent"};

  ${(props) =>
    props.$noInteraction
      ? css``
      : css`
          cursor: pointer;

          &:hover {
            background: ${YukaColorPalette.hoverTransparent};
          }
          &:active {
            background: ${YukaColorPalette.activeTransparent};
          }
        `}
`;

const StyledDetailPanelFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const RestoreDefaultsButton = styled(Button)`
  align-self: flex-end;
`;

export { StyledRow, StyledDetailPanelFields, RestoreDefaultsButton };
