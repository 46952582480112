import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonStyles, LinkTypes } from "yuka";
import styled from "styled-components";

import {
  StyledLoginHeaderContainer,
  StyledLoginHeader,
  StyledLoginSubHeader,
  Caption2HyperLink,
} from "./StyledComponents";

import { Form } from "react-final-form";
import LoadingSpinner from "../utils/LoadingSpinner";
import { AcceptAgreementsFields, SetPasswordFields } from "../form-fields";
import { ROUTING_PATH } from "../routes/constants";
import PasswordSecurityTips from "../utils/PasswordSecurityTips";
import useUnfocusOnSubmit from "../utils/useUnfocusOnSubmit";

const StyledButton = styled(Button)`
  margin-right: 12px;
`;

const SetNewPassword = (props) => {
  const [onSubmit, submitRef] = useUnfocusOnSubmit(props.onSubmit);

  return (
    <React.Fragment>
      <StyledLoginHeaderContainer>
        <StyledLoginHeader>Set your new password</StyledLoginHeader>
        <StyledLoginSubHeader>
          <PasswordSecurityTips />
        </StyledLoginSubHeader>
      </StyledLoginHeaderContainer>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, dirty }) => (
          <form onSubmit={handleSubmit}>
            {submitting && <LoadingSpinner />}
            <SetPasswordFields />
            {props.includeAgreements && <AcceptAgreementsFields />}
            <StyledButton
              ref={submitRef}
              buttonStyle={ButtonStyles.CTA}
              disabled={submitting || !dirty}
              type="submit"
            >
              Set password
            </StyledButton>
            <Caption2HyperLink
              linkType={LinkTypes.LOCAL_LINK}
              url={ROUTING_PATH.LOGIN()}
            >
              Back to Login
            </Caption2HyperLink>
          </form>
        )}
      </Form>
    </React.Fragment>
  );
};

SetNewPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  includeAgreements: PropTypes.bool.isRequired,
};

export default SetNewPassword;
