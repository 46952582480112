import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BadgeCell, FontColors } from "yuka";

import { EVENT_NAME_COLUMN_ID, STOCK_SPLIT } from "./constants";

const StyledSubRound = styled.span`
  ${FontColors.theme50};
`;

/**
 * Renders a cell used in the Funding Rounds table. Since cell stock splits are rendered as a
 * simple badge in the first column followed by a row of empty cells, this component handles
 * rendering both stock splits and funding rounds by allowing funding round cells to specify their
 * own default values (typically a white30 --), but also automatically using a blank cell for
 * stock split rows.
 */
const FundingRoundOrStockSplitCellRenderer = ({
  value,
  column: { fundingRoundCellRenderer: CellRenderer, ...column },
  ...props
}) => {
  if (value?.apiType === STOCK_SPLIT) {
    const multiplier = parseFloat(value.multiplier).toString();
    const description = `${multiplier}:1 Stock Split`;
    // Renders a blank cell for all cells beyond the first column.
    return column?.id === EVENT_NAME_COLUMN_ID ? (
      <BadgeCell value={description} column={column} {...props} />
    ) : null;
  }
  const Wrapper = value?.parent_funding_round ? StyledSubRound : React.Fragment;
  if (CellRenderer) {
    // We raw-call this renderer so we can actually get the underlying rendered value so automatic
    // tooltipping kicks in on the Table side.
    return (
      <Wrapper>
        <CellRenderer value={value} column={column} {...props} />
      </Wrapper>
    );
  }
  return <Wrapper>{value}</Wrapper>;
};

FundingRoundOrStockSplitCellRenderer.propTypes = {
  value: PropTypes.shape({
    apiType: PropTypes.string,
    parent_funding_round: PropTypes.shape({}),
    multiplier: PropTypes.string,
  }).isRequired,
  column: PropTypes.shape({
    fundingRoundCellRenderer: PropTypes.func,
  }).isRequired,
};

export default FundingRoundOrStockSplitCellRenderer;
