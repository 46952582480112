import React, { useMemo, useRef, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Fonts, ListItem, Thumbnail, StyledCaption2 } from "yuka";
import useInfiniteFetch from "../../api/useInfiniteFetch";
import { API_ENDPOINTS } from "../../api/constants";
import { ROUTING_PATH } from "../../routes/constants";
import highlightText from "../utils/highlightText";
import { shortMoneyFormat } from "../../utils/displayFormatUtils";
import { WEEKLY } from "../../utils/constants";
import useScrollable from "../../utils/useScrollable";
import { useNavigate } from "react-router-dom";
import { StyledList, StyledPlaceholderTextContainer } from "./StyledComponents";
import {
  FETCH_NEXT_COMPANIES_PAGE_THRESHOLD,
  MIN_SEARCH_QUERY_LENGTH,
} from "../constants";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";
import ProfileContext from "../../superchart/ProfileContext";
import LoadingSpinner from "../../utils/LoadingSpinner";
import LogoPlaceholderIcon from "../../superchart/LogoPlaceholderIcon";
import { StyledLoadingSpinnerContainer } from "../../utils/StyledComponents";

const CompanyDropdownList = ({
  searchQuery,
  toggleDropdown,
  watchlist = false,
}) => {
  const searchResultsRef = useRef(null);
  const navigate = useNavigate();
  const { setProfile } = useContext(ProfileContext);

  const showSearchResults = useMemo(
    () => searchQuery.length >= MIN_SEARCH_QUERY_LENGTH,
    [searchQuery]
  );

  const companyQuery = useInfiniteFetch(
    API_ENDPOINTS.COMPANY_LATEST_ORDER_FLOW(),
    {
      time_frame: WEEKLY,
      "filter[search]": showSearchResults ? searchQuery : undefined,
      watchlist: watchlist,
    },
    { enabled: true }
  );

  const companies = useMemo(() => {
    return companyQuery.isSuccess ? companyQuery.cleanedData.data : [];
  }, [companyQuery.isSuccess, companyQuery.cleanedData]);

  const onScrollDownFetchNext = useCallback(
    (distanceFromBottom) => {
      if (
        distanceFromBottom < FETCH_NEXT_COMPANIES_PAGE_THRESHOLD &&
        companyQuery.hasNextPage &&
        !companyQuery.isFetchingNextPage
      ) {
        companyQuery.fetchNextPage().then((response) => response.data);
      }
    },
    [companyQuery]
  );

  useScrollable({
    scrollRef: searchResultsRef,
    onScrollUp: _.noop,
    onScrollDown: onScrollDownFetchNext,
  });

  return (
    <>
      <StyledList divider ref={searchResultsRef}>
        {companies.map((company, idx) => (
          <ListItem
            key={`company_${idx}`}
            onClick={() => {
              toggleDropdown();
              MixpanelEvents.superChartSelectCompany(company.name);
              setProfile(null);
              return navigate(ROUTING_PATH.SUPERCHART(company.apiId));
            }}
            text={
              showSearchResults
                ? highlightText(company.name, searchQuery)
                : company.name
            }
            avatar={
              company.main_picture ? (
                <Thumbnail src={company.main_picture} size="24px" />
              ) : (
                <LogoPlaceholderIcon />
              )
            }
            trailingContent={
              <StyledCaption2>
                <Fonts.Overlinetheme30>ZX Index Value</Fonts.Overlinetheme30>{" "}
                <Fonts.Caption2theme100>
                  {company.zx_index_value
                    ? shortMoneyFormat(company.zx_index_value)
                    : "--"}
                </Fonts.Caption2theme100>
              </StyledCaption2>
            }
          />
        ))}
        {companyQuery.isFetching && (
          <StyledLoadingSpinnerContainer>
            <LoadingSpinner absolute={false} />
          </StyledLoadingSpinnerContainer>
        )}
      </StyledList>
      {companies.length === 0 && !companyQuery.isFetching && (
        <StyledPlaceholderTextContainer>
          {showSearchResults
            ? `No ${
                watchlist ? "watchlisted " : ""
              }company name matches "${searchQuery}"`
            : `No ${watchlist ? "watchlisted " : ""}companies`}
        </StyledPlaceholderTextContainer>
      )}
    </>
  );
};

CompanyDropdownList.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  watchlist: PropTypes.bool,
};

export default CompanyDropdownList;
