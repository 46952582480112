import { useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import { GraphContainer } from "./StyledComponents";
import useEndDate from "./hooks/useEndDate";

import useFetch from "../../api/useFetch";
import { API_ENDPOINTS } from "../../api/constants";
import {
  ORDER_FLOW_TIME_FRAME_MONTHLY,
  ORDER_FLOW_TIME_FRAME_WEEKLY,
} from "../../utils/constants";
import LoadingSpinner from "../../utils/LoadingSpinner";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";

import BidAskPriceGraph from "./graph-components/BidAskPriceGraph";
import {
  TIME_FRAME_MONTHLY,
  TIME_FRAME_WEEKLY,
} from "./graph-components/constants";

/**
 * Renders the "Trading Price" graphs in Company Profile.
 */
const TradingPriceGraphView = (props) => {
  const orderFlowQueryInfo = useFetch(
    `${API_ENDPOINTS.COMPANY_TRADING_PRICE_DATA(props.companyId, {
      time_frame: props.timeFrame,
    })}?time_frame=${props.timeFrame}`
  );

  const endDate = useEndDate(props.timeFrame);

  useEffect(() => {
    if (props.companyName) {
      MixpanelEvents.viewCompanyBidAskPriceGraph(props.companyName);
    }
  }, [props.companyName]);

  if (orderFlowQueryInfo.isLoading) {
    return <LoadingSpinner />;
  }

  const orderFlowData = orderFlowQueryInfo.cleanedData?.data;

  const graphTimeFrame =
    props.timeFrame === ORDER_FLOW_TIME_FRAME_MONTHLY
      ? TIME_FRAME_MONTHLY
      : TIME_FRAME_WEEKLY;

  const robustnessScore = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.robustness,
  }));

  const zxBidIndexPrice = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.zx_index_value_bid_side,
  }));

  const zxOfferIndexPrice = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.zx_index_value_ask_side,
  }));

  const bidDollarWeightedAverage = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.bid_dwa,
  }));
  const bidSimpleAverage = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.bid_simple_average,
  }));

  const askDollarWeightedAverage = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.ask_dwa,
  }));
  const askSimpleAverage = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.ask_simple_average,
  }));

  return (
    <GraphContainer>
      <BidAskPriceGraph
        title="Bid-Ask Price"
        hasDataAccess={props.hasDataAccess}
        endDate={endDate}
        timeFrame={graphTimeFrame}
        zxIndexBuyValue={zxBidIndexPrice}
        zxIndexSellValue={zxOfferIndexPrice}
        askDollarWeightedAverage={askDollarWeightedAverage}
        askSimpleAverage={askSimpleAverage}
        bidDollarWeightedAverage={bidDollarWeightedAverage}
        bidSimpleAverage={bidSimpleAverage}
        robustnessScore={robustnessScore}
      />
    </GraphContainer>
  );
};

TradingPriceGraphView.propTypes = {
  companyId: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  hasDataAccess: PropTypes.bool,
  timeFrame: PropTypes.oneOf([
    ORDER_FLOW_TIME_FRAME_MONTHLY,
    ORDER_FLOW_TIME_FRAME_WEEKLY,
  ]),
};

TradingPriceGraphView.defaultProps = {
  hasDataAccess: false,
  timeFrame: ORDER_FLOW_TIME_FRAME_MONTHLY,
};

export default TradingPriceGraphView;
