const ROUTING_PATH = {
  // market activity routes
  MARKET_ACTIVITY: () => "/market-activity",

  // company routes
  COMPANIES: () => "/company",
  COMPANY: (id) => `/company/${id}`,

  // user routes
  ACCOUNT: () => "/account",
  ACCOUNT_EMAIL: () => "/account/email",
  ACCOUNT_PASSWORD: () => "/account/password",
  ACCOUNT_CHANGE_EMAIL_CONFIRM: () => "/account/change_email/",

  // agreements routes
  AGREEMENTS: () => "/agreement/",
  AGREEMENT: (id) => `/agreement/${id}/`,

  // public comps routes
  SUPERCHARTS: () => "/superchart/",
  SUPERCHART: (id) => `/superchart/${id}/`,

  // insights routes
  INSIGHTS: () => "/insights",

  // login routes
  LOGIN: () => "/login",
  RESET_PASSWORD: () => "/resetpassword",
  RESET_PASSWORD_CONFIRM: () => "/resetpassword/confirm",
  SET_PASSWORD_BASE: () => "/setpassword/",
  SET_PASSWORD_NEW_USER: () => "/setpassword/new/",
};

const UNAUTHENTICATED_ROUTES = [
  ROUTING_PATH.LOGIN(),
  ROUTING_PATH.RESET_PASSWORD(),
  ROUTING_PATH.RESET_PASSWORD_CONFIRM(),
];

// IMPORTANT NOTE THAT YOU SHOULD READ WHILE YOU'RE IN THIS FILE:
// If a route that is unauthenticated has variable subcomponents i.e. an ID like:
// /setpassword/new/3bb892c58233437f74b99b?email=0380a201-ee70-4c42-b5be-6272acefb285
// Then you MUST include this route somehow in the "startsWith" logic below.
const isUnauthenticatedRoute = (route) =>
  route.startsWith("/agreement") ||
  route.startsWith("/setpassword") ||
  UNAUTHENTICATED_ROUTES.includes(route);

export { ROUTING_PATH, isUnauthenticatedRoute };
