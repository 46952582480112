import useFetch from "../api/useFetch";
import { API_ENDPOINTS } from "../api/constants";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { Card, CardStyles, Definition, Fonts, List } from "yuka";

import LoadingSpinner from "../utils/LoadingSpinner";
import styled from "styled-components";
import roundDecimal from "../utils/roundDecimal";
import AlternativeCardState from "./AlternativeCardState";
import { ERROR_MESSAGE } from "./constants";

const StyledDefinition = styled(Definition)`
  > span {
    max-width: unset;
  }
`;

const StyledNestedDefinition = styled(Definition)`
  > span {
    max-width: unset;
    padding-left: 16px;
  }
`;

const StyledFootnote = styled(Fonts.Caption1theme30)`
  padding-top: 8px;
  align-self: flex-end;
`;

const CARD_TITLE = "Company Transferability";

const CompanyTransferabilityCard = ({ companyId }) => {
  const transactionRecordAggregateQuery = useFetch(
    API_ENDPOINTS.TRANSACTION_RECORD_AGGREGATES(),
    {
      company: companyId,
    }
  );

  const transactionRecordData = useMemo(
    () =>
      transactionRecordAggregateQuery.isSuccess
        ? transactionRecordAggregateQuery.cleanedData?.data?.[0]
        : {},
    [
      transactionRecordAggregateQuery.isSuccess,
      transactionRecordAggregateQuery.cleanedData?.data,
    ]
  );

  if (transactionRecordAggregateQuery.isLoading) {
    return (
      <AlternativeCardState cardTitle={CARD_TITLE}>
        <LoadingSpinner absolute={false} />
      </AlternativeCardState>
    );
  }

  if (transactionRecordAggregateQuery.isError) {
    return (
      <AlternativeCardState cardTitle={CARD_TITLE}>
        {ERROR_MESSAGE}
      </AlternativeCardState>
    );
  }

  if (!transactionRecordData) {
    return (
      <AlternativeCardState cardTitle={CARD_TITLE}>
        No information available to disclose
      </AlternativeCardState>
    );
  }

  const approvedTotalPercentage = roundDecimal(
    (transactionRecordData.approved_volume_percentage || 0) * 100,
    0
  );
  const approvedPreferredPercentage = roundDecimal(
    (transactionRecordData.approved_volume_preferred_percentage || 0) * 100,
    0
  );
  const approvedCommonPercentage = roundDecimal(
    (transactionRecordData.approved_volume_common_percentage || 0) * 100,
    0
  );
  const approvedUnknownPercentage = roundDecimal(
    Math.max(
      approvedTotalPercentage -
        approvedPreferredPercentage -
        approvedCommonPercentage,
      0
    ),
    0
  );
  const rofrVolumeTotalPercentage = roundDecimal(
    (transactionRecordData.rofr_volume_total_percentage || 0) * 100,
    0
  );
  const rofrVolumePreferredPercentage = roundDecimal(
    (transactionRecordData.rofr_volume_preferred_percentage || 0) * 100,
    0
  );
  const rofrVolumeCommonPercentage = roundDecimal(
    (transactionRecordData.rofr_volume_common_percentage || 0) * 100,
    0
  );
  const rofrUnknownPercentage = roundDecimal(
    Math.max(
      rofrVolumeTotalPercentage -
        rofrVolumePreferredPercentage -
        rofrVolumeCommonPercentage,
      0
    ),
    0
  );
  const spvClosedVolumePercentage = roundDecimal(
    (transactionRecordData.spv_volume_percentage || 0) * 100,
    0
  );
  const forwardVolumePercentage = roundDecimal(
    (transactionRecordData.derivative_volume_percentage || 0) * 100,
    0
  );

  if (
    approvedTotalPercentage +
      rofrVolumeTotalPercentage +
      spvClosedVolumePercentage +
      forwardVolumePercentage <=
    0
  ) {
    return (
      <AlternativeCardState cardTitle={CARD_TITLE}>
        No information available to disclose
      </AlternativeCardState>
    );
  }

  return (
    <Card title={CARD_TITLE} cardStyle={CardStyles.PADDED}>
      <List>
        <List divider>
          <StyledDefinition
            label="Direct Trades (Approved)"
            value={`${approvedTotalPercentage}%`}
          />
        </List>
        <StyledNestedDefinition
          label="Preferred"
          value={`${approvedPreferredPercentage}%`}
        />
        <StyledNestedDefinition
          label="Common"
          value={`${approvedCommonPercentage}%`}
        />
        {approvedUnknownPercentage !== 0 && (
          <StyledNestedDefinition
            label="Unknown"
            value={`${approvedUnknownPercentage}%`}
          />
        )}
        <List divider>
          <StyledDefinition
            label="Direct Trades (ROFR'd)"
            value={`${rofrVolumeTotalPercentage}%`}
          />
        </List>
        <StyledNestedDefinition
          label="Preferred"
          value={`${rofrVolumePreferredPercentage}%`}
        />
        <StyledNestedDefinition
          label="Common"
          value={`${rofrVolumeCommonPercentage}%`}
        />
        {rofrUnknownPercentage !== 0 && (
          <StyledNestedDefinition
            label="Unknown"
            value={`${rofrUnknownPercentage}%`}
          />
        )}
        <List divider>
          <StyledDefinition
            label="Trades in SPV"
            value={`${spvClosedVolumePercentage}%`}
          />
        </List>
        <List divider>
          <StyledDefinition
            label="Trades via Forward"
            value={`${forwardVolumePercentage}%`}
          />
        </List>
      </List>
      <StyledFootnote>* Percent of transfers by volume</StyledFootnote>
    </Card>
  );
};

CompanyTransferabilityCard.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default CompanyTransferabilityCard;
