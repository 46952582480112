import { createContext, useReducer, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const GlobalContext = createContext(null);

const [
  addToast,
  removeToast,
  addBanner,
  removeBanner,
  registerDropdown,
  unregisterDropdown,
  toggleDropdown,
  disableHotkeys,
  enableHotkeys,
] = [
  "ADD_TOAST",
  "REMOVE_TOAST",
  "ADD_BANNER",
  "REMOVE_BANNER",
  "REGISTER_DROPDOWN",
  "UNREGISTER_DROPDOWN",
  "TOGGLE_DROPDOWN",
  "DISABLE_HOTKEYS",
  "ENABLE_HOTKEYS",
];

const ACTIONS = {
  addToast,
  removeToast,
  addBanner,
  removeBanner,
  registerDropdown,
  unregisterDropdown,
  toggleDropdown,
  enableHotkeys,
  disableHotkeys,
};

const reducer = (state, action) => {
  switch (action.type) {
    case addToast:
      return {
        ...state,
        toasts: [
          ...state.toasts,
          { id: Math.random(), message: action.message },
        ],
      };
    case removeToast:
      return {
        ...state,
        toasts: _.reject(state.toasts, { id: action.id }),
      };
    case addBanner:
      return {
        ...state,
        banners: [...state.banners, action.banner],
      };
    case removeBanner:
      state.banners.shift();
      return { ...state, banners: [...state.banners] };
    case registerDropdown:
      if (action.id in state.registeredDropdowns) {
        // eslint-disable-next-line no-console
        console.warn(
          `Dropdown is already registered with id "${action.id}"! Dropdown IDs must be globally unique.`
        );
      }
      return {
        ...state,
        registeredDropdowns: {
          ...state.registeredDropdowns,
          [action.id]: [action.toggleDropdown, false],
        },
      };
    case unregisterDropdown:
      return {
        ...state,
        registeredDropdowns: _.omit(state.registeredDropdowns, action.id),
      };
    case toggleDropdown:
      return {
        ...state,
        registeredDropdowns: {
          ...state.registeredDropdowns,
          [action.id]: [
            state.registeredDropdowns[action.id][0],
            !state.registeredDropdowns[action.id][1],
          ],
        },
      };
    case disableHotkeys:
      return {
        ...state,
        hotkeysEnabled: false,
      };
    case enableHotkeys:
      return {
        ...state,
        hotkeysEnabled: true,
      };
    // Falls through to default case and returns state;
    default:
      return state;
  }
};

const StateProvider = (props) => {
  const stateInitializer = () => ({
    toasts: [],
    banners: [],
    registeredDropdowns: {},
    hotkeysEnabled: true,
  });

  // A global data store to be shared by all children.
  const [state, dispatch] = useReducer(reducer, {}, stateInitializer);

  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useSelector = (fn) => {
  const { state } = useContext(GlobalContext);

  // Don't include fn in dependencies so an arrow function can be provided
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const val = useMemo(() => fn(state), [state]);

  return val;
};

const useDispatch = () => {
  const { dispatch } = useContext(GlobalContext);
  return dispatch;
};

export { GlobalContext, ACTIONS, useSelector, useDispatch };
export default StateProvider;
