import { FinalFormField, Fonts } from "yuka";
import { DateTime } from "luxon";
import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useForm, useFormState } from "react-final-form";

import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";

const StyledModalHeader = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

const StyledModalTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ValuationCalculatorModalHeader = ({
  companyName,
  valuation,
  previousValuations,
}) => {
  const { values } = useFormState();
  const { change } = useForm();
  // These will be used for mixpanel tracking.
  const [oldValuationDate, setOldValuationDate] = useState(
    values.valuation_date
  );

  return (
    <StyledModalHeader>
      <StyledModalTitle>
        <span>
          <Fonts.Headline2theme80>Valuation</Fonts.Headline2theme80>{" "}
          <Fonts.Headline2theme50>for</Fonts.Headline2theme50>{" "}
          <Fonts.Headline2theme80>{companyName}</Fonts.Headline2theme80>{" "}
          <Fonts.Headline2theme50>on</Fonts.Headline2theme50>
        </span>
        <FinalFormField
          small
          onClick={() => MixpanelEvents.clickValuationDate(companyName)}
          onChange={(value) => {
            // find the valuation in previousValuation that was created most recently prior to the new date value
            const previousValuation = previousValuations
              .filter((v) => v.valuation_date <= value)
              .sort(
                (a, b) =>
                  DateTime.fromISO(b.valuation_date) -
                  DateTime.fromISO(a.valuation_date)
              )[0];
            if (previousValuation) {
              change("previous_date", previousValuation.valuation_date);
              change("previous_pps", previousValuation.computed_value);
            } else if (
              values.valuation_date &&
              values.previous_date &&
              values.valuation_date < values.previous_date
            ) {
              change("previous_date", undefined);
              change("previous_pps", undefined);
            }
            MixpanelEvents.modifyValuationDate(
              companyName,
              oldValuationDate,
              value
            );
            setOldValuationDate(value);
          }}
          disabled={valuation}
          id="valuation_date"
          name="valuation_date"
          type="date"
          placeholder="MM/DD/YYYY"
          parse={(value) => DateTime.fromJSDate(value).toISODate()}
          width={160}
        />
      </StyledModalTitle>
      {!valuation && (
        <Fonts.Caption2theme50>
          Changing the date might affect the value for certain criteria
        </Fonts.Caption2theme50>
      )}
    </StyledModalHeader>
  );
};

ValuationCalculatorModalHeader.propTypes = {
  valuation: PropTypes.shape({}),
  previousValuations: PropTypes.arrayOf(
    PropTypes.shape({
      valuation_date: PropTypes.string,
    })
  ),
  companyName: PropTypes.string.isRequired,
};

ValuationCalculatorModalHeader.defaultProps = {
  valuation: {},
  previousValuations: [],
};

export default ValuationCalculatorModalHeader;
