import styled from "styled-components";
import React, { useEffect, useMemo } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";

import BidOfferRatioGraphView from "./graphs/BidOfferRatioGraphView";
import HoldingPriceGraphView from "./graphs/HoldingPriceGraphView";
import VolumeTradingGraphView from "./graphs/VolumeTradingGraphView";
import RelativeActivityGraphView from "./graphs/RelativeActivityGraphView";
import TradingPriceGraphView from "./graphs/TradingPriceGraphView";
import CompanyNavigation from "./CompanyNavigation";
import CompanyHeader from "./CompanyHeader";
import CompanyProfile from "./CompanyProfile";
import { CompanyTabs } from "./constants";
import CompanyNews from "./news/CompanyNewsView";

import useFetch from "../api/useFetch";
import { API_ENDPOINTS } from "../api/constants";
import LoadingSpinner from "../utils/LoadingSpinner";
import { pushRecentlyVisitedCompanies } from "../global-header/utils";
import useDocumentTitle from "../utils/useDocumentTitle";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import { ROUTING_PATH } from "../routes/constants";
import useCompanyFetch from "./utils/useCompanyFetch";
import CompanyValuation from "./CompanyValuation";

const StyledCompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  // subtract height of global header
  // height: calc(100% - 88px);
`;

const StyledCompanyViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 16px;
  min-height: 0;
  // subtract height of company header
  // height: calc(100% - 80px);
`;

const StyledCurrentViewContainer = styled.div`
  padding-top: 16px;
  flex-grow: 1;
  overflow: auto;
`;

const CompanyView = () => {
  useDocumentTitle("ZXData: Company Profile");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const tab = searchParams.get("tab");
  const companyId = params.id;

  const companyQuery = useCompanyFetch(companyId);
  const hasProDataAccessQueryInfo = useFetch(
    API_ENDPOINTS.HAS_PRO_DATA_ACCESS(companyId)
  );

  useEffect(() => {
    if (!companyQuery.isLoading && !companyQuery.isError) {
      pushRecentlyVisitedCompanies(companyQuery.cleanedData.data);
      MixpanelEvents.viewCompanyProfile(companyQuery.cleanedData.data.name);
    }
    if (
      !companyQuery.isLoading &&
      companyQuery.isError &&
      companyQuery.error.response.status === 404
    ) {
      navigate(ROUTING_PATH.COMPANIES());
    }
  }, [
    navigate,
    companyQuery.error?.response?.status,
    companyQuery.isLoading,
    companyQuery.isError,
    companyQuery.cleanedData?.data,
    companyQuery.cleanedData?.data?.name,
  ]);

  const currentView = useMemo(() => {
    if (hasProDataAccessQueryInfo.isLoading) {
      return <LoadingSpinner />;
    } else {
      const graphProps = {
        companyId,
        companyName: companyQuery.cleanedData?.data.name,
        timeFrame: hasProDataAccessQueryInfo.cleanedData?.time_frame,
      };

      switch (tab) {
        case CompanyTabs.Valuation:
          return (
            <CompanyValuation
              companyId={companyId}
              companyName={companyQuery.cleanedData?.data?.name}
            />
          );
        case CompanyTabs.CompanyNews:
          return (
            <CompanyNews
              companyId={companyId}
              companyName={companyQuery.cleanedData?.data?.name}
            />
          );
        case CompanyTabs.PriceVolume:
          return (
            <VolumeTradingGraphView
              {...graphProps}
              hasDataAccess={
                hasProDataAccessQueryInfo.cleanedData
                  ?.can_view_price_and_volume_graph
              }
            />
          );
        case CompanyTabs.BidAskPrice:
          return (
            <TradingPriceGraphView
              {...graphProps}
              hasDataAccess={
                hasProDataAccessQueryInfo.cleanedData
                  ?.can_view_bid_ask_price_graph
              }
            />
          );
        case CompanyTabs.ReportedMarks:
          return (
            <HoldingPriceGraphView
              {...graphProps}
              hasDataAccess={
                hasProDataAccessQueryInfo.cleanedData
                  ?.can_view_reported_marks_graph
              }
            />
          );
        case CompanyTabs.BidAskRatio:
          return (
            <BidOfferRatioGraphView
              {...graphProps}
              hasDataAccess={
                hasProDataAccessQueryInfo.cleanedData
                  ?.can_view_bid_ask_ratio_graph
              }
            />
          );
        case CompanyTabs.RelativeActivity:
          return (
            <RelativeActivityGraphView
              {...graphProps}
              hasDataAccess={
                hasProDataAccessQueryInfo.cleanedData
                  ?.can_view_relative_activity_graph
              }
            />
          );
        default:
          return (
            <CompanyProfile
              companyId={companyId}
              graphTimeFrame={hasProDataAccessQueryInfo.cleanedData?.time_frame}
              hasDocumentAccess={
                hasProDataAccessQueryInfo.cleanedData?.can_view_sacra_documents
              }
              canViewPriceAndVolumeGraph={
                hasProDataAccessQueryInfo.cleanedData
                  ?.can_view_price_and_volume_graph
              }
            />
          );
      }
    }
  }, [tab, companyId, companyQuery, hasProDataAccessQueryInfo]);

  return (
    <StyledCompanyContainer>
      <CompanyHeader
        companyId={companyId}
        timeFrame={hasProDataAccessQueryInfo.cleanedData?.time_frame}
      />
      <StyledCompanyViewContainer>
        <CompanyNavigation
          companyId={companyId}
          timeFrame={hasProDataAccessQueryInfo.cleanedData?.time_frame}
        />
        <StyledCurrentViewContainer>{currentView}</StyledCurrentViewContainer>
      </StyledCompanyViewContainer>
    </StyledCompanyContainer>
  );
};

export default CompanyView;
