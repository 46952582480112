import _ from "lodash";
import { API_ENDPOINTS } from "../../api/constants";
import useFetches from "../../api/useFetches";
import { useMemo } from "react";

const INVESTOR_PAGE_SIZE = 10;

/**
 * Fetches any number of entity profiles and simluates paging, based on a list of
 * Funding Round objects.
 *
 * @param fundingRounds
 * @return {Array}
 */
const useEntityProfileFetches = (fundingRounds) => {
  const investors = useMemo(
    () =>
      _.uniq(
        _.flatten(
          fundingRounds.map((fundingRound) => fundingRound.investors || [])
        ).map((investor) => investor[1])
      ),
    [fundingRounds]
  );

  const entityProfileQueries = [];
  for (let i = 0; i < investors.length; i += INVESTOR_PAGE_SIZE) {
    entityProfileQueries.push({
      url: API_ENDPOINTS.HD_ENTITY_PROFILES(),
      queryParams: { id: investors.slice(i, i + INVESTOR_PAGE_SIZE) },
      options: { enabled: fundingRounds.length > 0 },
    });
  }

  return useFetches(entityProfileQueries, {
    flattenResults: true,
  });
};

export default useEntityProfileFetches;
