import styled, { css } from "styled-components";
import { YukaColorPalette, body1, caption2 } from "yuka";

const StyledLegendItem = styled.div`
  display: flex;
  width: fit-content;
  padding: 2px 8px;
  border-radius: 4px;

  ${(props) =>
    !props.keyCompany &&
    css`
      &:hover {
        cursor: pointer;
        background-color: ${YukaColorPalette.surface3};
      }
    `}
`;

const LegendMarker = styled.div`
  background: ${(props) => props.$color};
  width: 3px;
  height: 12px;
  border-radius: 3px;
  margin: auto 12px auto 0;
`;

const StyledPriceDisplay = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledLegendValue = styled.div`
  ${(props) =>
    props.secondaryInfo
      ? css`
          ${caption2}
          margin-bottom: -1px; // gross but necessary because of combining different font sizes
        `
      : css`
          ${body1}
        `}
  color: ${(props) => props.$color};
  display: flex;
  align-items: center;
`;

const StyledLegendIcons = styled.div`
  display: flex;
  margin-left: auto;
  padding-left: 24px;
  min-width: 40px;
  gap: 8px;
`;

const StyledRobustnessScore = styled.div`
  display: flex;
  margin-left: 8px;
`;

export {
  StyledLegendItem,
  LegendMarker,
  StyledPriceDisplay,
  StyledLegendValue,
  StyledLegendIcons,
  StyledRobustnessScore,
};
