import _ from "lodash";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import PropTypes from "prop-types";
import {
  Table,
  DateCell,
  NumberCell,
  HyperLink,
  Modal,
  ModalStyles,
  List,
  ListItem,
  Fonts,
  BadgeStyles,
  FontColors,
  LinkStyles,
} from "yuka";

import {
  expandedMoneyFormat,
  shortMoneyFormat,
} from "../utils/displayFormatUtils";
import addCommasToNum from "../utils/addCommasToNum";
import FundingRoundOrStockSplitCellRenderer from "./FundingRoundOrStockSplitCellRenderer";
import { EVENT_NAME_COLUMN_ID, STOCK_SPLIT } from "./constants";
import { DateTime } from "luxon";
import investorNameSort from "./utils/investorNameSort";
import FundingRoundEventCellRenderer from "./CellRenderers/FundingRoundEventCellRenderer";

const FUNDING_ROUND_MIN_DECIMAL_PLACES = 2;
const FUNDING_ROUND_MAX_DECIMAL_PLACES = 4;
const EMPTY_VALUE_PLACEHOLDER = <Fonts.Body1theme30>--</Fonts.Body1theme30>;

const ENTITY_LOADING = "Loading...";

const fundingRoundTableColumnsNew = (hasWaterfallDistribution) => {
  const columns = [
    {
      id: EVENT_NAME_COLUMN_ID,
      width: 230,
      sticky: true,
      header: "Event",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      badgeMapping: {},
      defaultBadgeStyle: BadgeStyles.MEDIUM_GRAY,
      fundingRoundCellRenderer: FundingRoundEventCellRenderer,
    },
    {
      id: "date",
      width: 100,
      header: "Date",
      accessor: (fundingRound) => fundingRound,
      emptyPlaceholder: EMPTY_VALUE_PLACEHOLDER,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: ({ value: fundingRound, ...props }) => (
        <DateCell value={fundingRound.date} {...props} />
      ),
      dateFormatter: (value) => {
        const formattedDate = DateTime.fromISO(value).toLocaleString({
          month: "short",
          year: "2-digit",
        });
        const [month, year] = formattedDate.split(" ");
        // Inject the apostrophe to the year.
        return `${month} '${year}`;
      },
    },
    {
      id: "pricePerShare",
      width: 140,
      align: "right",
      header: "Price / Share",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: NumberCell,
      formatter: (fundingRound) =>
        fundingRound.price_per_share
          ? expandedMoneyFormat(
              fundingRound.price_per_share,
              FUNDING_ROUND_MIN_DECIMAL_PLACES,
              FUNDING_ROUND_MAX_DECIMAL_PLACES
            )
          : EMPTY_VALUE_PLACEHOLDER,
    },
    {
      id: "amountRaised",
      width: 124,
      align: "right",
      header: "Amount Raised",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: NumberCell,
      formatter: (fundingRound) =>
        fundingRound.amount_raised
          ? shortMoneyFormat(
              fundingRound.amount_raised,
              FUNDING_ROUND_MIN_DECIMAL_PLACES
            )
          : EMPTY_VALUE_PLACEHOLDER,
    },
    {
      id: "postmoneyValuation",
      width: 124,
      align: "right",
      header: "Post-val",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: NumberCell,
      formatter: (fundingRound) =>
        fundingRound.postmoney_valuation
          ? shortMoneyFormat(
              fundingRound.postmoney_valuation,
              FUNDING_ROUND_MIN_DECIMAL_PLACES
            )
          : EMPTY_VALUE_PLACEHOLDER,
    },
    {
      id: "investorList",
      width: 124,
      accessor: (fundingRound) => fundingRound,
      header: "Investor List",
      align: "right",
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: ({
        value: fundingRound,
        openInvestorsModal,
        entityProfiles,
      }) => {
        // Determine if we've loaded all the investor profiles.
        const allInvestorsLoaded =
          fundingRound.investors?.length > 0 &&
          _.some(
            fundingRound.investors,
            (investor) => investor[1] in entityProfiles
          );

        return allInvestorsLoaded ? (
          <HyperLink
            linkStyle={
              fundingRound.parent_funding_round
                ? LinkStyles.INVISIBLE
                : LinkStyles.DEFAULT
            }
            onClick={() => openInvestorsModal(fundingRound)}
          >
            {fundingRound.investors.length} Investor
            {fundingRound.investors.length > 1 ? "s" : ""}
          </HyperLink>
        ) : (
          EMPTY_VALUE_PLACEHOLDER
        );
      },
    },
    {
      id: "authorizedShares",
      width: 156,
      align: "right",
      header: "Shares Authorized",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: NumberCell,
      formatter: (fundingRound) =>
        fundingRound.authorized_shares
          ? addCommasToNum(fundingRound.authorized_shares)
          : EMPTY_VALUE_PLACEHOLDER,
    },
    {
      id: "security",
      width: 188,
      header: "Security",
      align: "right",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: ({ value: fundingRound }) =>
        fundingRound.security || EMPTY_VALUE_PLACEHOLDER,
    },
    {
      id: "liquidationMultiple",
      width: 124,
      align: "right",
      header: "Multiple",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: ({ value: fundingRound }) =>
        fundingRound?.liquidation_display || EMPTY_VALUE_PLACEHOLDER,
    },
  ];
  if (hasWaterfallDistribution) {
    columns.push({
      id: "distributionOrder",
      width: 200,
      align: "right",
      header: "Waterfall Distribution",
      accessor: (fundingRound) => fundingRound,
      cellRenderer: FundingRoundOrStockSplitCellRenderer,
      fundingRoundCellRenderer: ({ value: fundingRound }) =>
        fundingRound?.liquidation_order_display || EMPTY_VALUE_PLACEHOLDER,
    });
  }
  return columns;
};

const StyledList = styled(List)`
  height: unset;
  max-height: 400px;
  margin: -24px -16px;
`;

const EntityName = styled.span`
  ${({ $loading }) => ($loading ? FontColors.theme50 : FontColors.theme80)};
`;

/**
 * A table listing all funding rounds for the given company.
 *
 * @param {object} props
 * @returns {React.Component}
 */
const FundingRoundTable = (props) => {
  const [investorsModalFundingRound, setInvestorsModalFundingRound] = useState(
    {}
  );

  const sortedFundingRounds = useMemo(() => {
    // Sorts the funding rounds by `order` (descending), except for funding rounds with a
    // populated `parent_funding_round` field, which will be inserted directly below their parent,
    // also sorted by descending `order`.
    // Example of outcome:
    // Series C        (order 5)
    // Series B        (order 2)
    //    Series B-2   (order 4)   <- child of Series B
    //    Series B-1   (order 3)   <- child of Series B
    // Series A        (order 1)
    // Funding rounds are received in the props already sorted by order, so we start by just taking
    // all the parent funding rounds.
    const parentFundingRounds = props.fundingRounds.filter(
      (round) =>
        round.apiType === STOCK_SPLIT || round.parent_funding_round === null
    );
    const finalSortedFundingRounds = [];
    // Next we inject the children of each of the parents directly below them.
    parentFundingRounds.forEach((parentRound) => {
      finalSortedFundingRounds.push(parentRound);
      const children = props.fundingRounds.filter(
        (round) => round.parent_funding_round?.[1] === parentRound.apiId
      );
      finalSortedFundingRounds.push(...children);
    });
    return finalSortedFundingRounds;
  }, [props.fundingRounds]);

  const hasWaterfallDistribution = useMemo(() => {
    const hasValues = (fundingRound) => fundingRound.liquidation_order_display;
    return sortedFundingRounds.some(hasValues);
  }, [sortedFundingRounds]);

  const investorList = useMemo(() => {
    if (!_.isEmpty(investorsModalFundingRound)) {
      return investorsModalFundingRound.investors
        .map((investor) => ({
          investor: props.entityProfiles[investor[1]]?.name || ENTITY_LOADING,
        }))
        .sort(investorNameSort);
    }
    return [];
  }, [investorsModalFundingRound, props.entityProfiles]);

  return (
    <>
      <Table
        columns={fundingRoundTableColumnsNew(hasWaterfallDistribution)}
        emptyTablePlaceholder={props.emptyTablePlaceholder}
        data={sortedFundingRounds}
        openInvestorsModal={setInvestorsModalFundingRound}
        entityProfiles={props.entityProfiles}
        disableHeader={props.disableHeader}
      />
      {!_.isEmpty(investorsModalFundingRound) && (
        <Modal
          title={`${investorsModalFundingRound.security} Investors`}
          modalStyle={ModalStyles.SCROLLABLE}
          onClose={() => setInvestorsModalFundingRound(null)}
        >
          <StyledList divider>
            {investorList.map((investor, index) => (
              <ListItem
                key={`investor_${index}`}
                text={
                  <EntityName $loading={investor.investor === ENTITY_LOADING}>
                    {investor.investor}
                  </EntityName>
                }
              />
            ))}
          </StyledList>
        </Modal>
      )}
    </>
  );
};

FundingRoundTable.propTypes = {
  disableHeader: PropTypes.bool,
  entityProfiles: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  emptyTablePlaceholder: PropTypes.element,
  fundingRounds: PropTypes.arrayOf(PropTypes.object).isRequired,
};

FundingRoundTable.defaultProps = {
  emptyTablePlaceholder: null,
  disableHeader: false,
  entityProfiles: {},
};

export default FundingRoundTable;
