import React, { useContext } from "react";
import {
  BuildingIcon,
  CompassIcon,
  GlobalNavigation,
  HomeIcon,
  NavigationCategoryHeader,
  NavigationItem,
  LinkTypes,
  SlidersIcon,
} from "yuka";

import Footer from "./Footer";

import SiteLogo, { SiteLogoDataverse } from "../utils/svgs/SiteLogo";
import { ROUTING_PATH } from "../routes/constants";
import _ from "lodash";
import { AuthContext } from "../auth";
import { DATAVERSE_ENJOYERS } from "./constants";

/**
 * Global navigation bar
 *
 * @returns {React.Element}
 */
const GlobalNav = () => {
  const { user } = useContext(AuthContext);
  const navLinks = _.compact([
    {
      url: ROUTING_PATH.MARKET_ACTIVITY(),
      children: "Market Activity",
      icon: HomeIcon,
      id: "market-activity",
    },
    {
      url: ROUTING_PATH.COMPANIES(),
      children: "Company List",
      icon: BuildingIcon,
      id: "company-list",
    },
    {
      url: ROUTING_PATH.SUPERCHARTS(),
      children: "Superchart",
      icon: SlidersIcon,
      id: "superchart",
    },
    {
      url: ROUTING_PATH.INSIGHTS(),
      children: "Insights",
      icon: CompassIcon,
      id: "insights",
    },
  ]);

  const siteLogo = DATAVERSE_ENJOYERS.includes(user?.email) ? (
    <SiteLogoDataverse />
  ) : (
    <SiteLogo />
  );

  return (
    <GlobalNavigation
      logo={siteLogo}
      url={ROUTING_PATH.COMPANIES()}
      footer={Footer}
    >
      <NavigationCategoryHeader>ZXData</NavigationCategoryHeader>
      {_.map(
        navLinks,
        (link) =>
          !link.blockAccess && (
            <NavigationItem
              id={link.id}
              key={link.url}
              url={link.url}
              linkType={LinkTypes.LOCAL_LINK}
              onClick={link.onClick}
              selected={_.includes(location.pathname, link.url)}
              leadingIcon={link.icon}
            >
              {link.children}
            </NavigationItem>
          )
      )}
    </GlobalNavigation>
  );
};

export default GlobalNav;
