import { DateTime } from "luxon";
import React from "react";
import PropTypes from "prop-types";

import { percentFormat } from "../../utils/displayFormatUtils";
import getPercentFromFraction from "../../utils/getPercentFromFraction";

import { LOADING_TEXT } from "../constants";

/**
 * Tooltip for a company's Last Funding Round in Company List
 *
 * @returns {React.Element}
 */
const LastRoundTooltip = (props) => {
  const lastRoundPrice = props.column.getMainText(props);
  const { value: company, fundingRoundsMap } = props;

  const fundingRound = fundingRoundsMap[company.apiId];

  if (
    fundingRound === LOADING_TEXT ||
    !fundingRound ||
    !fundingRound.price_per_share
  ) {
    return null;
  }

  const lastRoundPriceDiff = percentFormat(
    getPercentFromFraction(
      Math.abs(company.zx_index_value - fundingRound.price_per_share),
      Math.abs(fundingRound.price_per_share),
      0
    ),
    0
  );

  const date = DateTime.fromISO(fundingRound.date).toLocaleString(
    DateTime.DATE_SHORT
  );

  return (
    <React.Fragment>
      <p>
        {lastRoundPrice} is the {fundingRound.event} price as of {date}
      </p>
      {lastRoundPriceDiff && (
        <p>
          The current ZX Index Value is at a {lastRoundPriceDiff}{" "}
          {company.zx_index_value - fundingRound.price_per_share > 0
            ? "premium"
            : "discount"}{" "}
          to last round
        </p>
      )}
    </React.Fragment>
  );
};

LastRoundTooltip.propTypes = {
  column: PropTypes.shape({
    getMainText: PropTypes.func.isRequired,
  }).isRequired,
  value: PropTypes.shape({
    apiId: PropTypes.string,
    zx_index_value: PropTypes.number,
  }).isRequired,
  fundingRoundsMap: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      date: PropTypes.string,
      price_per_share: PropTypes.number,
      security: PropTypes.string,
    }),
  }).isRequired,
};

export default LastRoundTooltip;
