import _ from "lodash";
import { DateTime } from "luxon";

import {
  KEY_COMPANY_SERIES_TYPE,
  ZX_COMPANY_EXCHANGE,
  ZX_MARKET_INDEX,
  ZX_COMPARISON_SERIES_TYPE,
  ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE,
  PUBLIC_COMPARISON_SERIES_TYPE,
  SUPERCHART_API_DATA_FIELDS,
  TWO_YEAR_KEY,
  COMPARISON_COLORS,
  INDICATOR_TYPES,
  PRICE_DATA_START_DATES,
  API_TYPE_TO_SERIES_TYPE,
} from "./constants";

/**
 * Combines the public security quotes and pre-IPO orderflow data into a single data set.
 *
 * @param {Array} postIPOData
 * @param {Array} preIPOData
 * @returns {object}
 */
const combinePublicAndPreIPOData = (postIPOData, preIPOData) => {
  // Masquerade private company orderflow data as public security quotes.
  const mappedPreIPOData = _.map(
    preIPOData,
    ({ zx_index_price: closePrice, report_period: date }) => ({
      close_price: closePrice,
      date,
      is_market_closed_day: false,
    })
  );

  let finalPreIPODataWithWhitespace = [];
  // Populate the data with "whitespace" data points to fill in the gaps between the pre-IPO
  // data points. We take orderflow reports on a weekly basis, but public security quotes are
  // collected daily. We want lightweight charts to keep our x-axis scale consistent, so we
  // make recently IPO'd companies' data look like it's always been public.
  _.forEach(mappedPreIPOData, (dataPoint, index) => {
    const nextDataPoint = mappedPreIPOData[index + 1];
    finalPreIPODataWithWhitespace.push(dataPoint);
    let nextDate = DateTime.fromISO(dataPoint.date).minus({ days: 1 });

    while (nextDataPoint && nextDate > DateTime.fromISO(nextDataPoint.date)) {
      finalPreIPODataWithWhitespace.push({
        date: nextDate.toISODate(),
      });
      nextDate = nextDate.minus({ days: 1 });
    }
  });

  const combined = _.sortedUniqBy(
    _.orderBy(
      [...postIPOData, ...finalPreIPODataWithWhitespace],
      ["date"],
      ["desc"]
    ),
    "date"
  );

  return _.isEmpty(combined) ? {} : combined;
};

/**
 * Makes a timeseries array that is used to draw a line on the superchart.
 *
 * This function accepts API price data and makes an array of `dataPoint` objects:
 * { time, absolute, percent }; `time` is an ISO datestring, `absolute` is the $ value at `time`,
 * and `percent` is the % change of absolute from the first/earliest `absolute` in the array.
 *
 * @param {Array<object>} data - an array of raw price data
 * @param {string} dateKey - the name of the date field in the data elements
 * @param {string} valueKey - the name of the price value field in the data elements
 * @param {Array<string>} additionalDataKeys - names of fields that we also want in the timeseries
 * @param {object} config - optional config object
 * @returns {Array<object>} [dataPoint] - a dataPoint is a { time, absolute, percent } object
 */
const makePriceDataTimeSeries = (
  data,
  dateKey,
  valueKey,
  additionalDataKeys = [],
  { castDateTimeToDate } = { castDateTimeToDate: false }
) => {
  // the last data point is the earliest in time; drop empty data until we have a real start point
  const cleanData = _.dropRightWhile(data, (datum) => !datum?.[valueKey]);

  const startValue = parseFloat(_.last(cleanData)?.[valueKey]);

  return _.reverse(
    _.map(cleanData, (datum, i) => {
      // We preserve whitespace points.
      const isWhiteSpace = _.isEqual(Object.keys(datum), [dateKey]);
      const time = castDateTimeToDate
        ? DateTime.fromISO(datum[dateKey]).toISODate()
        : datum[dateKey];

      return isWhiteSpace
        ? { time }
        : {
            time,
            absolute: parseFloat(datum[valueKey]),
            percent:
              i === cleanData.length - 1
                ? 0
                : ((parseFloat(datum[valueKey]) - startValue) / startValue) *
                  100,
            // for each additional data key, add a { key: datum[key] } property to the timeseries
            ..._.zipObject(
              additionalDataKeys,
              _.map(additionalDataKeys, (key) => datum[key])
            ),
          };
    })
  );
};

/**
 * Creates slices of the provided timeseries data corresponding to the length of data needed
 * for each selectable date range on the superchart (3 months, 6 months, 1 year, 2 years).
 *
 * `data` is 2 years of price data in descending order such that the 0th element is from today,
 * that way the function will return an object of this form:
 *
 * {
 *   3M: data[0:i]
 *   6M: data[0:j]
 *   1Y: data[0:k]
 *   2Y: data
 * } where `i` is the index that takes us to the data point from 3 months ago, j is 6mo, etc.
 *
 * @param {Array<object>} data - an array of raw price data
 * @param {string} dateKey - the name of the date field in the data elements
 * @returns {object} dateDividedData - splices of `data` as described above
 */
const getDateDividedData = (data, dateKey) => {
  // find indices of the rawPriceData array that correspond to the first data points in
  // the chartable data for last 3 months, 6 months, and 1 year
  const cutOffIndices = _.mapValues(PRICE_DATA_START_DATES, (startDate) => {
    const index = _.findIndex(
      data,
      (datum) => DateTime.fromISO(datum[dateKey]) < startDate
    );
    // findIndex returns -1 when the item isn't found; in these cases we want all the data
    return index === -1 ? data.length : index;
  });

  return {
    ..._.mapValues(cutOffIndices, (index) => data.slice(0, index)),
    [TWO_YEAR_KEY]: data,
  };
};

/**
 * This function creates all the data needed to chart a single company/security for any of the 4
 * date ranges.
 *
 * @param {Array<object>} rawPriceData - an array of raw price data
 * @param {string} seriesType - the type of series being processed
 * @returns {Array<object>} superchartData - maps superchart date ranges to price data timeseries
 */
const makeSuperchartData = (rawPriceData, seriesType) => {
  const { TIME_FIELD, PRICE_DATA_FIELD, ADDITIONAL_DATA_FIELDS, config } =
    SUPERCHART_API_DATA_FIELDS[seriesType];
  const dateDividedData = getDateDividedData(rawPriceData, TIME_FIELD);

  return _.mapValues(dateDividedData, (dataSeries) =>
    makePriceDataTimeSeries(
      dataSeries,
      TIME_FIELD,
      PRICE_DATA_FIELD,
      ADDITIONAL_DATA_FIELDS,
      config
    )
  );
};

/**
 * This function creates all the indicator timeseries data for the key company in the form:
 *
 * {
 *   [indicator1]: {
 *     3M: [dataPoint],
 *     6M: ...,
 *     ...
 *   },
 *   [indicator2]: {
 *     ...
 *   },
 *   ...
 * }
 *
 * @param {Array<object>} rawPriceData - an array of raw price data
 * @param {string} seriesType - the type of series being processed
 * @returns {Array<object>} superchartData - maps superchart date ranges to price data timeseries
 */
const makeAllIndicatorData = (rawPriceData) => {
  const { TIME_FIELD } = SUPERCHART_API_DATA_FIELDS[KEY_COMPANY_SERIES_TYPE];
  const dateDividedData = getDateDividedData(rawPriceData, TIME_FIELD);
  return _.mapValues(INDICATOR_TYPES, ({ apiDataKey }) =>
    _.mapValues(dateDividedData, (dataSeries) =>
      makePriceDataTimeSeries(dataSeries, TIME_FIELD, apiDataKey)
    )
  );
};

const makeRandomColorPicker = (colorSet) => {
  let remainingColors = _.cloneDeep(colorSet);

  return () => {
    const index = _.random(0, remainingColors.length - 1);
    const ret = _.pullAt(remainingColors, [index])[0];

    if (remainingColors.length === 0) {
      remainingColors = _.cloneDeep(colorSet);
    }

    return ret;
  };
};

const pickRandomColor = makeRandomColorPicker(COMPARISON_COLORS);

const mapComparisonExchangeToType = (exchange) => {
  switch (exchange) {
    case ZX_COMPANY_EXCHANGE:
      return ZX_COMPARISON_SERIES_TYPE;
    case ZX_MARKET_INDEX:
      return ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE;
    default:
      return PUBLIC_COMPARISON_SERIES_TYPE;
  }
};

const _mapGenericSecurityToRelatedObject = (security) =>
  security.public_security ||
  security.private_company ||
  security.private_market_index;

const mapGenericSecurityToSeriesType = (security) =>
  API_TYPE_TO_SERIES_TYPE[_mapGenericSecurityToRelatedObject(security)[0]];

const mapGenericSecurityToId = (security) =>
  _mapGenericSecurityToRelatedObject(security)[1];

export {
  combinePublicAndPreIPOData,
  makeSuperchartData,
  makeAllIndicatorData,
  pickRandomColor,
  mapComparisonExchangeToType,
  mapGenericSecurityToSeriesType,
  mapGenericSecurityToId,
};
