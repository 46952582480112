import {
  COMPONENT_TYPE_FIELD_NAME_PREFIX,
  CURRENT_VALUE_FORM_FIELD_NAME_PREFIX,
  INITIAL_VALUE_FORM_FIELD_NAME_PREFIX,
  NAME_FORM_FIELD_NAME_PREFIX,
  WEIGHT_FORM_FIELD_NAME_PREFIX,
} from "../ValuationCalculator/constants";

// Removes periods (.) and replaces them with underscores (_), since `.` denotes a
// nested field in our forms.
const replaceDotWithUnderscore = (id) => String(id).split(".").join("_");

const nameFieldName = (id) =>
  `${NAME_FORM_FIELD_NAME_PREFIX}${replaceDotWithUnderscore(id)}`;
const weightFieldName = (id) =>
  `${WEIGHT_FORM_FIELD_NAME_PREFIX}${replaceDotWithUnderscore(id)}`;
const componentTypeFieldName = (id) =>
  `${COMPONENT_TYPE_FIELD_NAME_PREFIX}${replaceDotWithUnderscore(id)}`;
const currentValueFieldName = (id) =>
  `${CURRENT_VALUE_FORM_FIELD_NAME_PREFIX}${replaceDotWithUnderscore(id)}`;
const initialValueFieldName = (id) =>
  `${INITIAL_VALUE_FORM_FIELD_NAME_PREFIX}${replaceDotWithUnderscore(id)}`;

export {
  replaceDotWithUnderscore,
  nameFieldName,
  weightFieldName,
  componentTypeFieldName,
  currentValueFieldName,
  initialValueFieldName,
};
