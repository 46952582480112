import PropTypes from "prop-types";
import styled from "styled-components";
import { FontColors } from "yuka";

const StyledLegendItemContainer = styled.div`
  display: flex;
  gap: 4px;
  ${FontColors.theme50}
`;

const StyledLegendValueText = styled.span`
  ${FontColors.theme100}
`;

const LegendItem = ({ text, value }) => (
  <StyledLegendItemContainer>
    {text}
    {value && <StyledLegendValueText>{value}</StyledLegendValueText>}
  </StyledLegendItemContainer>
);

LegendItem.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
};

LegendItem.defaultProps = {
  text: "",
  value: "",
};

export default LegendItem;
