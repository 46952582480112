import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { ColorPalette, EyeIcon, EyeOffIcon, XCloseIcon } from "yuka";

import { EXCHANGE_DISPLAY_NAMES, ZX_COMPANY_EXCHANGE } from "../constants";

import useChartReferencePoint from "./useChartReferencePoint";
import ToggleableIcon from "./ToggleableIcon";
import {
  StyledLegendItem,
  LegendMarker,
  StyledPriceDisplay,
  StyledLegendValue,
  StyledRobustnessScore,
  StyledLegendIcons,
} from "./StyledComponents";

import { percentFormat, shortMoneyFormat } from "/src/utils/displayFormatUtils";
import roundDecimal from "/src/utils/roundDecimal";

/**
 * An element of SuperchartLegend; displays relevant data for a comparison on the superchart.
 */
const ComparisonLegendItem = (props) => {
  const [hovered, setHovered] = useState(false);
  const [compVisible, setCompVisible] = useState(true);
  const { name, color, exchange, data } = props.chartElement;
  const referencePoint = useChartReferencePoint(props.hoverDate, data);

  const formatExchange = useCallback(
    (exchange) => {
      return EXCHANGE_DISPLAY_NAMES[exchange] || exchange;
    },
    [exchange]
  );

  const formatPrice = useCallback(
    (data) => {
      if (!data.length) return "";

      const changeAbsolute = roundDecimal(
        referencePoint.absolute - data[0].absolute
      );
      const changePercent = roundDecimal(referencePoint.percent);

      return (
        <StyledPriceDisplay>
          <span>{shortMoneyFormat(referencePoint.absolute)}</span>
          <span>
            {changeAbsolute >= 0 ? "+" : "-"}
            {shortMoneyFormat(Math.abs(changeAbsolute))}
          </span>
          <span>
            ({changePercent > 0 ? "+" : ""}
            {percentFormat(changePercent)})
          </span>
        </StyledPriceDisplay>
      );
    },
    [referencePoint, data]
  );

  const formatRobustness = useCallback(() => {
    return `${referencePoint?.robustness || "--"}/10`;
  }, [referencePoint]);

  const toggleVisibility = useCallback(() => {
    props.setVisibility(!compVisible);
    setCompVisible(!compVisible);
  }, [compVisible]);

  return (
    <StyledLegendItem
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <LegendMarker $color={color} />
      <StyledLegendValue $color={ColorPalette.white50}>
        {formatExchange(exchange)}:&nbsp;
      </StyledLegendValue>
      <StyledLegendValue $color={ColorPalette.white80}>
        {name}
      </StyledLegendValue>
      &nbsp;
      <StyledLegendValue $color={color}>{formatPrice(data)}</StyledLegendValue>
      {exchange === ZX_COMPANY_EXCHANGE && (
        <StyledRobustnessScore>
          <StyledLegendValue $color={ColorPalette.white50}>
            with Robustness Score&nbsp;
          </StyledLegendValue>
          <StyledLegendValue $color={ColorPalette.white80}>
            {formatRobustness(data)}
          </StyledLegendValue>
        </StyledRobustnessScore>
      )}
      <StyledLegendIcons>
        {hovered && (
          <ToggleableIcon
            icon={compVisible ? EyeIcon : EyeOffIcon}
            onClick={toggleVisibility}
          />
        )}
        {hovered && (
          <ToggleableIcon icon={XCloseIcon} onClick={props.removeElement} />
        )}
      </StyledLegendIcons>
    </StyledLegendItem>
  );
};

ComparisonLegendItem.propTypes = {
  chartElement: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  setVisibility: PropTypes.func.isRequired,
  removeElement: PropTypes.func.isRequired,
  hoverDate: PropTypes.string,
};

ComparisonLegendItem.defaultProps = {
  hoverDate: "",
};

export default ComparisonLegendItem;
