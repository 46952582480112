import PropTypes from "prop-types";
import styled from "styled-components";
import { FontColors, StyledBody1 } from "yuka";

const HalfOpacityBody1 = styled(StyledBody1)`
  ${FontColors.theme30};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UnderlyingFundCell = ({ value }) => {
  if (!value)
    return <HalfOpacityBody1>No fund name available</HalfOpacityBody1>;
  return value;
};

UnderlyingFundCell.propTypes = {
  value: PropTypes.string,
};

UnderlyingFundCell.defaultProps = {
  value: null,
};

export default UnderlyingFundCell;
