import PropTypes from "prop-types";
import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  FloatingNavigation,
  NavigationItem,
  GridIcon,
  BarChartIcon,
  NavigationCategoryHeader,
  DownloadIcon,
  CalculatorIcon,
} from "yuka";

import AxiosInstance from "../api/AxiosInstance";
import {
  API_ENDPOINTS,
  CUSTOM_VALUATION_CALCULATOR_FEATURE_NAME,
} from "../api/constants";
import LoadingSpinner from "../utils/LoadingSpinner";
import useFetch from "../api/useFetch";
import { useDispatch, ACTIONS } from "../routes/StateProvider";
import {
  ORDER_FLOW_TIME_FRAME_MONTHLY,
  ORDER_FLOW_TIME_FRAME_WEEKLY,
} from "../utils/constants";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

import { CompanyTabs } from "./constants";
import DisclosureModal from "./DisclosureModal";
import useCompanyFetch from "./utils/useCompanyFetch";
import useHasFeatureAccess from "./hooks/useHasFeatureAccess";

const StyledNavigationContainer = styled.div`
  margin-top: 16px;
`;

const CompanyNavigation = (props) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab") || CompanyTabs.CompanyProfile;

  const [customValuationCalculatorFeatureEnabled, featureAccessLoading] =
    useHasFeatureAccess(CUSTOM_VALUATION_CALCULATOR_FEATURE_NAME);
  const company = useCompanyFetch(props.companyId);
  const canDownloadZXDataReportQueryInfo = useFetch(
    API_ENDPOINTS.CAN_DOWNLOAD_ZX_DATA_REPORT(props.companyId)
  );
  const hasProDataAccessQueryInfo = useFetch(
    API_ENDPOINTS.HAS_PRO_DATA_ACCESS(props.companyId)
  );

  const [isDownloading, setIsDownloading] = useState(false);

  const switchTab = (tab) => {
    setSearchParams({ tab });
  };

  const clickDownloadZXData = () => {
    setIsDownloading(true);
    MixpanelEvents.downloadXlsSpreadsheet();
    AxiosInstance.get(API_ENDPOINTS.DOWNLOAD_ZXDATA(props.companyId), {
      params: { time_frame: props.timeFrame },
      responseType: "blob",
    })
      .then(() => {
        dispatch({
          type: ACTIONS.addToast,
          message:
            "Your ZXData Report is being generated, please check your email in a couple of minutes for receipt of the report.",
        });
      })
      .catch(() => {
        dispatch({
          type: ACTIONS.addToast,
          message:
            "Failed to download ZXData Report. Please try again or contact support.",
        });
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const tabs = useMemo(() => {
    let navigationTabs = [
      { tab: CompanyTabs.CompanyProfile, leadingIcon: GridIcon },
    ];
    if (customValuationCalculatorFeatureEnabled) {
      navigationTabs = navigationTabs.concat([
        { tab: CompanyTabs.Valuation, leadingIcon: CalculatorIcon },
      ]);
    }
    navigationTabs = navigationTabs.concat([
      { tab: CompanyTabs.CompanyNews, leadingIcon: GridIcon },
    ]);
    if (company.cleanedData?.data.show_order_flow_data) {
      if (
        hasProDataAccessQueryInfo.cleanedData?.can_view_price_and_volume_graph
      ) {
        navigationTabs = navigationTabs.concat([
          { tab: CompanyTabs.PriceVolume, leadingIcon: BarChartIcon },
        ]);
      }
      if (hasProDataAccessQueryInfo.cleanedData?.can_view_bid_ask_price_graph) {
        navigationTabs = navigationTabs.concat([
          { tab: CompanyTabs.BidAskPrice, leadingIcon: BarChartIcon },
        ]);
      }
    }
    if (
      company.cleanedData?.data.show_reported_marks_data &&
      hasProDataAccessQueryInfo.cleanedData?.can_view_reported_marks_graph
    ) {
      navigationTabs = navigationTabs.concat([
        { tab: CompanyTabs.ReportedMarks, leadingIcon: BarChartIcon },
      ]);
    }
    if (company.cleanedData?.data.show_order_flow_data) {
      if (hasProDataAccessQueryInfo.cleanedData?.can_view_bid_ask_ratio_graph) {
        navigationTabs = navigationTabs.concat([
          { tab: CompanyTabs.BidAskRatio, leadingIcon: BarChartIcon },
        ]);
      }
      if (
        hasProDataAccessQueryInfo.cleanedData?.can_view_relative_activity_graph
      ) {
        navigationTabs = navigationTabs.concat([
          { tab: CompanyTabs.RelativeActivity, leadingIcon: BarChartIcon },
        ]);
      }
    }
    return navigationTabs;
  }, [
    company,
    hasProDataAccessQueryInfo,
    customValuationCalculatorFeatureEnabled,
  ]);

  useEffect(() => {
    if (
      hasProDataAccessQueryInfo.isSuccess &&
      !featureAccessLoading &&
      tabs.find(({ tab }) => tab === selectedTab) === undefined
    ) {
      switchTab(CompanyTabs.CompanyProfile);
    }
  }, [
    featureAccessLoading,
    selectedTab,
    hasProDataAccessQueryInfo.isSuccess,
    tabs,
  ]);

  return (
    <StyledNavigationContainer>
      <FloatingNavigation>
        {tabs.map(({ tab, leadingIcon }) => (
          <NavigationItem
            key={tab}
            onClick={() => {
              switchTab(tab);
            }}
            selected={selectedTab === tab}
            leadingIcon={leadingIcon}
          >
            {tab}
          </NavigationItem>
        ))}
        {!canDownloadZXDataReportQueryInfo.isLoading &&
          canDownloadZXDataReportQueryInfo.cleanedData && (
            <>
              <NavigationCategoryHeader>Downloads</NavigationCategoryHeader>
              <NavigationItem
                key="download"
                onClick={clickDownloadZXData}
                leadingIcon={DownloadIcon}
              >
                ZXData (XLS)
              </NavigationItem>
            </>
          )}
        <DisclosureModal />
        {isDownloading && <LoadingSpinner />}
      </FloatingNavigation>
    </StyledNavigationContainer>
  );
};

CompanyNavigation.propTypes = {
  companyId: PropTypes.string.isRequired,
  timeFrame: PropTypes.oneOf([
    ORDER_FLOW_TIME_FRAME_MONTHLY,
    ORDER_FLOW_TIME_FRAME_WEEKLY,
  ]),
};

CompanyNavigation.defaultProps = {
  timeFrame: ORDER_FLOW_TIME_FRAME_MONTHLY,
};

export default CompanyNavigation;
