import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { HyperLink, LinkStyles, LinkTypes, PageSection } from "yuka";

import { ROUTING_PATH } from "../routes/constants";

import { StyledContainer, StyledFooter } from "./StyledComponents";
import SiteLogo from "../utils/svgs/SiteLogo";

const StyledLogoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
`;

const StyledWrapper = styled(PageSection)`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
`;

const LoginBase = (props) => (
  <StyledWrapper>
    <PageSection>
      <StyledContainer>
        <StyledLogoContainer>
          <SiteLogo size={28} />
        </StyledLogoContainer>
        <div>{props.children}</div>
        <StyledFooter>
          <span>&copy; Zanbato, Inc. 2023</span>
          <span>
            <HyperLink
              linkStyle="SUPPORT"
              linkType={LinkTypes.EXTERNAL_LINK}
              url={ROUTING_PATH.AGREEMENT("tou")}
            >
              Terms of Use
            </HyperLink>
            &nbsp;|&nbsp;
            <HyperLink
              linkStyle={LinkStyles.SUPPORT}
              linkType={LinkTypes.EXTERNAL_LINK}
              url={ROUTING_PATH.AGREEMENT("privacy")}
            >
              Privacy Policy
            </HyperLink>
          </span>
        </StyledFooter>
      </StyledContainer>
    </PageSection>
  </StyledWrapper>
);

LoginBase.propTypes = {
  children: PropTypes.node,
};

LoginBase.defaultProps = {
  children: null,
};

export default LoginBase;
