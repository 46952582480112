import React from "react";

import { InputRow } from "yuka";

import { FinalFormField } from "../hdYuka";

/**
 * Fields for setting a new password
 *
 * @returns {React.Element} SetPasswordFields
 */
const SetPasswordFields = () => {
  const required = (value) => (value ? undefined : "Required");
  return (
    <React.Fragment>
      <InputRow>
        <FinalFormField
          id="password"
          type="password"
          name="password"
          placeholder="New password"
          validate={required}
        />
      </InputRow>
      <InputRow>
        <FinalFormField
          id="confirm-password"
          type="password"
          name="confirm_password"
          placeholder="Confirm your new password"
          validate={required}
          displayIndicator={false}
        />
      </InputRow>
    </React.Fragment>
  );
};

export default SetPasswordFields;
