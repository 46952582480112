import styled from "styled-components";
import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import useFetch from "../../api/useFetch";
import useWrite from "../../api/useWrite";

import PropTypes from "prop-types";
import { Button, ButtonStyles, Card, InputRow } from "yuka";
import { FinalFormField } from "../../hdYuka";
import { API_ENDPOINTS } from "../../api/constants";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { QRCodeSVG } from "qrcode.react";
import { AuthContext } from "../../auth";
import { StyledReverseRow } from "../StyledComponents";

const StyledQRCode = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
`;

const StyledQRCodeSVG = styled(QRCodeSVG)`
  border: 8px solid white;
  border-radius: 8px;
`;

const TokenRegistrationStep = ({ goToPrevious }) => {
  const { setUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const otpFormRef = useRef();
  const validate = (value) => (value ? undefined : "Required");

  const tokenQueryInfo = useFetch(API_ENDPOINTS.TOKEN());
  const enable2faQuery = useWrite(API_ENDPOINTS.TOKEN(), false, {
    message:
      "Congratulations, you've successfully enabled two-factor authentication.",
  });

  if (tokenQueryInfo.isLoading) {
    return <LoadingSpinner />;
  }

  const token = tokenQueryInfo.cleanedData;

  const onSubmit = (values) => {
    enable2faQuery.mutate(values, {
      onSuccess: () => {
        setUserData();
        navigate(0);
      },
    });
  };

  return (
    <Card
      title="Enable Two-Factor Authentication"
      tagline="To start using a token generator, please use your smartphone to scan the QR code below. For example, use Google Authenticator."
    >
      {token && (
        <StyledQRCode>
          <StyledQRCodeSVG
            value={token.provision_url}
            bgColor="#ffffff"
            fgColor="#1b1b1b"
            size="160"
          />
        </StyledQRCode>
      )}
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, dirty, valid }) => (
          <form onSubmit={handleSubmit}>
            {submitting && <LoadingSpinner />}
            <InputRow>
              <FinalFormField
                label="Please enter the tokens generated by your token generator"
                name="otp_token"
                placeholder="Token"
                type="text"
                validate={[validate]}
                ref={otpFormRef}
              />
            </InputRow>
            <StyledReverseRow>
              <Button onClick={goToPrevious}>Back</Button>
              <Button
                buttonStyle={ButtonStyles.CTA}
                disabled={submitting || !dirty || !valid}
                type="submit"
              >
                Submit
              </Button>
            </StyledReverseRow>
          </form>
        )}
      </Form>
    </Card>
  );
};

TokenRegistrationStep.propTypes = {
  goToPrevious: PropTypes.func.isRequired,
};

export default TokenRegistrationStep;
