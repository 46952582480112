import { ColorPalette, DateCell, NumberCell } from "yuka";
import UnderlyingFundCell from "./UnderlyingFundCell";
import { shortMoneyFormat } from "/src/utils/displayFormatUtils";
import { DateTime } from "luxon";

const MAX_PADDING_FACTOR = 1.05;
const GRIDLINE_STYLE = {
  stroke: ColorPalette.faintWhite,
  strokeDasharray: "1, 5",
};
const AXIS_STYLE = {
  line: { stroke: "#5A5A5A", strokeWidth: "1px" },
  text: { stroke: "none" },
};

// custom colors, should be added to yuka later
const GRAPH_COLORS = {
  PURPLE_100: "#BA75FF",
  PURPLE_200: "#849FFF",
  PINK_100: "#FF50F8",
  PINK_200: "#FE7071",
  PINK_300: "#FF9688",
  TEAL: "#75FFF7",
  BLUE: "#0071F6",
  MAGENTA: "#E063EB",
  DARK_GREEN: "#006F3F",
  DARK_RED: "#B21818",
  LIGHT_BLUE: "#0B98DE",

  TRADING_GREEN: ColorPalette.buy,
  GREEN_400: "#00AC61",
  GREEN_300: "#006238",
  TRADING_RED: ColorPalette.sell,
  RED_400: "#B24545",
  RED_300: "#803131",
  BLUE_500: "#00E6F6",
};

const TIME_FRAME_MONTHLY = "TIME_FRAME_MONTHLY";
const TIME_FRAME_WEEKLY = "TIME_FRAME_WEEKLY";

const HALF_OPACITY = 0.5;
const FULL_OPACITY = 1;

const NO_DATA_GRAPH_CARD_HEIGHT = 369;
const CARD_HEIGHT = 412;

const REPORTED_MARKS_TABLE_COLUMNS = [
  {
    id: "underlyingFund",
    accessor: "name",
    header: "Underlying Fund",
    width: 27,
    sortable: true,
    cellRenderer: UnderlyingFundCell,
  },
  {
    id: "pricePerShare",
    accessor: "price_per_share",
    header: "Price / Share",
    cellRenderer: NumberCell,
    formatter: (value) => shortMoneyFormat(value, 2),
    width: 18,
    sortable: true,
  },
  {
    id: "security",
    accessor: "security",
    header: "Security",
    width: 18,
    sortable: true,
  },
  {
    id: "notionalValue",
    accessor: "total_value",
    header: "Notional Value",
    cellRenderer: NumberCell,
    formatter: (value) => shortMoneyFormat(value, 1),
    width: 18,
    sortable: true,
  },
  {
    id: "date",
    accessor: "date",
    header: "Date",
    dateFormatter: (value) =>
      DateTime.fromISO(value).toLocaleString({
        month: "short",
        year: "2-digit",
      }),
    cellRenderer: DateCell,
    width: 19,
    sortable: true,
  },
];

export {
  AXIS_STYLE,
  CARD_HEIGHT,
  FULL_OPACITY,
  GRAPH_COLORS,
  GRIDLINE_STYLE,
  HALF_OPACITY,
  MAX_PADDING_FACTOR,
  NO_DATA_GRAPH_CARD_HEIGHT,
  REPORTED_MARKS_TABLE_COLUMNS,
  TIME_FRAME_MONTHLY,
  TIME_FRAME_WEEKLY,
};
