import styled from "styled-components";
import { Button, BuildingIcon, PlusIcon, MinusIcon } from "yuka";
import PropTypes from "prop-types";

import SuperchartHeaderValue from "./SuperchartHeaderValue";

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const StyledHeaderValueGroup = styled.div`
  display: flex;
  gap: 50px;
`;

const StyledButtonGroup = styled.div`
  display: flex;
`;

const SuperchartHeader = (props) => (
  <StyledHeader>
    <StyledHeaderValueGroup>
      <SuperchartHeaderValue
        name="Current ZX Index Value"
        value={props.zxIndexValue}
        unit="USD"
      />
      <SuperchartHeaderValue
        name="Robustness Score"
        value={props.robustnessScore}
        unit="/10"
      />
    </StyledHeaderValueGroup>
    <StyledButtonGroup>
      <Button
        disabled={!props.watchlistAction}
        onClick={props.watchlistAction}
        leadingIcon={props.isWatchlisted ? MinusIcon : PlusIcon}
      >
        {props.isWatchlisted ? "Remove from" : "Add to"} Watchlist
      </Button>
      <Button
        disabled={!props.profileAction}
        onClick={props.profileAction}
        leadingIcon={BuildingIcon}
      >
        Visit Profile
      </Button>
    </StyledButtonGroup>
  </StyledHeader>
);

SuperchartHeader.propTypes = {
  zxIndexValue: PropTypes.string,
  robustnessScore: PropTypes.number,
  isWatchlisted: PropTypes.bool.isRequired,
  watchlistAction: PropTypes.func,
  profileAction: PropTypes.func,
};

SuperchartHeader.defaultProps = {
  profileAction: null,
  watchlistAction: null,
};

export default SuperchartHeader;
