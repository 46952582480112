import styled from "styled-components";
import { FontColors, List, body1 } from "yuka";

const StyledList = styled(List)`
  max-height: 350px;

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const StyledPlaceholderTextContainer = styled.div`
  ${body1}
  ${FontColors.theme50}
  margin-top: 28px;
  margin-bottom: 28px;
  text-align: center;
`;

export { StyledList, StyledPlaceholderTextContainer };
