import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { ColorPalette, EyeIcon, EyeOffIcon, XCloseIcon } from "yuka";

import useChartReferencePoint from "./useChartReferencePoint";
import ToggleableIcon from "./ToggleableIcon";
import {
  StyledLegendItem,
  LegendMarker,
  StyledPriceDisplay,
  StyledLegendValue,
  StyledLegendIcons,
} from "./StyledComponents";

import { shortMoneyFormat, percentFormat } from "/src/utils/displayFormatUtils";
import ComparisonLegendItem from "./ComparisonLegendItem";

/**
 * An element of SuperchartLegend; displays relevant data for an indicator on the superchart.
 */
const IndicatorLegendItem = (props) => {
  const [hovered, setHovered] = useState(false);
  const [lineVisible, setLineVisible] = useState(true);
  const { name, label, data, color } = props.chartElement;
  const referencePoint = useChartReferencePoint(props.hoverDate, data);

  const formatPrice = useCallback(
    (data) => {
      if (!data.length) return "";

      const changeAbsolute = (
        referencePoint.absolute - data[0].absolute
      ).toFixed(2);
      const changePercent = referencePoint.percent.toFixed(2);
      return (
        <StyledPriceDisplay>
          <span>{shortMoneyFormat(referencePoint.absolute)}</span>
          <span>
            {changeAbsolute >= 0 ? "+" : "-"}
            {shortMoneyFormat(Math.abs(changeAbsolute))}
          </span>
          <span>
            ({changePercent > 0 ? "+" : ""}
            {percentFormat(changePercent)})
          </span>
        </StyledPriceDisplay>
      );
    },
    [referencePoint, data]
  );

  const toggleVisibility = useCallback(() => {
    props.setVisibility(!lineVisible);
    setLineVisible(!lineVisible);
  }, [lineVisible]);

  return (
    <StyledLegendItem
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <LegendMarker $color={"transparent"} />
      <StyledLegendValue $color={ColorPalette.white80}>
        {name}
      </StyledLegendValue>
      &nbsp;
      <StyledLegendValue $color={ColorPalette.white30} secondaryInfo>
        {label || ""}
      </StyledLegendValue>
      &nbsp;
      <StyledLegendValue $color={color}>{formatPrice(data)}</StyledLegendValue>
      <StyledLegendIcons>
        {hovered && (
          <ToggleableIcon
            icon={lineVisible ? EyeIcon : EyeOffIcon}
            onClick={toggleVisibility}
          />
        )}
        {hovered && (
          <ToggleableIcon icon={XCloseIcon} onClick={props.removeElement} />
        )}
      </StyledLegendIcons>
    </StyledLegendItem>
  );
};

IndicatorLegendItem.propTypes = {
  chartElement: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  setVisibility: PropTypes.func.isRequired,
  removeElement: PropTypes.func.isRequired,
  hoverDate: PropTypes.string,
  percentDisplay: PropTypes.bool.isRequired,
};

ComparisonLegendItem.defaultProps = {
  hoverDate: "",
};

export default IndicatorLegendItem;
