import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";

import { INDICATOR_COLORS, LEGEND_Z_INDEX, SERIES_TYPES } from "../constants";

import KeyCompanyLegendItem from "./KeyCompanyLegendItem";
import IndicatorLegendItem from "./IndicatorLegendItem";
import ComparisonLegendItem from "./ComparisonLegendItem";

const StyledLegend = styled.div`
  position: absolute;
  z-index: ${LEGEND_Z_INDEX};
  left: 16px;
  top: 0;
`;

/**
 * The legend for superchart. Creates a legend item for the key company as well as each comparison
 * that is currently charted. Mostly just forwards props through to the correct type of LegendItem.
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const Legend = (props) => (
  <StyledLegend>
    <KeyCompanyLegendItem
      key="key-company"
      chartElement={props.keyCompany}
      hoverDate={props.hoverDate}
    />
    {_.map(props.indicators, (indicator, index) => (
      <IndicatorLegendItem
        key={indicator.id}
        chartElement={{
          color: INDICATOR_COLORS[index % INDICATOR_COLORS.length],
          ...indicator,
        }}
        setVisibility={(visible) => props.setVisibility(indicator.id, visible)}
        removeElement={() =>
          props.removeSeries(SERIES_TYPES.INDICATOR, indicator.id)
        }
        hoverDate={props.hoverDate}
        percentDisplay={props.comparisons.length}
      />
    ))}
    {_.map(props.comparisons, (comp) => (
      <ComparisonLegendItem
        key={comp.id}
        chartElement={comp}
        setVisibility={(visible) => props.setVisibility(comp.id, visible)}
        removeElement={() =>
          props.removeSeries(SERIES_TYPES.COMPARISON, comp.id)
        }
        hoverDate={props.hoverDate}
      />
    ))}
  </StyledLegend>
);

Legend.propTypes = {
  keyCompany: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  indicators: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  comparisons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string,
      exchange: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  hoverDate: PropTypes.string,
  setVisibility: PropTypes.func,
  removeSeries: PropTypes.func.isRequired,
};

Legend.defaultProps = {
  hoverDate: "",
  setVisibility: _.noop,
};

export default Legend;
