import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import SetNewPassword from "../SetNewPassword";

import AxiosInstance from "../../api/AxiosInstance";
import { API_ENDPOINTS } from "../../api/constants";
import { AuthContext } from "../../auth";

const ResetPasswordForm = () => {
  const params = useParams();
  const token = params.token;
  const { authenticate } = useContext(AuthContext);

  const onSubmit = async (values) => {
    if (values.password !== values.confirm_password) {
      return {
        // The view for resetting password uses a library that doesn't have support for
        // password confirmation, so we do it in the frontend.
        confirm_password: "Passwords do not match",
      };
    }
    return AxiosInstance.post(
      API_ENDPOINTS.RESET_PASSWORD_SET_PASSWORD(),
      JSON.stringify({
        password: values.password,
        token,
      })
    )
      .then(({ data }) => {
        authenticate(data, true);
      })
      .catch(({ response: { data } }) => {
        let errorMsg = "Unknown error occurred";
        if (data.password) {
          errorMsg = data.password.join(" ");
        } else if (data.detail) {
          errorMsg = "Expired or invalid URL.";
        }
        return {
          confirm_password: errorMsg,
        };
      });
  };

  return <SetNewPassword onSubmit={onSubmit} includeAgreements={false} />;
};

export default ResetPasswordForm;
