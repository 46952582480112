import { useEffect } from "react";
import PropTypes from "prop-types";

import { GraphSection } from "./StyledComponents";
import useEndDate from "./hooks/useEndDate";

import useFetch from "../../api/useFetch";
import { API_ENDPOINTS } from "../../api/constants";
import { ORDER_FLOW_TIME_FRAME_MONTHLY } from "../../utils/constants";
import LoadingSpinner from "../../utils/LoadingSpinner";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";

import { TIME_FRAME_MONTHLY } from "./graph-components/constants";
import HoldingPriceGraph from "./graph-components/HoldingPriceGraph";

/**
 * Renders the "Holding Price" graph in Company Profile.
 *
 * @param {object} props
 * @returns {Element}
 */
const HoldingPriceGraphView = (props) => {
  const companyReportedMarksValuationQueryInfo = useFetch(
    API_ENDPOINTS.COMPANY_REPORTED_MARKS_VALUATION(props.companyId)
  );

  useEffect(() => {
    if (props.companyName) {
      MixpanelEvents.viewCompanyReportedMarksGraph(props.companyName);
    }
  }, [props.companyName]);

  const endDate = useEndDate(ORDER_FLOW_TIME_FRAME_MONTHLY);

  if (companyReportedMarksValuationQueryInfo.isLoading) {
    return <LoadingSpinner />;
  }

  const companyReportedMarksValuationData =
    companyReportedMarksValuationQueryInfo?.data?.data;
  // Transform fund report data into useful format for the grpah's holdingData prop.
  const holdingData = Object.keys(companyReportedMarksValuationData)
    .map((date) => ({ date, ...companyReportedMarksValuationData[date] }))
    .sort((a, b) => b.date - a.date);

  const graphTimeFrame = TIME_FRAME_MONTHLY;

  return (
    <GraphSection>
      <HoldingPriceGraph
        hasDataAccess={props.hasDataAccess}
        endDate={endDate}
        timeFrame={graphTimeFrame}
        holdingData={holdingData}
        companyReportedMarksValuationData={companyReportedMarksValuationData}
      />
    </GraphSection>
  );
};

HoldingPriceGraphView.propTypes = {
  companyId: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  hasDataAccess: PropTypes.bool,
};

HoldingPriceGraphView.defaultProps = {
  hasDataAccess: false,
};

export default HoldingPriceGraphView;
