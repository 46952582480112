import PropTypes from "prop-types";
import { StyledCaption2, StyledCaption1, headline1, ColorPalette } from "yuka";
import styled from "styled-components";
import _ from "lodash";

const StyledValue = styled.span`
  ${headline1}
  color: ${ColorPalette.white100}
`;

const SuperChartHeaderValue = (props) => (
  <div>
    <StyledCaption2>{props.name}</StyledCaption2>
    <div>
      <StyledValue>{_.isNull(props.value) ? "--" : props.value} </StyledValue>
      <StyledCaption1>{props.unit}</StyledCaption1>
    </div>
  </div>
);

SuperChartHeaderValue.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string.isRequired,
};

SuperChartHeaderValue.defaultProps = {
  value: null,
};

export default SuperChartHeaderValue;
