import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styled from "styled-components";
import { DateTime } from "luxon";
import {
  caption2,
  Card,
  CardStyles,
  Definition,
  HyperLink,
  List,
  LinkTypes,
} from "yuka";

import LoadingSpinner from "../utils/LoadingSpinner.jsx";

import { companyProfileBreakpoint, ERROR_MESSAGE } from "./constants";
import useFetch from "../api/useFetch";
import { API_ENDPOINTS } from "../api/constants";
import useCompanyFetch from "./utils/useCompanyFetch";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import AlternativeCardState from "./AlternativeCardState";

const StyledDescription = styled.p`
  ${caption2}
`;

const StyledFlexContainer = styled.div`
  @media ${`only screen and (max-width : ${companyProfileBreakpoint}px)`} {
    display: flex;
    gap: 8px;
  }
`;

const formatWebsiteUrl = (rawUrl) =>
  rawUrl ? rawUrl.replace("http://", "").replace("www.", "") : "";

const formatDate = (rawDate) =>
  DateTime.fromISO(rawDate).toLocaleString(DateTime.DATE_SHORT);

const getS1Filing = (s1Filings, s1aFilings) =>
  _.sortBy(_.compact([s1Filings[0], s1aFilings[0]]), (filing) =>
    DateTime.fromISO(filing.filing_date)
  ).at(-1);

const makeS1Url = (cik, accessionNum, document) =>
  `https://www.sec.gov/Archives/edgar/data/${cik}/${accessionNum.replace(
    /-/g,
    ""
  )}/${document}`;

const CARD_TITLE = "About";

const CompanyDetailCard = (props) => {
  const company = useCompanyFetch(props.companyId);

  const sectors = useFetch(API_ENDPOINTS.COMPANY_SECTORS(), {
    company: props.companyId,
  });

  const s1Filings = useFetch(API_ENDPOINTS.SEC_FILINGS(), {
    company: props.companyId,
    form_type: "S-1",
  });

  const s1aFilings = useFetch(API_ENDPOINTS.SEC_FILINGS(), {
    company: props.companyId,
    form_type: "S-1/A",
  });

  if (
    _.some([company, sectors, s1Filings, s1aFilings], (res) => res.isLoading)
  ) {
    return (
      <AlternativeCardState cardTitle={CARD_TITLE}>
        <LoadingSpinner absolute={false} />
      </AlternativeCardState>
    );
  }

  if (_.some([company, sectors, s1Filings, s1aFilings], (res) => res.isError)) {
    return (
      <AlternativeCardState cardTitle={CARD_TITLE}>
        {ERROR_MESSAGE}
      </AlternativeCardState>
    );
  }

  const data = company.cleanedData.data;
  const companySectors = sectors.cleanedData.data;
  const s1Filing = getS1Filing(
    s1Filings.cleanedData.data,
    s1aFilings.cleanedData.data
  );

  const isSmall = window.innerWidth <= companyProfileBreakpoint;
  const DefinitionValueContainer = isSmall
    ? React.Fragment
    : StyledFlexContainer;

  return (
    <Card
      title={CARD_TITLE}
      cardStyle={CardStyles.PADDED}
      id="company-profile-walkthrough-scroll-anchor"
    >
      <StyledDescription>{data.description}</StyledDescription>
      <List divider>
        <Definition label="Legal name" value={data.legal_name || data.name} />
        <Definition label="Headquarters" value={`${data.headquarters}`} />
        <Definition
          label="Sectors"
          value={
            <DefinitionValueContainer>
              {_.map(companySectors, (sector, index) =>
                isSmall ? (
                  `${sector.name}${
                    index < companySectors.length - 1 ? ", " : ""
                  }`
                ) : (
                  <div>{sector.name}</div>
                )
              )}
            </DefinitionValueContainer>
          }
        />
        <Definition
          label="Website"
          value={
            <HyperLink
              onClick={() =>
                MixpanelEvents.navigateToCompanyWebsite(data.name, data.url)
              }
              url={data.url}
              linkType={LinkTypes.EXTERNAL_LINK}
            >
              {formatWebsiteUrl(data.url)}
            </HyperLink>
          }
        />
        {s1Filing && (
          <Definition
            label={`S-1 Filing ${
              s1Filing.filing_date
                ? `(${formatDate(s1Filing.filing_date)})`
                : ""
            }`}
            value={
              <HyperLink
                url={makeS1Url(
                  data.cik,
                  s1Filing.accession_number,
                  s1Filing.primary_document
                )}
                linkType={LinkTypes.EXTERNAL_LINK}
              >
                View
              </HyperLink>
            }
          />
        )}
      </List>
    </Card>
  );
};

CompanyDetailCard.propTypes = {
  companyId: PropTypes.string.isRequired,
  company: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    legal_name: PropTypes.string,
    s1_filed_on: PropTypes.string,
    latest_s1_url: PropTypes.string,
    coi_filed_on: PropTypes.string,
    description: PropTypes.string,
    metadata: PropTypes.shape({
      headquarters: PropTypes.string,
      country: PropTypes.string,
      url: PropTypes.string,
      has_cert_of_inc: PropTypes.bool,
    }),
    thumbnail: PropTypes.string,
    sectors: PropTypes.arrayOf(PropTypes.number),
  }),
  sectors: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, id: PropTypes.number })
  ),
  companyProfileMpEventParams: PropTypes.shape({
    hasProDataAccess: PropTypes.bool,
  }),
};

CompanyDetailCard.defaultProps = {
  company: null,
  sectors: [],
  companyProfileMpEventParams: {},
};

export default CompanyDetailCard;
