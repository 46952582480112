import {
  AreaSeries,
  FlexibleXYPlot,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  XAxis,
  YAxis,
  Crosshair,
  MarkSeries,
} from "react-vis";
import { DateTime } from "luxon";
import styled, { css } from "styled-components";
import React, { useState, useMemo } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import { ColorPalette, GraphCard, Table, YukaColorPalette } from "yuka";

import { shortMoneyFormat } from "/src/utils/displayFormatUtils";

import LegendItem from "./LegendItem";
import {
  GraphContainer,
  StyledTableContainer,
  ReportPeriodWrapper,
  StyledGraphHeader,
  StyledTypography50,
} from "./StyledComponents";

import {
  AXIS_STYLE,
  GRIDLINE_STYLE,
  MAX_PADDING_FACTOR,
  NO_DATA_GRAPH_CARD_HEIGHT,
  GRAPH_COLORS,
  REPORTED_MARKS_TABLE_COLUMNS,
} from "./constants";
import { splitZeroSeparator, getMonthlyHoverPointsArray } from "./graphUtils";

const CardBodyGraphContainer = styled.div`
  height: 410px;
  cursor: pointer;
`;

const StyledHoldingPriceGraphCard = styled(GraphCard)`
  flex-grow: 1;

  ${(props) =>
    props.$isEmpty
      ? css`
          height: ${NO_DATA_GRAPH_CARD_HEIGHT}px;
        `
      : ""}
`;

/**
 * Renders the "Holding Data Price" graphs in Company Profile.
 *
 * @param {object} props
 * @returns {Element}
 */
const HoldingPriceGraph = (props) => {
  const [hoverCrosshairValue, setHoverCrosshairValue] = useState(null);
  const [selectedCrosshairValue, setSelectedCrosshairValue] = useState(null);
  const [hasUserSelected, setHasUserSelected] = useState(false);

  const startDate = DateTime.fromISO(props.endDate)
    .startOf("month")
    .minus({ months: 12 });

  // Used by AreaSeries
  const transformedData = useMemo(() => {
    const filteredAreas = _.filter(
      props.holdingData,
      (dataPoint) =>
        dataPoint.min ||
        dataPoint.max ||
        dataPoint.median ||
        dataPoint.simple_avg
    );
    return _.map(filteredAreas, (dataPoint) => ({
      x: DateTime.fromISO(dataPoint.date)
        .startOf("month")
        .diff(startDate, ["months"]).months,
      y: dataPoint.max,
      y0: dataPoint.min,
    }));
  }, [startDate, props.holdingData]);

  // Transform { date, value } pairs into { x, y } coordinates
  const transformedWeightedAverageData = useMemo(
    () =>
      _.map(props.holdingData, (dataPoint) => ({
        x: DateTime.fromISO(dataPoint.date)
          .startOf("month")
          .diff(startDate, ["months"]).months,
        y: dataPoint.weighted_avg,
      })),
    [startDate, props.holdingData]
  );
  const splitWeightedAverageData = splitZeroSeparator(
    transformedWeightedAverageData
  );

  const transformedSimpleAverageData = useMemo(
    () =>
      _.map(props.holdingData, (dataPoint) => ({
        x: DateTime.fromISO(dataPoint.date)
          .startOf("month")
          .diff(startDate, ["months"]).months,
        y: dataPoint.simple_avg,
      })),
    [startDate, props.holdingData]
  );
  const splitSimpleAverageData = splitZeroSeparator(
    transformedSimpleAverageData
  );

  const transformedMedianData = useMemo(
    () =>
      _.map(props.holdingData, (dataPoint) => ({
        x: DateTime.fromISO(dataPoint.date)
          .startOf("month")
          .diff(startDate, ["months"]).months,
        y: dataPoint.median,
      })),
    [startDate, props.holdingData]
  );
  const splitMedianData = splitZeroSeparator(transformedMedianData);

  // Legend and MarkSeries (point) values
  const dollarWeightedAverageValue = useMemo(
    () =>
      _.find(_.flatten(splitWeightedAverageData), {
        x: selectedCrosshairValue?.x,
      }),
    [splitWeightedAverageData, selectedCrosshairValue]
  );
  const simpleAverageValue = useMemo(
    () =>
      _.find(_.flatten(splitSimpleAverageData), {
        x: selectedCrosshairValue?.x,
      }),
    [splitSimpleAverageData, selectedCrosshairValue]
  );
  const medianValue = useMemo(
    () => _.find(_.flatten(splitMedianData), { x: selectedCrosshairValue?.x }),
    [splitMedianData, selectedCrosshairValue]
  );
  const mutualFundValue = useMemo(
    () => _.find(transformedData, { x: selectedCrosshairValue?.x }),
    [transformedData, selectedCrosshairValue]
  );

  const maxAverage =
    _.max(
      _.concat(
        _.map(transformedWeightedAverageData, (dataPoint) =>
          _.toNumber(dataPoint.y)
        ),
        _.map(transformedSimpleAverageData, (dataPoint) =>
          _.toNumber(dataPoint.y)
        ),
        _.map(transformedMedianData, (dataPoint) => _.toNumber(dataPoint.y)),
        _.map(transformedData, (dataPoint) => dataPoint.y)
      )
    ) * MAX_PADDING_FACTOR;

  const boundaryPoints = [
    { x: 0.5, y: 0 },
    { x: 12.5, y: maxAverage },
  ];

  const hoverPointsArray = getMonthlyHoverPointsArray(props.endDate);

  const isEmpty = useMemo(
    () =>
      _.every(props.holdingData, {
        min: null,
        max: null,
        median: null,
        simple_avg: null,
      }),
    [props.holdingData]
  );

  const legendValues = useMemo(
    () => [
      {
        text: (
          <LegendItem
            text="Dollar-Weighted Average"
            value={
              dollarWeightedAverageValue
                ? shortMoneyFormat(dollarWeightedAverageValue.y)
                : ""
            }
          />
        ),
        color: ColorPalette.blue500,
        tooltip:
          "Sum product of size and price at which funds are marking their positions",
      },
      {
        text: (
          <LegendItem
            text="Simple Average"
            value={
              simpleAverageValue ? shortMoneyFormat(simpleAverageValue.y) : ""
            }
          />
        ),
        color: GRAPH_COLORS.BLUE,
        tooltip:
          "Simple average of the price  at which funds are marking their positions",
      },
      {
        text: (
          <LegendItem
            text="Median Average"
            value={medianValue ? shortMoneyFormat(medianValue.y) : ""}
          />
        ),
        color: GRAPH_COLORS.MAGENTA,
        tooltip:
          "Median of the price at which funds are marking their positions",
      },
      {
        text: (
          <LegendItem
            text="Max"
            value={mutualFundValue ? shortMoneyFormat(mutualFundValue.y) : ""}
          />
        ),
        tooltip: "Maximum price at which funds are marking their positions",
      },
      {
        text: (
          <LegendItem
            text="Min"
            value={mutualFundValue ? shortMoneyFormat(mutualFundValue.y0) : ""}
          />
        ),
        tooltip: "Minimum price at which funds are marking their positions",
      },
    ],
    [
      dollarWeightedAverageValue,
      simpleAverageValue,
      medianValue,
      mutualFundValue,
    ]
  );

  const tableData = useMemo(() => {
    const date = startDate
      .plus({ months: selectedCrosshairValue?.x })
      .toISODate();

    return props.companyReportedMarksValuationData?.hasOwnProperty(date)
      ? props.companyReportedMarksValuationData[date]?.funds
      : [];
  }, [
    startDate,
    props.companyReportedMarksValuationData,
    selectedCrosshairValue,
  ]);

  const reportPeriod = selectedCrosshairValue
    ? startDate.plus({ months: selectedCrosshairValue?.x }).toLocaleString({
        month: "short",
        year: "2-digit",
      })
    : "--";

  return (
    <GraphContainer>
      <React.Fragment>
        <StyledHoldingPriceGraphCard
          title={
            <StyledGraphHeader>
              <span>{"Reported Marks - Price"}</span>
              <ReportPeriodWrapper>
                <StyledTypography50>Report Period: </StyledTypography50>
                {reportPeriod}
              </ReportPeriodWrapper>
            </StyledGraphHeader>
          }
          legend={legendValues}
          $isEmpty={isEmpty}
        >
          <CardBodyGraphContainer>
            <FlexibleXYPlot
              margin={{ left: 0, right: 50 }}
              onMouseLeave={() => {
                if (!hasUserSelected) {
                  setSelectedCrosshairValue(null);
                }
                setHoverCrosshairValue(null);
              }}
              onMouseDown={() => {
                if (!hoverCrosshairValue || !hasUserSelected) {
                  setHasUserSelected(!hasUserSelected);
                }
                setSelectedCrosshairValue(hoverCrosshairValue);
              }}
            >
              <MarkSeries data={boundaryPoints} size={0} />
              {!_.isNull(selectedCrosshairValue) && (
                <MarkSeries
                  colorType="literal"
                  size={4}
                  data={_.compact([
                    !_.isUndefined(dollarWeightedAverageValue)
                      ? {
                          ...dollarWeightedAverageValue,
                          color: ColorPalette.blue500,
                        }
                      : null,
                    !_.isUndefined(simpleAverageValue)
                      ? { ...simpleAverageValue, color: GRAPH_COLORS.BLUE }
                      : null,
                    !_.isUndefined(medianValue)
                      ? { ...medianValue, color: GRAPH_COLORS.MAGENTA }
                      : null,
                  ])}
                  stroke={YukaColorPalette.surface1}
                />
              )}
              {!_.isNull(selectedCrosshairValue) && (
                <Crosshair
                  values={[selectedCrosshairValue]}
                  style={{ line: { background: ColorPalette.white50 } }}
                >
                  <React.Fragment />
                </Crosshair>
              )}
              {hasUserSelected && !_.isNull(hoverCrosshairValue) && (
                <Crosshair
                  values={[hoverCrosshairValue]}
                  style={{ line: { background: ColorPalette.white50 } }}
                >
                  <React.Fragment />
                </Crosshair>
              )}
              <LineSeries
                color="rgba(0, 0, 0, 0)"
                opacity={0}
                data={hoverPointsArray}
                onNearestX={(value) => {
                  setHoverCrosshairValue(value);
                  if (!hasUserSelected) {
                    setSelectedCrosshairValue(value);
                  }
                }}
                strokeWidth={1}
              />
              {_.map(splitWeightedAverageData, (arr, i) => (
                <LineSeries
                  key={i}
                  color={ColorPalette.blue500}
                  data={arr}
                  strokeWidth={1}
                />
              ))}
              {_.map(splitSimpleAverageData, (arr, i) => (
                <LineSeries
                  key={i}
                  color={GRAPH_COLORS.BLUE}
                  data={arr}
                  strokeWidth={1}
                />
              ))}
              {_.map(splitMedianData, (arr, i) => (
                <LineSeries
                  key={i}
                  color={GRAPH_COLORS.MAGENTA}
                  data={arr}
                  strokeWidth={1}
                />
              ))}
              {_.isEmpty(transformedData) || (
                <AreaSeries
                  color={ColorPalette.white05}
                  stroke="none"
                  data={transformedData}
                />
              )}
              <HorizontalGridLines style={GRIDLINE_STYLE} tickTotal={8} />
              <VerticalGridLines style={GRIDLINE_STYLE} tickTotal={12} />
              <XAxis
                style={AXIS_STYLE}
                tickSize={0}
                tickTotal={12}
                tickFormat={(value) =>
                  startDate.plus({ months: value }).toLocaleString({
                    month: "short",
                    year: "2-digit",
                  })
                }
              />
              <YAxis
                orientation="right"
                hideLine
                tickSize={0}
                tickTotal={8}
                tickFormat={(value) => shortMoneyFormat(value)}
                style={AXIS_STYLE}
              />
            </FlexibleXYPlot>
          </CardBodyGraphContainer>
          <StyledTableContainer>
            <Table
              data={hasUserSelected ? tableData : []}
              columns={REPORTED_MARKS_TABLE_COLUMNS}
              usePercentageColumnWidths
              emptyTablePlaceholder="Please select a specific month to see the underlying funds"
            />
          </StyledTableContainer>
        </StyledHoldingPriceGraphCard>
      </React.Fragment>
    </GraphContainer>
  );
};

HoldingPriceGraph.propTypes = {
  hasDataAccess: PropTypes.bool.isRequired,
  endDate: PropTypes.string.isRequired,
  holdingData: PropTypes.arrayOf(
    PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
      median: PropTypes.number,
      weighted_avg: PropTypes.number,
      simple_avg: PropTypes.number,
      date: PropTypes.string,
    })
  ).isRequired,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      price_per_share: PropTypes.number,
      security: PropTypes.string,
      total_value: PropTypes.number,
      date: PropTypes.string,
    })
  ),
};

export default HoldingPriceGraph;
