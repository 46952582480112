import {
  FlexibleXYPlot,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  XAxis,
  YAxis,
  MarkSeries,
} from "react-vis";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import { ColorPalette } from "yuka";

import LegendItem from "./LegendItem";
import NoDataPlaceholder from "./NoDataPlaceholder";
import {
  GraphContainer,
  StyledGraphCard,
  ReportPeriodWrapper,
  StyledGraphHeader,
  StyledTypography50,
} from "./StyledComponents";

import {
  AXIS_STYLE,
  GRIDLINE_STYLE,
  GRAPH_COLORS,
  TIME_FRAME_MONTHLY,
} from "./constants";
import {
  createGraphArray,
  getMonthlyHoverPointsArray,
  isSplitGraphEmpty,
  splitZeroSeparator,
} from "./graphUtils";

const { BLUE, PURPLE_200, PINK_100 } = GRAPH_COLORS;
// Y-axis values Top 100/80/60/40/20/1%
const Y_AXIS_LOWER_BOUND_0 = 0;
// Y-axis values Top 50/40/30/20/10/1%
const Y_AXIS_LOWER_BOUND_50 = 50;
// Y-axis values Top 25/20/15/10/5/1%
const Y_AXIS_LOWER_BOUND_75 = 75;

// Directly correlates to constants in `OrderFlowReport` model
const CHOICE_BOTTOM_25 = 24;
const CHOICE_BOTTOM_50 = 49;
const CHOICE_TOP_50 = 54;
const CHOICE_TOP_45 = 59;
const CHOICE_TOP_40 = 64;
const CHOICE_TOP_35 = 69;
const CHOICE_TOP_30 = 74;
const CHOICE_TOP_25 = 79;
const CHOICE_TOP_20 = 84;
const CHOICE_TOP_15 = 89;
const CHOICE_TOP_10 = 94;
const CHOICE_TOP_5 = 97;
const CHOICE_TOP_2 = 98;
const CHOICE_TOP_1 = 99;

// Maps API values to values used in graph
// We transform y-values from API values to values that is more suitable for display in the graph
// e.g. "Top 5%" is represented as `97` in the API, but a value of `95` looks better on the graph
const CHOICE_MAPPING = {
  [CHOICE_BOTTOM_25]: 25,
  [CHOICE_BOTTOM_50]: 45,
  [CHOICE_TOP_50]: 50,
  [CHOICE_TOP_45]: 55,
  [CHOICE_TOP_40]: 60,
  [CHOICE_TOP_35]: 65,
  [CHOICE_TOP_30]: 70,
  [CHOICE_TOP_25]: 75,
  [CHOICE_TOP_20]: 80,
  [CHOICE_TOP_15]: 85,
  [CHOICE_TOP_10]: 90,
  [CHOICE_TOP_5]: 95,
  [CHOICE_TOP_2]: 99,
  [CHOICE_TOP_1]: 100,
};

/**
 * Util for getting the lower bound value for the graph.
 *
 * @param {Array<object>} data - Array of graph data of shape { x, y }
 * @returns {number}
 */
const getYAxisLowerBound = (data) => {
  const dataPoints = _.compact(_.map(data, "y"));
  const min = _.min(dataPoints);

  if (min < Y_AXIS_LOWER_BOUND_50) {
    return Y_AXIS_LOWER_BOUND_0;
  }
  if (min < Y_AXIS_LOWER_BOUND_75) {
    return Y_AXIS_LOWER_BOUND_50;
  }
  return Y_AXIS_LOWER_BOUND_75;
};

/**
 * Transforms y-values from API values to a value that is more suitable for display in the graph
 *
 * @param {Array<object>} data - Graph data of shape { x, y }
 * @returns {Array<object>} Transformed graph data
 */
const transformGraphData = (data) =>
  _.map(data, (d) => ({
    x: d.x,
    y: CHOICE_MAPPING[d.y],
  }));

/**
 * Util for displaying crosshair values
 *
 * @param {number} value
 * @returns {string}
 */
const crosshairValueFormat = (value) => {
  // Values in these cases map to values in `CHOICE_MAPPING`
  /* eslint-disable no-magic-numbers */
  switch (value) {
    case 25:
      return "Bottom 25%";
    case 45:
      return "Bottom 50%";
    case 50:
      return "Top 50%";
    case 55:
      return "Top 45%";
    case 60:
      return "Top 40%";
    case 65:
      return "Top 35%";
    case 70:
      return "Top 30%";
    case 75:
      return "Top 25%";
    case 80:
      return "Top 20%";
    case 85:
      return "Top 15%";
    case 90:
      return "Top 10%";
    case 95:
      return "Top 5%";
    case 99:
      return "Top 2%";
    case 100:
      return "Top 1%";
    default:
      return "N/A";
  }
  /* eslint-enable no-magic-numbers */
};

/**
 * Util for displaying Y-axis tick values
 *
 * @param {number} value
 * @returns {string}
 */
const yAxisTickFormat = (value) => {
  if (value === 0) {
    return "";
  }
  if (value === 100) {
    return "Top 1%";
  }
  return `Top ${100 - value}%`;
};

/**
 * Renders the "Relative Activity" graphs in Company Profile.
 *
 * @param {object} props
 * @returns {Element}
 */
const RelativeActivityGraph = (props) => {
  const startDate = DateTime.fromISO(props.endDate)
    .startOf("month")
    .minus({ months: 12 });

  // Track if user is hovering over graph by setting a crosshair
  const [totalInterestCrosshairValue, setTotalInterestCrosshairValue] =
    useState(null);
  const [numTicketsCrosshairValue, setNumTicketsCrosshairValue] =
    useState(null);
  const [companyViewsCrosshairValue, setCompanyViewsCrosshairValue] =
    useState(null);

  // LineSeries values
  const totalInterestLineSeriesData = useMemo(
    () =>
      _.map(
        splitZeroSeparator(
          createGraphArray(
            props.rankingTotalInterest,
            TIME_FRAME_MONTHLY,
            props.endDate,
            props.hasDataAccess,
            false
          )
        ),
        (subarray) => transformGraphData(subarray)
      ),
    [props.rankingTotalInterest]
  );
  const numTicketsLineSeriesData = useMemo(
    () =>
      _.map(
        splitZeroSeparator(
          createGraphArray(
            props.rankingNumTickets,
            TIME_FRAME_MONTHLY,
            props.endDate,
            props.hasDataAccess,
            false
          )
        ),
        (subarray) => transformGraphData(subarray)
      ),
    [props.rankingNumTickets]
  );
  const companyViewsLineSeriesData = useMemo(
    () =>
      _.map(
        splitZeroSeparator(
          createGraphArray(
            props.rankingCompanyViews,
            TIME_FRAME_MONTHLY,
            props.endDate,
            props.hasDataAccess,
            false
          )
        ),
        (subarray) => transformGraphData(subarray)
      ),
    [props.rankingCompanyViews]
  );

  // Crosshair values
  const totalInterestValue = useMemo(
    () =>
      _.find(_.flatten(totalInterestLineSeriesData), {
        x: totalInterestCrosshairValue?.x,
      }),
    [totalInterestCrosshairValue, totalInterestLineSeriesData]
  );
  const numTicketsValue = useMemo(
    () =>
      _.find(_.flatten(numTicketsLineSeriesData), {
        x: numTicketsCrosshairValue?.x,
      }),
    [numTicketsCrosshairValue, numTicketsLineSeriesData]
  );
  const companyViewsValue = useMemo(
    () =>
      _.find(_.flatten(companyViewsLineSeriesData), {
        x: companyViewsCrosshairValue?.x,
      }),
    [companyViewsCrosshairValue, companyViewsLineSeriesData]
  );

  // Boundary points
  const totalInterestBoundaryPoints = [
    { x: 1, y: getYAxisLowerBound(props.rankingTotalInterest) },
    { x: 12, y: 100 },
  ];
  const numTicketsBoundaryPoints = [
    { x: 1, y: getYAxisLowerBound(props.rankingNumTickets) },
    { x: 12, y: 100 },
  ];
  const companyViewsBoundaryPoints = [
    { x: 1, y: getYAxisLowerBound(props.rankingCompanyViews) },
    { x: 12, y: 100 },
  ];

  // used to ensure graph x-axis range is always 12 months even if
  // graph data only contains 3 months worth of data
  // and to set crosshair values based on hovered region
  const hoverPointsArray = getMonthlyHoverPointsArray(props.endDate);

  const totalInterestReportPeriod = totalInterestCrosshairValue
    ? startDate
        .plus({
          months: totalInterestCrosshairValue?.x,
        })
        .toLocaleString({
          month: "short",
          year: "2-digit",
        })
    : "--";
  const numTicketsReportPeriod = numTicketsCrosshairValue
    ? startDate
        .plus({
          months: numTicketsCrosshairValue?.x,
        })
        .toLocaleString({
          month: "short",
          year: "2-digit",
        })
    : "--";
  const companyViewsReportPeriod = companyViewsCrosshairValue
    ? startDate
        .plus({
          months: companyViewsCrosshairValue?.x,
        })
        .toLocaleString({
          month: "short",
          year: "2-digit",
        })
    : "--";

  return (
    <GraphContainer>
      <StyledGraphCard
        title={
          <StyledGraphHeader>
            <span>Ranking - Total Interest</span>
            <ReportPeriodWrapper>
              <StyledTypography50>Report Period: </StyledTypography50>
              {totalInterestReportPeriod}
            </ReportPeriodWrapper>
          </StyledGraphHeader>
        }
        $isEmpty={isSplitGraphEmpty([totalInterestLineSeriesData])}
        legend={[
          {
            text: (
              <LegendItem
                text="Ranking Total Interest"
                value={
                  totalInterestValue
                    ? crosshairValueFormat(totalInterestValue.y)
                    : ""
                }
              />
            ),
            color: ColorPalette.blue500,
            tooltip:
              "Market activity rankings of each company on ZX based on total interest",
          },
        ]}
      >
        {isSplitGraphEmpty([totalInterestLineSeriesData]) ? (
          <NoDataPlaceholder height="400px" />
        ) : (
          <FlexibleXYPlot
            margin={{ left: 16, right: 50 }}
            onMouseLeave={() => {
              setTotalInterestCrosshairValue(null);
            }}
          >
            <MarkSeries data={totalInterestBoundaryPoints} size={0} />
            <LineSeries
              color="rgba(0, 0, 0, 0)"
              data={hoverPointsArray}
              onNearestX={(value) => {
                setTotalInterestCrosshairValue(value);
                props.onNearestX(value);
              }}
              strokeWidth={1}
            />
            {!_.isNull(totalInterestCrosshairValue) && (
              <MarkSeries
                colorType="literal"
                size={4}
                data={_.compact([
                  !_.isUndefined(totalInterestValue)
                    ? { ...totalInterestValue, color: GRAPH_COLORS.BLUE }
                    : null,
                ])}
                stroke={ColorPalette.black10}
              />
            )}
            {_.map(totalInterestLineSeriesData, (arr, i) => (
              <LineSeries
                key={i}
                color={GRAPH_COLORS.BLUE}
                data={arr}
                strokeWidth={1}
              />
            ))}
            <HorizontalGridLines style={GRIDLINE_STYLE} tickTotal={5} />
            <VerticalGridLines style={GRIDLINE_STYLE} tickTotal={12} />
            <XAxis
              style={AXIS_STYLE}
              tickSize={0}
              tickTotal={12}
              tickFormat={(value) =>
                startDate.plus({ months: value }).toLocaleString({
                  month: "short",
                  year: "2-digit",
                })
              }
            />
            <YAxis
              orientation="right"
              hideLine
              tickSize={0}
              tickTotal={5}
              tickFormat={(value) => yAxisTickFormat(value)}
              style={AXIS_STYLE}
            />
          </FlexibleXYPlot>
        )}
      </StyledGraphCard>

      {/* Num Tickets */}
      <StyledGraphCard
        title={
          <StyledGraphHeader>
            <span>Ranking - # Tickets</span>
            <ReportPeriodWrapper>
              <StyledTypography50>Report Period: </StyledTypography50>
              {numTicketsReportPeriod}
            </ReportPeriodWrapper>
          </StyledGraphHeader>
        }
        $isEmpty={isSplitGraphEmpty([numTicketsLineSeriesData])}
        legend={[
          {
            text: (
              <LegendItem
                text="Ranking # Tickets"
                value={
                  numTicketsValue ? crosshairValueFormat(numTicketsValue.y) : ""
                }
              />
            ),
            color: BLUE,
            tooltip:
              "Market activity rankings of each company on ZX based on number of tickets submitted",
          },
        ]}
      >
        {isSplitGraphEmpty([numTicketsLineSeriesData]) ? (
          <NoDataPlaceholder height="400px" />
        ) : (
          <FlexibleXYPlot
            margin={{ left: 16, right: 50 }}
            onMouseLeave={() => {
              setNumTicketsCrosshairValue(null);
            }}
          >
            <MarkSeries data={numTicketsBoundaryPoints} size={0} />
            <LineSeries
              color="rgba(0, 0, 0, 0)"
              data={hoverPointsArray}
              onNearestX={(value) => {
                setNumTicketsCrosshairValue(value);
                props.onNearestX(value);
              }}
              strokeWidth={1}
            />
            {!_.isNull(numTicketsCrosshairValue) && (
              <MarkSeries
                colorType="literal"
                size={4}
                data={_.compact([
                  !_.isUndefined(numTicketsValue)
                    ? { ...numTicketsValue, color: PURPLE_200 }
                    : null,
                ])}
                stroke={ColorPalette.black10}
              />
            )}
            {_.map(numTicketsLineSeriesData, (arr, i) => (
              <LineSeries
                key={i}
                color={PURPLE_200}
                data={arr}
                strokeWidth={1}
              />
            ))}
            <HorizontalGridLines style={GRIDLINE_STYLE} tickTotal={5} />
            <VerticalGridLines style={GRIDLINE_STYLE} tickTotal={12} />
            <XAxis
              style={AXIS_STYLE}
              tickSize={0}
              tickTotal={12}
              tickFormat={(value) =>
                startDate.plus({ months: value }).toLocaleString({
                  month: "short",
                  year: "2-digit",
                })
              }
            />
            <YAxis
              orientation="right"
              hideLine
              tickSize={0}
              tickTotal={5}
              tickFormat={(value) => yAxisTickFormat(value)}
              style={AXIS_STYLE}
            />
          </FlexibleXYPlot>
        )}
      </StyledGraphCard>

      {/* Company Views */}
      <StyledGraphCard
        title={
          <StyledGraphHeader>
            <span>Ranking - Company Views</span>
            <ReportPeriodWrapper>
              <StyledTypography50>Report Period: </StyledTypography50>
              {companyViewsReportPeriod}
            </ReportPeriodWrapper>
          </StyledGraphHeader>
        }
        $isEmpty={isSplitGraphEmpty([companyViewsLineSeriesData])}
        legend={[
          {
            text: (
              <LegendItem
                text="Ranking Company Views"
                value={
                  companyViewsValue
                    ? crosshairValueFormat(companyViewsValue.y)
                    : ""
                }
              />
            ),
            color: PINK_100,
            tooltip:
              "Market activity rankings of each company on ZX based on company views",
          },
        ]}
      >
        {isSplitGraphEmpty([companyViewsLineSeriesData]) ? (
          <NoDataPlaceholder height="400px" />
        ) : (
          <FlexibleXYPlot
            margin={{ left: 16, right: 50 }}
            onMouseLeave={() => {
              setCompanyViewsCrosshairValue(null);
            }}
          >
            <MarkSeries data={companyViewsBoundaryPoints} size={0} />
            <LineSeries
              color="rgba(0, 0, 0, 0)"
              data={hoverPointsArray}
              onNearestX={(value) => {
                setCompanyViewsCrosshairValue(value);
                props.onNearestX(value);
              }}
              strokeWidth={1}
            />
            {!_.isNull(companyViewsCrosshairValue) && (
              <MarkSeries
                colorType="literal"
                size={4}
                data={_.compact([
                  !_.isUndefined(companyViewsValue)
                    ? { ...companyViewsValue, color: PINK_100 }
                    : null,
                ])}
                stroke={ColorPalette.black10}
              />
            )}
            {_.map(companyViewsLineSeriesData, (arr, i) => (
              <LineSeries key={i} color={PINK_100} data={arr} strokeWidth={1} />
            ))}
            <HorizontalGridLines style={GRIDLINE_STYLE} tickTotal={5} />
            <VerticalGridLines style={GRIDLINE_STYLE} tickTotal={12} />
            <XAxis
              style={AXIS_STYLE}
              tickSize={0}
              tickTotal={12}
              tickFormat={(value) =>
                startDate.plus({ months: value }).toLocaleString({
                  month: "short",
                  year: "2-digit",
                })
              }
            />
            <YAxis
              orientation="right"
              hideLine
              tickSize={0}
              tickTotal={5}
              tickFormat={(value) => yAxisTickFormat(value)}
              style={AXIS_STYLE}
            />
          </FlexibleXYPlot>
        )}
      </StyledGraphCard>
    </GraphContainer>
  );
};

RelativeActivityGraph.propTypes = {
  companyId: PropTypes.string.isRequired,
  hasDataAccess: PropTypes.bool.isRequired,
  endDate: PropTypes.string.isRequired,
  onNearestX: PropTypes.func,
  rankingTotalInterest: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
  ).isRequired,
  rankingNumTickets: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
  ).isRequired,
  rankingCompanyViews: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
  ).isRequired,
};

RelativeActivityGraph.defaultProps = {
  onNearestX: _.noop,
};

export default RelativeActivityGraph;
