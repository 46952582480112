import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import styled, { css } from "styled-components";

import getLinkProps, { LinkTypes } from "./getLinkProps";

import { body1, FontColors } from "../Typography";
import { ColorPalette, YukaColorPalette } from "../StylingConstants";

const ButtonStyles = {
  OUTLINE: "OUTLINE",
  CTA: "CTA",
  BUY: "BUY",
  SELL: "SELL",
  KEY_ACTION: "KEY_ACTION",
};

const StyledButton = styled.button.attrs({ className: "btn" })`
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  min-width: 0;
  outline: none;
  transition: all 0.1s cubic-bezier(0.33, 0, 0.2, 1);

  // 1  - Container
  ${props =>
    props.$buttonStyle === ButtonStyles.KEY_ACTION
      ? css`
          border: none;
          border-radius: 12px;
          padding: 9px 12px;
          height: 44px;
        `
      : css`
          border: 1px solid ${ColorPalette.white30};
          border-radius: 16px;
          padding: 0 12px;
          height: 32px;
        `}

  // 2 - Button Text
  ${body1};
  line-height: 16px;

  // 3 - Leading/Trailing Icon
  // height, width, color set as props
  svg {
    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }

  // Conditional Styles from props.$buttonStyle and $selected
  ${props => {
    // 4 - Disabled
    if (props.disabled) {
      switch (props.$buttonStyle) {
        case ButtonStyles.OUTLINE:
          return css`
            border: 1px solid ${ColorPalette.white15};
            background: rgba(255, 255, 255, 0);
            cursor: default;
            ${FontColors.theme30}
          `;
        case ButtonStyles.KEY_ACTION:
          return css`
            border: none;
            background: ${ColorPalette.white05};
            cursor: default;
            ${FontColors.theme30}
          `;
        default:
          return css`
            border: 1px solid transparent;
            background: ${ColorPalette.white15};
            cursor: default;
            ${FontColors.theme30}
          `;
      }
    } else {
      switch (props.$buttonStyle) {
        case ButtonStyles.OUTLINE:
          return css`
            background: rgba(255, 255, 255, 0);
            ${FontColors.theme80}

            &&:hover {
              background: ${YukaColorPalette.hoverTransparent};
            }

            &&:active {
              background: ${YukaColorPalette.activeTransparent};
            }
          `;
        // Key Action
        case ButtonStyles.KEY_ACTION:
          return props.$selected
            ? css`
                background: ${ColorPalette.blue200};
                color: ${ColorPalette.blue500};
                border: none;

                &&:hover {
                  background: ${ColorPalette.blue300};
                }

                &&:active {
                  background: ${ColorPalette.blue300};
                  color: ${ColorPalette.blue700};

                  svg path {
                    fill: ${ColorPalette.blue700};
                  }
                }
              `
            : css`
                background: ${ColorPalette.white10};
                color: ${ColorPalette.white80};
                border: none;

                &&:hover {
                  background: ${ColorPalette.white15};
                }

                &&:active {
                  background: rgba(255, 255, 255, 0.17);
                }
              `;
        // Filled CTA
        case ButtonStyles.CTA:
          return css`
            background: ${ColorPalette.blue500};
            color: ${ColorPalette.black80};
            border: 1px solid transparent;

            &&:hover {
              background: ${ColorPalette.blue600};
            }

            &&:active {
              background: ${ColorPalette.blue700};
            }
          `;
        // Filled Bid Green
        case ButtonStyles.BUY:
          return css`
            background: ${ColorPalette.buy};
            color: ${ColorPalette.black80};
            border: 1px solid transparent;

            &&:hover {
              background: #33f8a2;
            }

            &&:active {
              background: #66fab9;
            }
          `;
        // Filled Sell Red
        case ButtonStyles.SELL:
          return css`
            background: ${ColorPalette.sell};
            color: ${ColorPalette.white100};
            border: 1px solid transparent;

            &&:hover {
              background: #ff7272;
            }

            &&:active {
              background: #ff7a7a;
            }
          `;
      }
    }
  }}

  // Apply selector using class name instead of & because styled components acts weird
  .icon-btn + &,
  .btn + & {
    // rows of buttons are spaced out
    margin-left: 12px;
  }
`;

const computeButtonIconColor = (buttonStyle, disabled, selected) => {
  if (disabled) {
    return ColorPalette.white15;
  }
  if (buttonStyle === ButtonStyles.CTA) {
    return ColorPalette.black50;
  }
  if (buttonStyle === ButtonStyles.KEY_ACTION && selected) {
    return ColorPalette.blue500;
  }
  return ColorPalette.white50;
};

/**
 * Renders an element that looks like a button to the screen. Underlying HTML element depends on
 * function.
 *
 * url - This prop is a string that is a valid href or a string/object that react-router can parse.
 *   This results in either an `<a>` tag or a `<Link>` being rendered.
 *
 * Either url, to, or href must be specified for `target`, `rel`, or `download` attributes to be applied
 *
 * onClick - This prop is a function handler that results in an html `<button>` being rendered if
 *   none of the above are specified. Must be set for `type` to be applied.
 *
 * buttonStyle - If this prop is supplied, then we render a filled button with the given fill style.
 *   If not supplied then we render an outline button.
 *
 * LinkTypes guideline:
 * - `BUTTON` - when no navigation is performed but need a text link visually
 * - `DOWNLOAD` - when the link is directly to a document to be downloaded
 * - `LOCAL_LINK` - the link navigates within a SPA
 * - `EXTERNAL_LINK` - the link navigates away from the environment, opens in a new tab
 * - `LINK` - a traditional `<a>` tag without any special behaviors
 */
const Button = React.forwardRef((props, ref) => {
  const iconColor = computeButtonIconColor(props.buttonStyle, props.disabled, props.selected);

  const legacyProps = _.pick(props, [
    "newTab",
    "accent",
    "download",
    "href",
    "to",
    "active",
    "rel",
    "target",
  ]);
  const [computedLinkProps, renderAs] = getLinkProps(props.url, props.linkType, legacyProps);
  // Render a slightly larger icon for key action buttons.
  const iconSize = props.buttonStyle === ButtonStyles.KEY_ACTION ? 18 : 16;

  return (
    <StyledButton
      ref={ref}
      {...computedLinkProps}
      id={props.id}
      className={props.className}
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.type} // eslint-disable-line react/button-has-type
      $selected={props.buttonStyle === ButtonStyles.KEY_ACTION && props.selected}
      $buttonStyle={props.buttonStyle}
      as={renderAs}
      role={props.role}
    >
      {props.leadingIcon && <props.leadingIcon color={iconColor} size={iconSize} />}
      <span>{props.children}</span>
      {props.trailingIcon && <props.trailingIcon color={iconColor} size={iconSize} />}
    </StyledButton>
  );
});

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  /** force button disabled state */
  disabled: PropTypes.bool,
  /** do not use with href, to, or url */
  onClick: PropTypes.func,
  /* Shown before children inside the button */
  leadingIcon: PropTypes.func,
  /* Shown after children inside the button */
  trailingIcon: PropTypes.func,
  /** do not use with href, to, or url */
  type: PropTypes.string,
  /** the href to use. May be renamed to href in the future if legacy compatibility is removed */
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ pathname: PropTypes.string, search: PropTypes.string }),
  ]),
  /* Filled/Outline button style. If not supplied, then the button is an outline button. */
  buttonStyle: PropTypes.oneOf(Object.values(ButtonStyles)),
  /** Only available for key actions */
  selected: PropTypes.bool,
  /** How the link should be treated for navigation and other best practices purposes */
  linkType: PropTypes.oneOf(Object.values(LinkTypes)),
  /** Aria role */
  role: PropTypes.string,
};

Button.defaultProps = {
  className: "",
  disabled: false,
  selected: false,
  onClick: _.noop,
  leadingIcon: null,
  trailingIcon: null,
  type: "button",
  url: null,
  buttonStyle: ButtonStyles.OUTLINE,
};

Button.displayName = "Button";

export default Button;
export { ButtonStyles };
