import _ from "lodash";
import PropTypes from "prop-types";

import useEndDate from "./hooks/useEndDate";

import useFetch from "../../api/useFetch";
import { API_ENDPOINTS } from "../../api/constants";
import { ORDER_FLOW_TIME_FRAME_MONTHLY } from "../../utils/constants";
import LoadingSpinner from "../../utils/LoadingSpinner";

import VolumeGraph from "./graph-components/VolumeGraph";

/**
 * Renders the "Trading Price" graphs in Company Profile.
 */
const VolumeTradingGraphView = (props) => {
  // volume graph always uses monthly data
  const monthlyOrderFlowQueryInfo = useFetch(
    `${API_ENDPOINTS.COMPANY_VOLUME_TRADING_DATA(props.companyId, {
      time_frame: ORDER_FLOW_TIME_FRAME_MONTHLY,
    })}?time_frame=${ORDER_FLOW_TIME_FRAME_MONTHLY}`
  );

  const volumeEndDate = useEndDate(ORDER_FLOW_TIME_FRAME_MONTHLY);

  if (monthlyOrderFlowQueryInfo.isLoading) {
    return <LoadingSpinner />;
  }

  const monthlyOrderFlowData = monthlyOrderFlowQueryInfo.cleanedData?.data;

  const totalVolume = _.map(monthlyOrderFlowData, (d) => ({
    date: d.report_period,
    value: d.total_volume,
  }));
  const bidVolume = _.map(monthlyOrderFlowData, (d) => ({
    date: d.report_period,
    value: d.bid_volume,
  }));
  const offerVolume = _.map(monthlyOrderFlowData, (d) => ({
    date: d.report_period,
    value: d.ask_volume,
  }));

  return (
    <VolumeGraph
      hasDataAccess={props.hasDataAccess}
      endDate={volumeEndDate}
      totalVolume={totalVolume}
      bidVolume={bidVolume}
      offerVolume={offerVolume}
    />
  );
};

VolumeTradingGraphView.propTypes = {
  companyId: PropTypes.string.isRequired,
  hasDataAccess: PropTypes.bool,
};

VolumeTradingGraphView.defaultProps = {
  hasDataAccess: false,
};

export default VolumeTradingGraphView;
