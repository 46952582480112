import PropTypes from "prop-types";
import styled from "styled-components";
import { FontColors } from "yuka";

import { getCounterStyleByValue } from "./graphUtils";

import { StyledCounter } from "./StyledComponents";

const StyledTypography50 = styled.div`
  display: flex;
  white-space: nowrap;
  gap: 12px;
  align-items: center;
  ${FontColors.theme50}
`;

const RobustnessScoreLegendItem = ({ value }) => {
  const { backgroundColor, color } = getCounterStyleByValue(value?.y);

  return (
    <StyledTypography50>
      ZXData Robustness Score
      <StyledCounter $opacity={1} $background={backgroundColor} $color={color}>
        {value?.y || "--"} / 10
      </StyledCounter>
    </StyledTypography50>
  );
};

RobustnessScoreLegendItem.propTypes = {
  value: PropTypes.shape({
    y: PropTypes.number,
  }),
};

RobustnessScoreLegendItem.defaultProps = {
  value: null,
};

export default RobustnessScoreLegendItem;
