import React from "react";
import { StyledCaption1 } from "yuka";
import styled from "styled-components";

import ProfileInfo from "./ProfileInfo";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

/**
 * Footer for the global navigation.
 *
 * @returns {React.Element}
 */
const StyledCaptionWrapper = styled.div`
  padding: 10px 0 6px 0;
`;

const Footer = () => (
  <StyledContainer>
    <ProfileInfo />
    <StyledCaptionWrapper>
      <StyledCaption1>© 2023 Zanbato, Inc. All Rights Reserved.</StyledCaption1>
    </StyledCaptionWrapper>
  </StyledContainer>
);

export default Footer;
