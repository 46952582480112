import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, ACTIONS } from "../routes/StateProvider";

import AxiosInstance from "./AxiosInstance";

/**
 * Wrapper around `useMutation` for DELETE request
 * https://tanstack.com/query/v4/docs/reference/useMutation
 *
 * @param {string} url
 * @returns {Array} see useMutation documentation for return values.
 */
const useDelete = (url, config = {}) => {
  const dispatch = useDispatch();
  const apiUrl = `${url}`;
  const queryClient = useQueryClient();

  // Delete request is a detail request
  // To make the useDelete hook more generic on initialization, id is passed to the mutationFn instead of the hook
  // Whether that's the best practice is questionable
  const mutationFn = useCallback(
    ({ id }) => AxiosInstance.delete(`${apiUrl}${id}/`),
    [apiUrl]
  );

  const mutation = useMutation({
    mutationFn,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [url] });

      if (!config?.silent) {
        if (config?.message) {
          dispatch({ type: ACTIONS.addToast, message: config.message });
        } else {
          dispatch({ type: ACTIONS.addToast, message: "Successfully deleted" });
        }
      }
    },
    onError: () => {
      if (!config?.silent) {
        dispatch({
          type: ACTIONS.addToast,
          message: "Update failed, please check your information and try again",
        });
      }
    },
  });

  return mutation;
};

export default useDelete;
