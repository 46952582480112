import styled from "styled-components";
import { body1, FontColors } from "yuka";

const PlaceholderContainer = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
`;

const PlaceholderText = styled.div`
  ${body1}
  ${FontColors.theme50}
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 55px);
`;

export default () => {
  return (
    <PlaceholderContainer>
      <PlaceholderText>No data available</PlaceholderText>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1087 250"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_735_59312"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1087"
          height="250"
        >
          <rect
            width="1087"
            height="250"
            fill="url(#paint0_radial_735_59312)"
          />
        </mask>
        <g mask="url(#mask0_735_59312)">
          <path
            d="M0 50H1087"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M0 0H1087"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M0 100H1087"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M0 150H1087"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M0 200H1087"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M0 250H1087"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M56 0L56 249"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M153 0L153 249"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M250 0L250 249"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M347 0L347 249"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M444 0L444 249"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M541 0L541 249"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M638 0L638 249"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M735 0L735 249"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M832 0L832 249"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M929 0L929 249"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
          <path
            d="M1026 0L1026 249"
            stroke="white"
            strokeOpacity="0.5"
            strokeDasharray="1 4"
          />
        </g>
        <defs>
          <radialGradient
            id="paint0_radial_735_59312"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(543.5 125) rotate(90) scale(125 543.5)"
          >
            <stop stopColor="white" stopOpacity="0.8" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </PlaceholderContainer>
  );
};
