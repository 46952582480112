import _ from "lodash";
import React, { useContext } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {
  caption2,
  FontColors,
  HyperLink,
  LinkStyles,
  LinkTypes,
  LogOutIcon,
  Orientations,
  UserCircleIcon,
  StyledBody1,
  StyledCaption1,
  PageSection,
} from "yuka";

import { useAnchoredMenu } from "../hdYuka";

import { AuthContext } from "../auth";
import { ROUTING_PATH } from "../routes/constants";
import { fullDisplayName } from "../user/utils";
import UserAvatar from "../utils/UserAvatar";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const StyledProfileMenu = styled.div`
  ${caption2}
  ${FontColors.theme30}
  padding: 0 12px;
`;

const StyledProfileCard = styled(PageSection)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  border-radius: 8px;
  padding: 12px;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
`;

const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

/**
 * Renders a card with the users profile information that has an anchored menu.
 *
 * @returns {React.Element}
 */

const ProfileInfo = () => {
  const { user, logout } = useContext(AuthContext);

  const menuItems = _.compact([
    {
      url: ROUTING_PATH.ACCOUNT(),
      icon: UserCircleIcon,
      linkType: LinkTypes.LOCAL_LINK,
      onClick: () => MixpanelEvents.viewAccountSettings(),
      text: "Account",
    },
    {
      url: ROUTING_PATH.LOGIN(),
      text: "Log Out",
      icon: LogOutIcon,
      onClick: logout,
    },
  ]);

  const profileDropdown = (
    <StyledProfileMenu>
      <HyperLink
        onClick={() => MixpanelEvents.openReportABug()}
        url="mailto:support@zanbato.com"
        linkStyle={LinkStyles.SUPPORT}
      >
        Report a Bug
      </HyperLink>
      {" | "}
      <HyperLink
        onClick={() => MixpanelEvents.openTermsOfUse()}
        url={ROUTING_PATH.AGREEMENT("tou")}
        linkStyle={LinkStyles.SUPPORT}
        linkType={LinkTypes.EXTERNAL_LINK}
      >
        Terms of Use
      </HyperLink>
      {" | "}
      <HyperLink
        onClick={() => MixpanelEvents.openPrivacyPolicy()}
        url={ROUTING_PATH.AGREEMENT("privacy")}
        linkStyle={LinkStyles.SUPPORT}
        linkType={LinkTypes.EXTERNAL_LINK}
      >
        Privacy Policy
      </HyperLink>
      {" | "}
      <HyperLink
        onClick={() => MixpanelEvents.openNDA()}
        url={ROUTING_PATH.AGREEMENT("nda")}
        linkStyle={LinkStyles.SUPPORT}
        linkType={LinkTypes.EXTERNAL_LINK}
      >
        NDA
      </HyperLink>
    </StyledProfileMenu>
  );

  const [dropdownElement, targetRef, toggleDisplay] = useAnchoredMenu({
    id: "UserProfileMenu",
    sectioned: true,
    orientation: Orientations.HORIZONTAL,
    menuItems: [
      { menuItems },
      { nonMenuItemNode: profileDropdown, menuItems: [] },
    ],
  });

  const profileInfoTarget = (
    <div ref={targetRef} onClick={toggleDisplay}>
      <StyledProfileCard>
        {user && <UserAvatar user={user} />}
        <StyledTextWrapper>
          <StyledBody1>{user ? fullDisplayName(user) : ""}</StyledBody1>
          <StyledCaption1>{user?.email}</StyledCaption1>
        </StyledTextWrapper>
      </StyledProfileCard>
    </div>
  );

  return (
    <React.Fragment>
      {profileInfoTarget}
      {ReactDOM.createPortal(dropdownElement, document.body)}
    </React.Fragment>
  );
};

export default ProfileInfo;
