import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { body2, ColorPalette, YukaColorPalette } from "yuka";

const StyledCompanySearchButton = styled.button`
  border-radius: 12px;
  height: 48px;
  width: 320px;
  background-color: ${(props) =>
    props.$selected ? YukaColorPalette.surface2 : YukaColorPalette.surface1};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${YukaColorPalette.surface2};
  }
`;

const StyledLeadingInfo = styled.span`
  display: flex;
  align-items: center;
`;

const StyledTitleWithoutIcon = styled.span`
  color: ${ColorPalette.white30};
  margin-left: 16px;
  ${body2}
`;

const StyledTitleWithIcon = styled.span`
  color: ${ColorPalette.white100};
  margin-left: 12px;
  ${body2}
`;

const StyledChevronIcon = styled.span`
  padding: 9px;
`;

const CompanySearchButton = (props) => (
  <StyledCompanySearchButton
    ref={props.ref}
    onClick={props.onClick}
    $selected={props.selected}
  >
    <StyledLeadingInfo>
      {props.leadingIcon && props.leadingIcon}
      {props.title &&
        (props.leadingIcon ? (
          <StyledTitleWithIcon>{props.title}</StyledTitleWithIcon>
        ) : (
          <StyledTitleWithoutIcon>{props.title}</StyledTitleWithoutIcon>
        ))}
    </StyledLeadingInfo>

    <StyledChevronIcon>
      {props.trailingIcon && props.trailingIcon}
    </StyledChevronIcon>
  </StyledCompanySearchButton>
);

CompanySearchButton.propTypes = {
  leadingIcon: PropTypes.element,
  title: PropTypes.string.isRequired,
  trailingIcon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  ref: PropTypes.object,
  dropdownElement: PropTypes.node.isRequired,
  selected: PropTypes.bool,
};

CompanySearchButton.defaultProps = {
  selected: false,
  ref: null,
};

export default CompanySearchButton;
