import React from "react";
import styled from "styled-components";

import { FontColors, caption2, Modal, ModalStyles, ColorPalette } from "yuka";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const StyledDisclosureText = styled.div`
  ${caption2};
  ${FontColors.theme50};
  margin: 24px 12px 0;
  padding: 24px 0 12px;
  border-top: 1px solid ${ColorPalette.white10};
`;

const StyledInlineLink = styled.span`
  text-decoration: underline;
  ${FontColors.theme30};

  &:hover {
    cursor: pointer;
  }
`;

const StyledModalContent = styled.div`
  width: 720px;
`;

/**
 * This renders the contents of a dismissable disclosure modal for the data product.
 *
 * @returns {React.Element}
 */
const DisclosureModal = () => {
  const [modal, setModal] = React.useState(null);

  const closeModal = React.useCallback(() => setModal(null), []);

  const openModal = React.useCallback(() => {
    MixpanelEvents.openTooltip("ZXData Disclosures");
    const modalComp = (
      <Modal
        onClose={closeModal}
        cancelText="Close"
        title="Disclosures"
        modalStyle={ModalStyles.SCROLLABLE}
        height="530"
      >
        <StyledModalContent>
          <p>
            This report is derived from order flow data collected on (i) closed
            trades on ZX and reported through ZXData partners, (ii) bids/asks
            submitted for display on ZX, and (iii) orders submitted to ZX market
            operators as “dark” orders (together, the “ZXData”). Valuations for
            certain thinly traded securities may, in certain circumstances, be
            largely driven by a single data input, such as a broker quote.
          </p>
          <p>
            <strong>Robustness Score:</strong> ZXData Robustness score is
            Zanbato’s proprietary approach to objectively assign confidence
            levels to the underlying data used in constructing ZXData output
            metrics.
          </p>
          <p>
            The ZXData Robustness Score is calculated on a scale of 1 to 10 for
            each company, based on the average of the following metrics:
            <ul>
              <li>
                Number of executed trades, either executed on ZX or submitted by
                third party data partner
              </li>
              <li>Number buy and sell orders submitted to ZX</li>
              <li>Skewness of the order book</li>
            </ul>
            In general, the higher the number of executed trades and submitted
            orders, the higher the Robustness Score. Conversely, the lower the
            skew in the order book the higher the Robustness Score, in other
            words companies that have more asymmetric order books skewed to one
            side or the other will generally provide less reliable price
            signals.
          </p>
          <p>
            To be included in ZXData, a company must score 3 or above:
            <ul>
              <li>
                A score of 3-4 typically implies a company has 3-5 orders on
                each side of the market or a more asymmetric orderbook with 5+
                orders. Most companies scoring 3 or 4 do not have a recently
                executed trade in the data set
              </li>
              <li>
                A company with a score of 5 will typically have at least one
                executed trade or a more balanced orderbook with no recently
                executed trades.
              </li>
              <li>
                A company with a score of 6 or greater usually includes at least
                one executed trade in the data set, with the edge cases
                reflecting a deep orderbook of 10+ orders on both sides of the
                market.
              </li>
              <li>
                Companies receiving scores of 7 and above will typically reflect
                a deep orderbook as well as multiple executed trades.
              </li>
            </ul>
            The highest ZXData Robustness score that any given company currently
            has is an 8.
          </p>
          <p>
            <strong>ZX Index Value:</strong> ZX Index Value is an internally
            developed metric that reflects secondary prices for private
            companies using an algorithmic approach based on the prices sourced
            from ZX and ZXData partners. The algorithm used to calculate ZX
            Index Values incorporates both executed transaction prices and
            current bid and ask pricing. As such, ZX Index Value provides a
            better representation of current market conditions versus closed
            prices given there is usually a 30–45-day lag between the time that
            a transaction gets matched and the time when it is fully executed.
            This has translated to a more successful prediction of clearing
            price – in 80% of the cases analyzed, ZX Index Value is predictive
            of executed prices within a 10% range.
          </p>
          <p>
            The algorithm is developed by incorporating metrics such as executed
            prices, inside market prices, and size of transactions. Executed
            transactions are consistently given the highest weighting in the ZX
            Index Value, followed by non-executed prices. Inside market prices
            see higher weightings than bids and offers further down the
            orderbook, as they consider current market conditions.
            Institutionally sized transactions are weighted above smaller
            transactions in the ZX Index Value. In addition, half-lives are
            calculated for all ZX Index Value inputs. Empirically, time decays
            typically see a substantial drop-off after two weeks.
          </p>
          <p>
            <strong>Relative Activity:</strong> Relative % for the subject
            company vs. all other active companies(represented as a percentage
            ranking). “Ranking Total Interest” is based on total volume;
            “Ranking # of Tickets” is based on number of tickets submitted; and
            “Company Views” is based on number of views.
          </p>
          <p>
            <strong>Volume in mm (3-month aggregate):</strong> “Total Interest”
            is based on total amount of open interest (bids and asks); “Bid
            Interest” is based on total number of bids; “Ask Interest” is based
            on total number of offers to sell; “% Direct vs. SPV” reflects the %
            of total offers to sell (Ask Interest) from direct investment vs.
            from special purpose vehicles.
          </p>
          <p>
            <strong>Price: Overall.</strong> The “ZX Index Value” is derived by
            applying proprietary weightings to the order flow data collected for
            the stated period (both bids and asks), based on the order type
            (firm order or indication of interest) and whether the order has
            been executed, with greater weight given to more recent orders.
            “Dollar-Weighted Average” is the sum product of volume and price for
            all buy and sell orders. “Simple Average” is the simple average of
            the prices of all buy and sell orders.
          </p>
          <p>
            <strong>Price: Bid/Ask.</strong> Bid prices are based on offers to
            buy; Ask prices are based on orders to sell. “Highest Bid” is the
            highest live bid at the end of the month; “Lowest Ask” is the lowest
            live ask at the end of the month. “Period Best Bid” is the highest
            bid during the 3-month period; “Period Best Ask” is the highest ask
            during the 3-month period. “Dollar-Weighted Average” is based on the
            sum product of volume and price for buy orders and sell orders,
            respectively, during the stated period. “Simple Average” is based on
            the simple average of the price of all buy orders and sell orders,
            respectively, during the stated period.
          </p>
          <p>
            <strong>Mark-to-Market Holding Price</strong> is derived from market
            participants’ public filings on their mark to market holding prices
            for private companies, as well as other data.
          </p>
          <p>
            <strong>Primary Financing Rounds</strong> represents historical view
            of primary financing rounds based on Certification of Incorporation.
          </p>
          <p>
            <strong>
              This report is provided for informational purposes only and does
              not constitute a recommendation or solicitation to buy or sell any
              financial instrument or to participate in any trading strategy and
              should not be construed as such. The values shown in this report
              are not indicative price quotations. This report does not
              constitute investment advice, nor does this report constitute a
              "research report" as defined by FINRA Rule 2241 or a "debt
              research report" as defined by FINRA Rule 2242.
            </strong>
          </p>
          <p>
            <strong>
              The ZXData and the information contained in this report, some of
              which has been obtained from third party sources, is provided on
              an "as is" basis and the user of this information assumes the
              entire risk of any use made of this information. Neither Zanbato
              Inc. (“Zanbato”) nor its affiliates guarantee the accuracy or
              completeness of the data contained in this report, or its
              computation and compilation.
            </strong>
          </p>
          <p>
            <strong>
              The ZXData and this report constitute Zanbato’s valuable
              intellectual property. You are expressly prohibited from copying,
              modifying, transmitting or redistributing the ZXData or this
              report without Zanbato’s prior written consent.
            </strong>
          </p>
        </StyledModalContent>
      </Modal>
    );

    setModal(modalComp);
  }, [closeModal]);

  return (
    <React.Fragment>
      <StyledDisclosureText>
        All ZXData graphs are based on 3 month rolling data.&nbsp;
        <StyledInlineLink onClick={openModal}>
          Click here for important Disclosure information.
        </StyledInlineLink>
      </StyledDisclosureText>
      {modal}
    </React.Fragment>
  );
};

export default DisclosureModal;
