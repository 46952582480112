import styled from "styled-components";

const GraphContainer = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: auto;
  gap: 16px;
  flex-direction: column;
`;

const GraphSection = styled.div`
  width: 100%;
  overflow-y: auto;
`;

export { GraphContainer, GraphSection };
