import _ from "lodash";
import { useMemo } from "react";
import { useQueries } from "react-query";
import queryString from "query-string";

import AxiosInstance from "./AxiosInstance";
import { cleanJsonApiData, defaultQueryOptions } from "./utils";

/**
 * Wrapper around `useQueries` to execute multiple queries in parallel. Basically is used like
 * a `useFetch` that takes an array of the params. The key difference is the returned object will
 * contain `cleanedData`, which will be an array of the cleaned results, and `queryInfo`, which will
 * be an array of query information objects. The object will also contain information about the
 * full set of queries, such as `isLoading` and `isError`, which are logically true based on all
 * of the underlying queries.
 *
 * @param {Array} queries - Array of query objects. Each query object should have the following
 *  properties: `url`, `queryParams`, `options`. See `useFetch` for more details about how each
 *  will be applied.
 * @param {object} globalOptions - Metadata options for using this hook. These options are NOT
 *  forwarded on to the individual queries.
 */
const useFetches = (
  queries = [],
  globalOptions = {
    flattenResults: false,
  }
) => {
  const queryStrings = queries.map(({ queryParams }) =>
    _.isEmpty(queryParams) ? "" : `?${queryString.stringify(queryParams)}`
  );
  const apiURLs = queries.map(
    ({ url }, index) => `${url}${queryStrings[index]}`
  );
  const queryOptions = queries.map(({ options }) => ({
    ...options,
    ...defaultQueryOptions,
  }));

  const queryFns = useMemo(
    () =>
      apiURLs.map(
        (apiUrl) => () =>
          AxiosInstance.get(apiUrl).then((response) => response.data)
      ),
    [apiURLs]
  );

  const queryInfo = useQueries(
    queries.map((query, index) => ({
      queryKey: [query.url, query.queryParams],
      queryFn: queryFns[index],
      ...queryOptions[index],
    }))
  );

  const cleanedData = useMemo(() => {
    const mappedCleanData = queryInfo.map((queryResult) => {
      // JSON API data
      if (queryResult.data?.data) {
        return cleanJsonApiData(queryResult.data);
      }
      // HD API data
      return queryResult.data;
    });

    if (!globalOptions.flattenResults) {
      return mappedCleanData;
    }
    return {
      data: _.flatten(mappedCleanData.map((data) => data?.data || [])),
      relationships: _.merge(
        {},
        ...mappedCleanData.map((data) => data?.relationships || {})
      ),
      // page is discarded here.
    };
  }, [queryInfo, globalOptions.flattenResults]);

  return useMemo(
    () => ({
      queryInfo,
      cleanedData,
      isLoading: queryInfo.some((query) => query.isLoading),
      isFetching: queryInfo.some((query) => query.isFetching),
      isError: queryInfo.some((query) => query.isError),
      isIdle: queryInfo.every((query) => query.isIdle),
      isAnySuccess: queryInfo.some((query) => query.isSuccess),
      isSuccess: queryInfo.every((query) => query.isSuccess),
    }),
    [queryInfo, cleanedData]
  );
};

export default useFetches;
