import React from "react";
import styled from "styled-components";
import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { PageSection, YukaThemeProvider } from "yuka";
import PropTypes from "prop-types";

import { ROUTING_PATH } from "./constants";

import AccountSettings from "../account";
import ChangeEmailConfirm from "../account/ChangeEmailConfirm";
import CompanyView from "../company";
import CompanyList from "../company-list";
import DocView from "../agreements/DocView";
import GlobalHeader from "../global-header";
import GlobalNav from "../global-nav";
import Login from "../login";
import LoginBase from "../login/LoginBase";
import MarketActivity from "../market-activity";
import NewUserSetPasswordForm from "../login/NewUserSetPasswordForm";
import ResetPasswordConfirm from "../login/reset-password/ResetPasswordConfirm";
import ResetPasswordForm from "../login/reset-password/ResetPasswordForm";
import ResetPasswordRequest from "../login/reset-password/ResetPasswordRequest";
import useHasFeatureAccess from "../company/hooks/useHasFeatureAccess";
import LoadingSpinner from "../utils/LoadingSpinner";
import Insights from "../insights";
import Superchart from "../superchart";
import ToastList from "../toast/ToastList";
import BannerList from "../banner/BannerList";

const StyledArticleContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  padding: 16px;
  min-height: 0;
`;

const StyledPageSection = styled(PageSection)`
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;

const FeatureProtectedRoute = (props) => {
  const [hasFeatureAccess, featureAccessLoading] = useHasFeatureAccess(
    props.featureName
  );

  if (featureAccessLoading) {
    return <LoadingSpinner />;
  }

  return (
    <React.Fragment>
      {hasFeatureAccess ? <Outlet /> : <Navigate to={ROUTING_PATH.LOGIN()} />}
    </React.Fragment>
  );
};

FeatureProtectedRoute.propTypes = {
  featureName: PropTypes.string.isRequired,
};

const MainRoutes = () => (
  <YukaThemeProvider theme={{ componentVersion: 2, useTangeloTable: false }}>
    <BannerList />
    <Routes>
      <Route
        path={ROUTING_PATH.LOGIN()}
        element={
          <LoginBase>
            <Login />
          </LoginBase>
        }
      />
      <Route
        path={ROUTING_PATH.RESET_PASSWORD()}
        element={
          <LoginBase>
            <ResetPasswordRequest />
          </LoginBase>
        }
      />
      <Route
        path={ROUTING_PATH.RESET_PASSWORD_CONFIRM()}
        element={
          <LoginBase>
            <ResetPasswordConfirm />
          </LoginBase>
        }
      />
      <Route
        path={`${ROUTING_PATH.SET_PASSWORD_BASE()}:token`}
        element={
          <LoginBase>
            <ResetPasswordForm />
          </LoginBase>
        }
      />
      <Route
        path={`${ROUTING_PATH.SET_PASSWORD_NEW_USER()}:token`}
        element={
          <LoginBase>
            <NewUserSetPasswordForm />
          </LoginBase>
        }
      />
      <Route
        path={`${ROUTING_PATH.ACCOUNT_CHANGE_EMAIL_CONFIRM()}:token`}
        element={
          <LoginBase>
            <ChangeEmailConfirm />
          </LoginBase>
        }
      />
      <Route
        path={ROUTING_PATH.ACCOUNT()}
        element={
          <React.Fragment>
            <StyledPageSection>
              <GlobalNav />
              <StyledArticleContent>
                <StyledContentContainer>
                  <AccountSettings />
                </StyledContentContainer>
              </StyledArticleContent>
            </StyledPageSection>
          </React.Fragment>
        }
      />
      <Route
        path={ROUTING_PATH.MARKET_ACTIVITY()}
        element={
          <StyledPageSection>
            <GlobalNav />
            <StyledArticleContent>
              <GlobalHeader />
              <StyledContentContainer>
                <MarketActivity />
              </StyledContentContainer>
            </StyledArticleContent>
          </StyledPageSection>
        }
      />
      <Route
        path={ROUTING_PATH.COMPANIES()}
        element={
          <StyledPageSection>
            <GlobalNav />
            <StyledArticleContent>
              <GlobalHeader />
              <StyledContentContainer>
                <CompanyList />
              </StyledContentContainer>
            </StyledArticleContent>
          </StyledPageSection>
        }
      />
      <Route
        path="/company/:id"
        exact
        element={
          <StyledPageSection>
            <GlobalNav />
            <StyledArticleContent>
              <GlobalHeader />
              <StyledContentContainer>
                <CompanyView />
              </StyledContentContainer>
            </StyledArticleContent>
          </StyledPageSection>
        }
      />
      <Route path={`${ROUTING_PATH.AGREEMENT()}`} element={<DocView />} />
      <Route path={`${ROUTING_PATH.AGREEMENTS()}`} element={<DocView />} />
      <Route path={`${ROUTING_PATH.AGREEMENTS()}:type`} element={<DocView />} />
      <Route
        path={ROUTING_PATH.INSIGHTS()}
        element={
          <StyledPageSection>
            <GlobalNav />
            <StyledArticleContent>
              <GlobalHeader />
              <StyledContentContainer>
                <Insights />
              </StyledContentContainer>
            </StyledArticleContent>
          </StyledPageSection>
        }
      />
      <Route
        path={`${ROUTING_PATH.SUPERCHARTS()}:id?`}
        element={
          <StyledPageSection>
            <GlobalNav />
            <StyledArticleContent>
              <GlobalHeader />
              <StyledContentContainer>
                <Superchart />
              </StyledContentContainer>
            </StyledArticleContent>
          </StyledPageSection>
        }
      />
      <Route
        path="*"
        element={<Navigate to={ROUTING_PATH.COMPANIES()} replace />}
      />
    </Routes>
    <ToastList />
  </YukaThemeProvider>
);

export default MainRoutes;
