import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import SetNewPassword from "./SetNewPassword";

import AxiosInstance from "../api/AxiosInstance";
import { API_ENDPOINTS } from "../api/constants";
import { AuthContext } from "../auth";

const NewUserSetPasswordForm = () => {
  const params = useParams();
  const token = params.token;
  const { authenticate } = useContext(AuthContext);

  const onSubmit = async (values) => {
    if (values.password !== values.confirm_password) {
      return {
        // The view for resetting password uses a library that doesn't have support for
        // password confirmation, so we do it in the frontend.
        confirm_password: "Passwords do not match",
      };
    }
    return AxiosInstance.post(
      API_ENDPOINTS.RESET_PASSWORD_NEW_USER(),
      JSON.stringify({
        accept_tou: values.accept_tou,
        accept_privacy: values.accept_privacy,
        accept_nda: values.accept_nda,
        password: values.password,
        token,
      })
    )
      .then(({ data }) => {
        authenticate(data, true);
      })
      .catch(({ response: { data } }) => {
        let errorMsg = data.error || "Unknown error occurred";
        if (data.password) {
          errorMsg = data.password.join(" ");
        } else if (data.detail) {
          errorMsg = "Expired or invalid URL.";
        }
        return {
          confirm_password: errorMsg,
        };
      });
  };

  return <SetNewPassword onSubmit={onSubmit} includeAgreements />;
};

export default NewUserSetPasswordForm;
