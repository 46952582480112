import { useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import { GraphSection } from "./StyledComponents";
import useEndDate from "./hooks/useEndDate";

import useFetch from "../../api/useFetch";
import { API_ENDPOINTS } from "../../api/constants";
import { ORDER_FLOW_TIME_FRAME_MONTHLY } from "../../utils/constants";
import LoadingSpinner from "../../utils/LoadingSpinner";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";

import BidOfferRatioGraph from "./graph-components/BidOfferRatioGraph";

/**
 * Renders the "Bid Offer Ratio" graph in Company Profile.
 */
const BidOfferRatioGraphView = (props) => {
  const orderFlowQueryInfo = useFetch(
    `${API_ENDPOINTS.COMPANY_BID_OFFER_RATIO_DATA(props.companyId, {
      time_frame: ORDER_FLOW_TIME_FRAME_MONTHLY,
    })}?time_frame=${ORDER_FLOW_TIME_FRAME_MONTHLY}`
  );

  useEffect(() => {
    if (props.companyName) {
      MixpanelEvents.viewCompanyBidAskRatioGraph(props.companyName);
    }
  }, [props.companyName]);

  const endDate = useEndDate(ORDER_FLOW_TIME_FRAME_MONTHLY);

  if (orderFlowQueryInfo.isLoading) {
    return <LoadingSpinner />;
  }

  const orderFlowData = orderFlowQueryInfo.cleanedData?.data;

  const percentBidByVolume = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.percent_bid_by_volume,
  }));
  const percentBidByCount = _.map(orderFlowData, (d) => ({
    date: d.report_period,
    value: d.percent_bid_by_tickets,
  }));

  return (
    <GraphSection>
      <BidOfferRatioGraph
        hasDataAccess={true}
        endDate={endDate}
        percentBidByVolume={percentBidByVolume}
        percentBidByCount={percentBidByCount}
      />
    </GraphSection>
  );
};

BidOfferRatioGraphView.propTypes = {
  companyId: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  hasDataAccess: PropTypes.bool,
};

BidOfferRatioGraphView.defaultProps = {
  hasDataAccess: false,
};

export default BidOfferRatioGraphView;
