import PropTypes from "prop-types";
import _ from "lodash";

import { getCounterStyleByValue } from "./graphUtils";
import { FULL_OPACITY, HALF_OPACITY } from "./constants";
import { StyledCounter } from "./StyledComponents";

const ZXRobustnessScoreLabel = (props) => {
  const { crosshairValue, value } = props;
  const { x, y } = value || { x: null, y: null };
  const { backgroundColor, color } = getCounterStyleByValue(y);
  const opacity =
    x && crosshairValue && crosshairValue.x === x ? FULL_OPACITY : HALF_OPACITY;

  return (
    <foreignObject
      style={{
        height: 16,
        width: 25,
        transform: "translate(-12.5px, 16px)",
        overflow: "visible",
      }}
    >
      <StyledCounter
        $opacity={opacity}
        $color={color}
        $background={backgroundColor}
      >
        {_.isNull(y) ? "--" : y}
      </StyledCounter>
    </foreignObject>
  );
};

ZXRobustnessScoreLabel.propTypes = {
  crosshairValue: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  value: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
};

ZXRobustnessScoreLabel.defaultProps = {
  crosshairValue: null,
};

export default ZXRobustnessScoreLabel;
