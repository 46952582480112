import React from "react";
import styled from "styled-components";

import { ColorPalette, Fonts, ActionChip } from "yuka";
import {
  VALUATION_COMPONENT_FUNDING_ROUND,
  VALUATION_COMPONENT_OTHER,
  VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON,
  VALUATION_COMPONENT_REPORTED_MARKS,
  VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING,
} from "../constants";
import PropTypes from "prop-types";
import LastSecondaryPriceField from "./LastSecondaryPriceField";
import LastFundingRoundField from "./LastFundingRoundField";
import IndexPerformanceField from "./IndexPerformanceField";
import CustomComponentField from "./CustomComponentField";
import { currentValueFieldName } from "../utils/valuationCalculatorFormNameUtils";
import { useForm, useFormState } from "react-final-form";
import FundMarksField from "./FundMarksField";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";
import { useComponent } from "./useComponents";
import { COMPONENT_TYPE_MAP } from "./constants";

const StyledInformationPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 16px;
  background: ${ColorPalette.white10};
  border-left: 1px solid ${ColorPalette.white05};

  > :first-child {
    // A little extra space for the title.
    margin-bottom: 8px;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ComponentRowDetailPanel = ({
  companyName,
  companyId,
  editable,
  removeComponent,
}) => {
  const { change } = useForm();
  const { values } = useFormState();
  const componentId = values.selected_row;

  const { name, componentType, initialValue, currentValue } = useComponent(
    values,
    componentId
  );

  if (!componentId) {
    return null;
  }

  const resetValue = () => {
    // Shared helper function that some of the children will use.
    if (currentValue !== initialValue) {
      MixpanelEvents.restoreDefaultComponentValue(
        companyName,
        name,
        COMPONENT_TYPE_MAP[componentType]
      );
    }
    change(currentValueFieldName(componentId), initialValue);
  };

  const label =
    componentType === VALUATION_COMPONENT_OTHER ? "Custom criterion" : name;

  return (
    <StyledInformationPanel>
      <StyledHeader>
        <Fonts.Headline1theme80>{label}</Fonts.Headline1theme80>
        {editable && componentType === VALUATION_COMPONENT_OTHER && (
          <ActionChip
            onClick={() => removeComponent(componentId)}
            text="Remove"
          />
        )}
      </StyledHeader>
      {componentType === VALUATION_COMPONENT_ZX_INDEX_VALUE_TRAILING && (
        <LastSecondaryPriceField
          companyName={companyName}
          companyId={companyId}
          editable={editable}
          resetValue={resetValue}
          componentId={componentId}
        />
      )}
      {componentType === VALUATION_COMPONENT_FUNDING_ROUND && (
        <LastFundingRoundField
          companyName={companyName}
          companyId={companyId}
          editable={editable}
          resetValue={resetValue}
          componentId={componentId}
        />
      )}
      {componentType === VALUATION_COMPONENT_REPORTED_MARKS && (
        <FundMarksField
          companyId={companyId}
          editable={editable}
          resetValue={resetValue}
          componentId={componentId}
        />
      )}
      {/*
      IndexPerformanceField must be rendered despite potentially not matching the "currently
      selected component" because we need it to perform some form-values-populating logic for values
      used downstream. We need those values to be present in the form despite not having clicked on
      this row. This component returns null and renders nothing if it's not the selected component.
      */}
      <IndexPerformanceField
        visible={
          componentType === VALUATION_COMPONENT_PRIVATE_MARKET_INDEX_COMPARISON
        }
        companyName={companyName}
        companyId={companyId}
        editable={editable}
        componentId={componentId}
      />
      {componentType === VALUATION_COMPONENT_OTHER && (
        <CustomComponentField
          companyName={companyName}
          componentId={componentId}
          editable={editable}
        />
      )}
    </StyledInformationPanel>
  );
};

ComponentRowDetailPanel.propTypes = {
  companyName: PropTypes.string,
  companyId: PropTypes.string.isRequired,
  removeComponent: PropTypes.func.isRequired,
  editable: PropTypes.bool,
};

export default ComponentRowDetailPanel;
