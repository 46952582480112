import { DateTime } from "luxon";
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  List,
  ListItem,
  ListStyles,
  LinkExternalIcon,
  BuildingIcon,
  AnnouncementIcon,
  GlobeIcon,
  PrimaryColorPalette,
  HyperLink,
  LinkTypes,
  ColorPalette,
} from "yuka";

const StyledList = styled(List)`
  height: auto;
`;

const StyledHyperLink = styled(HyperLink)`
  color: ${PrimaryColorPalette.blue400};
  padding-top: 16px;
  padding-left: 16px;
  text-align: left;
  font-size: 14px;
`;

const CompanyDocumentsList = (props) => {
  const getLeadingIcon = useCallback((documentType) => {
    const documentTypes = {
      company: BuildingIcon,
      market: GlobeIcon,
      interview: AnnouncementIcon,
    };
    if (documentType in documentTypes) {
      return documentTypes[documentType];
    }
    return null;
  }, []);

  const documents = props.showAll
    ? props.documents
    : props.documents.slice(0, 3);

  return (
    <StyledList divider>
      {documents.map((document) => (
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          url={document.link}
          linkType={LinkTypes.EXTERNAL_LINK}
          key={document.id}
          leadingIcon={getLeadingIcon(document.type)}
          text={document.title}
          subtext={`Published: ${DateTime.fromISO(document.date).toFormat(
            "D"
          )}`}
          trailingContent={<LinkExternalIcon color={ColorPalette.white50} />}
          onClick={() => {}}
        />
      ))}

      {!props.showAll && props.documents.length > 3 && (
        <StyledHyperLink
          onClick={() => {
            props.setShowAll(true);
          }}
        >
          Load other {props.documents.length - 3} mentions
        </StyledHyperLink>
      )}
    </StyledList>
  );
};

CompanyDocumentsList.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      link: PropTypes.string,
      relation: PropTypes.string,
      date: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  showAll: PropTypes.bool.isRequired,
  setShowAll: PropTypes.func,
};

export default CompanyDocumentsList;
