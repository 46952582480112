import PropTypes from "prop-types";
import styled from "styled-components";

import CompanyDetailCard from "./CompanyDetailCard";
import CompanyFundingRoundsCard from "./CompanyFundingRoundsCard";
import { companyProfileBreakpoint } from "./constants";
import CompanyDocumentsCard from "./documents/CompanyDocumentsCard";
import OverallTradingPriceGraphView from "./graphs/OverallTradingPriceGraphView";
import {
  COMPANY_TRANSFERABILITY_FEATURE_NAME,
  CUSTOM_VALUATION_CALCULATOR_FEATURE_NAME,
  EV_CALCULATOR_FEATURE_NAME,
  SACRA_FEATURE_NAME,
} from "../api/constants";
import useHasFeatureAccess from "./hooks/useHasFeatureAccess";
import useCompanyFetch from "./utils/useCompanyFetch";

import {
  ORDER_FLOW_TIME_FRAME_MONTHLY,
  ORDER_FLOW_TIME_FRAME_WEEKLY,
} from "../utils/constants";
import PostMoneyCalculator from "./PostMoneyCalculator";
import CompanyTransferabilityCard from "./CompanyTransferabilityCard";
import useWindowDimensions from "../utils/useWindowDimensions";
import ValuationStats from "./ValuationStats";

const StyledCompanyProfileContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-y: auto;
  flex-direction: row-reverse;
  gap: 16px;

  @media ${`only screen and (max-width : ${companyProfileBreakpoint}px)`} {
    flex-direction: column;
    justify-content: start;
  }
`;

const StyledDetailsSection = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 330px;

  @media ${`only screen and (max-width : ${companyProfileBreakpoint}px)`} {
    width: 100%;
    padding: 0;
  }
`;

const StyledChartSection = styled.div`
  width: 100%;
  display: grid;
  row-gap: 16px;
  grid-auto-rows: minmax(min-content, max-content); // fit-content(100%);
  height: fit-content;

  @media ${`only screen and (max-width : ${companyProfileBreakpoint}px)`} {
    width: 100%;
    padding: 0;
  }
`;

const CompanyProfile = (props) => {
  const { width: windowWidth } = useWindowDimensions();
  const companyQuery = useCompanyFetch(props.companyId);

  const company = companyQuery?.cleanedData?.data;
  const [sacraDocumentsFeatureEnabled] =
    useHasFeatureAccess(SACRA_FEATURE_NAME);
  const [customValuationCalculatorFeatureEnabled] = useHasFeatureAccess(
    CUSTOM_VALUATION_CALCULATOR_FEATURE_NAME
  );
  const [evCalculatorFeatureAccess] = useHasFeatureAccess(
    EV_CALCULATOR_FEATURE_NAME
  );
  const [companyTransferabilityFeatureAccess] = useHasFeatureAccess(
    COMPANY_TRANSFERABILITY_FEATURE_NAME
  );

  return (
    <StyledCompanyProfileContainer>
      <StyledDetailsSection>
        {customValuationCalculatorFeatureEnabled &&
          windowWidth <= companyProfileBreakpoint && (
            <ValuationStats companyId={props.companyId} />
          )}
        {evCalculatorFeatureAccess && (
          <PostMoneyCalculator companyId={props.companyId} />
        )}
        {companyTransferabilityFeatureAccess && (
          <CompanyTransferabilityCard companyId={props.companyId} />
        )}
        <CompanyDetailCard companyId={props.companyId} />
      </StyledDetailsSection>
      <StyledChartSection>
        {customValuationCalculatorFeatureEnabled &&
          windowWidth > companyProfileBreakpoint && (
            <ValuationStats companyId={props.companyId} />
          )}
        {company?.show_order_flow_data && (
          <OverallTradingPriceGraphView
            includeRobustnessScoreAxis
            companyId={props.companyId}
            hasDataAccess={props.canViewPriceAndVolumeGraph}
            showDollarWeightedAverage={false}
            showSimpleAverage={false}
            timeFrame={props.graphTimeFrame}
          />
        )}
        {sacraDocumentsFeatureEnabled &&
          company?.show_sacra_documents &&
          props.hasDocumentAccess && (
            <CompanyDocumentsCard
              companyId={props.companyId}
              companyName={company?.name} // this is passed here to avoid a duplicate API call
            />
          )}
        <CompanyFundingRoundsCard companyId={props.companyId} />
      </StyledChartSection>
    </StyledCompanyProfileContainer>
  );
};

CompanyProfile.propTypes = {
  canViewPriceAndVolumeGraph: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
  graphTimeFrame: PropTypes.oneOf([
    ORDER_FLOW_TIME_FRAME_MONTHLY,
    ORDER_FLOW_TIME_FRAME_WEEKLY,
  ]),
  hasDocumentAccess: PropTypes.bool.isRequired,
};

CompanyProfile.defaultProps = {
  company: null,
  graphTimeFrame: null,
};

export default CompanyProfile;
