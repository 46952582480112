import { Card, CardStyles } from "yuka";
import React, { useState } from "react";

import {
  StyledLoginHeaderContainer,
  StyledLoginHeader,
  StyledLoginSubHeader,
} from "./StyledComponents";

import LoginForm from "./LoginForm";
import TokenForm from "./TokenForm";

const Login = () => {
  const [credentials, setCredentials] = useState(null);

  return (
    <React.Fragment>
      <StyledLoginHeaderContainer>
        <StyledLoginHeader>Welcome</StyledLoginHeader>
        <StyledLoginSubHeader>Log in to your account</StyledLoginSubHeader>
      </StyledLoginHeaderContainer>
      <Card cardStyle={CardStyles.PADDED}>
        {credentials ? (
          <TokenForm
            credentials={credentials}
            setCredentials={setCredentials}
          />
        ) : (
          <LoginForm setCredentials={setCredentials} />
        )}
      </Card>
    </React.Fragment>
  );
};

export default Login;
