import styled from "styled-components";
import { elevation5Raised, FontColors, GraphCard, caption2 } from "yuka";

import { CARD_HEIGHT, NO_DATA_GRAPH_CARD_HEIGHT } from "./constants";

const GraphContainer = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: auto;
  gap: 16px;
  flex-direction: column;
`;

const CardBodyGraphContainer = styled.div`
  height: 410px;
  cursor: pointer;
`;

const CrosshairTooltip = styled.div`
  ${elevation5Raised};
  border-radius: 4px;
  margin: 12px;
  padding: 12px;
  white-space: nowrap;
  width: 280px;
`;

const CrosshairListItemName = styled.span`
  ${caption2};
  ${FontColors.light};
`;

const CrosshairListValue = styled.span`
  ${caption2};
  ${FontColors.brightest};
`;

const CrosshairListItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ReportPeriodWrapper = styled.span`
  font-size: 12px;
`;

const StyledCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${caption2}
  white-space: nowrap;
  color: ${(props) => props.$color};
  background: ${(props) => props.$background};
  opacity: ${(props) => props.$opacity};
  border-radius: 8px;
  padding: 0 6px;
  height: 16px;
`;

const StyledGraphCard = styled(GraphCard)`
  svg:not(:root) {
    overflow: visible;
  }
  flex-shrink: 0;
  height: ${(props) =>
    props.$isEmpty ? NO_DATA_GRAPH_CARD_HEIGHT : CARD_HEIGHT}px;
`;

const StyledGraphHeader = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTableContainer = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`;

const StyledTypography50 = styled.span`
  ${FontColors.theme50};
`;

export {
  CardBodyGraphContainer,
  CrosshairTooltip,
  CrosshairListItemName,
  CrosshairListValue,
  CrosshairListItem,
  GraphContainer,
  ReportPeriodWrapper,
  StyledCounter,
  StyledGraphCard,
  StyledGraphHeader,
  StyledTableContainer,
  StyledTypography50,
};
