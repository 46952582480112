import useFetch from "../../api/useFetch";
import { API_ENDPOINTS } from "../../api/constants";

const useHasFeatureAccess = (featureName) => {
  const hasFeatureAccessQuery = useFetch(API_ENDPOINTS.HAS_FEATURE_ACCESS());
  const hasFeatureAccessQueryInfo = hasFeatureAccessQuery?.cleanedData;
  const featureAccessLoading = hasFeatureAccessQuery?.isLoading;

  return [
    hasFeatureAccessQueryInfo && hasFeatureAccessQueryInfo[featureName],
    featureAccessLoading,
  ];
};

export default useHasFeatureAccess;
