import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FilterChip, SearchIcon, StarIcon } from "yuka";
import { Input } from "../hdYuka";
import useDebouncedState from "../utils/useDebouncedState";
import CompanyDropdownList from "./dropdown-lists/CompanyDropdownList";
import PortfolioList from "./dropdown-lists/PortfolioList";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const StyledMenu = styled.div`
  width: 500px;
  max-height: 480px;
  margin-bottom: -16px;
`;

const StyledInput = styled(Input)`
  margin: 0 16px;
`;

const StyledChipWrapper = styled.div`
  display: flex;
  margin: 16px;
`;

const PRIVATE_COMPANIES_FILTER = "Private Companies";
const WATCHLIST_FILTER = "Watchlist";
const SAVED_PROFILE_FILTER = "Saved Profiles";

const CompanySearchDropdown = ({ toggleDropdown }) => {
  const [selectedFilter, setSelectedFilter] = useState(
    PRIVATE_COMPANIES_FILTER
  );

  const [searchQuery, setSearchQuery] = useDebouncedState("");

  const changeHandler = useCallback(
    (event) => {
      setSearchQuery.cancel();
      setSearchQuery(event.target.value);
    },
    [setSearchQuery]
  );

  useEffect(() => {
    MixpanelEvents.superChartFilterKeyCompanySearch(searchQuery, {
      filter_tab: selectedFilter,
      "saved profiles": selectedFilter === SAVED_PROFILE_FILTER,
    });
  }, [selectedFilter, searchQuery]);

  return (
    <StyledMenu>
      <StyledInput
        placeholder="Search"
        leadingIcon={SearchIcon}
        onChange={changeHandler}
        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
      />
      <StyledChipWrapper>
        <FilterChip
          text={PRIVATE_COMPANIES_FILTER}
          onClick={() => {
            setSelectedFilter(PRIVATE_COMPANIES_FILTER);
          }}
          selected={selectedFilter === PRIVATE_COMPANIES_FILTER}
        />
        <FilterChip
          text={WATCHLIST_FILTER}
          onClick={() => {
            setSelectedFilter(WATCHLIST_FILTER);
          }}
          selected={selectedFilter === WATCHLIST_FILTER}
        />
        <FilterChip
          leadingIcon={StarIcon}
          text={SAVED_PROFILE_FILTER}
          onClick={() => {
            setSelectedFilter(SAVED_PROFILE_FILTER);
          }}
          selected={selectedFilter === SAVED_PROFILE_FILTER}
        />
      </StyledChipWrapper>
      {selectedFilter === SAVED_PROFILE_FILTER ? (
        <PortfolioList
          searchQuery={searchQuery}
          toggleDropdown={toggleDropdown}
        />
      ) : (
        <CompanyDropdownList
          searchQuery={searchQuery}
          toggleDropdown={toggleDropdown}
          watchlist={selectedFilter === WATCHLIST_FILTER}
        />
      )}
    </StyledMenu>
  );
};
CompanySearchDropdown.propTypes = {
  toggleDropdown: PropTypes.func.isRequired,
};

export default CompanySearchDropdown;
