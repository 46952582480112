import _ from "lodash";
import PropTypes from "prop-types";

import CompanyProfileLineGraph from "./CompanyProfileLineGraph";

import {
  GRAPH_COLORS,
  TIME_FRAME_MONTHLY,
  TIME_FRAME_WEEKLY,
} from "./constants";

/**
 * Renders a Trading Price graph showing:
 *   1. Ask Dollar-Weighted Average
 *   2. Ask Simple Average
 *   3. Bid Dollar-Weighted Average
 *   4. Bid Simple Average
 */
const BidAskPriceGraph = (props) => (
  <CompanyProfileLineGraph
    title={props.title}
    dataSeriesArray={_.compact([
      {
        name: "Bid ZX Index Value",
        color: GRAPH_COLORS.TRADING_GREEN,
        tooltip:
          "Derived by applying proprietary weightings to the bid order flow data",
        graphData: props.zxIndexBuyValue,
      },
      {
        name: "Bid Price Dollar-Weighted Avg.",
        color: GRAPH_COLORS.GREEN_400,
        tooltip: "Sum product of volume and price across all bids",
        graphData: props.bidDollarWeightedAverage,
      },
      {
        name: "Bid Price Simple Avg.",
        color: GRAPH_COLORS.GREEN_300,
        tooltip: "Simple average of the price across all bids",
        graphData: props.bidSimpleAverage,
      },
      {
        name: "Ask ZX Index Value",
        color: GRAPH_COLORS.TRADING_RED,
        tooltip:
          "Derived by applying proprietary weightings to the ask order flow data",
        graphData: props.zxIndexSellValue,
      },
      {
        name: "Ask Price Dollar-Weighted Avg.",
        color: GRAPH_COLORS.RED_400,
        tooltip: "Sum product of volume and price across all bids",
        graphData: props.askDollarWeightedAverage,
      },
      {
        name: "Ask Price Simple Avg.",
        color: GRAPH_COLORS.RED_300,
        tooltip: "Simple average of the price across all bids",
        graphData: props.askSimpleAverage,
      },
    ])}
    includeRobustnessScoreAxis={props.includeRobustnessScoreAxis}
    legendHeadline="Price"
    onNearestX={props.onNearestX}
    robustnessScore={props.robustnessScore}
    timeFrame={props.timeFrame}
    endDate={props.endDate}
    hasDataAccess={props.hasDataAccess}
  />
);

BidAskPriceGraph.propTypes = {
  endDate: PropTypes.string.isRequired,
  onNearestX: PropTypes.func,
  timeFrame: PropTypes.oneOf([TIME_FRAME_MONTHLY, TIME_FRAME_WEEKLY]),
  hasDataAccess: PropTypes.bool,
  includeRobustnessScoreAxis: PropTypes.bool,
  askDollarWeightedAverage: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
    )
  ).isRequired,
  askSimpleAverage: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
    )
  ).isRequired,
  bidDollarWeightedAverage: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
    )
  ).isRequired,
  bidSimpleAverage: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
    )
  ).isRequired,
  zxIndexBuyValue: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
    )
  ).isRequired,
  zxIndexSellValue: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
    )
  ).isRequired,
  robustnessScore: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.number, date: PropTypes.string })
    )
  ).isRequired,
  title: PropTypes.string.isRequired,
};

BidAskPriceGraph.defaultProps = {
  hasDataAccess: false,
  includeRobustnessScoreAxis: false,
  onNearestX: _.noop,
  timeFrame: TIME_FRAME_MONTHLY,
};

export default BidAskPriceGraph;
