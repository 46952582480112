import { useCallback } from "react";
import PropTypes from "prop-types";
import { ColorPalette } from "yuka";

import { ZX_COMPANY_EXCHANGE, KEY_COMPANY_COLOR } from "../constants";

import useChartReferencePoint from "./useChartReferencePoint";
import {
  StyledLegendItem,
  LegendMarker,
  StyledPriceDisplay,
  StyledLegendValue,
  StyledRobustnessScore,
} from "./StyledComponents";

import { shortMoneyFormat, percentFormat } from "/src/utils/displayFormatUtils";

/**
 * An element of SuperchartLegend; displays relevant data for the key company on the superchart.
 */
const KeyCompanyLegendItem = (props) => {
  const { name, data } = props.chartElement;
  const referencePoint = useChartReferencePoint(props.hoverDate, data);

  const formatPrice = useCallback(
    (data) => {
      if (!data.length) return "";

      const changeAbsolute = (
        referencePoint.absolute - data[0].absolute
      ).toFixed(2);
      const changePercent = referencePoint.percent.toFixed(2);
      return (
        <StyledPriceDisplay>
          <span>{shortMoneyFormat(referencePoint.absolute)}</span>
          <span>
            {changeAbsolute >= 0 ? "+" : "-"}
            {shortMoneyFormat(Math.abs(changeAbsolute))}
          </span>
          <span>
            ({changePercent > 0 ? "+" : ""}
            {percentFormat(changePercent)})
          </span>
        </StyledPriceDisplay>
      );
    },
    [referencePoint, data]
  );

  const formatRobustness = useCallback(() => {
    return `${referencePoint?.robustness || "--"}/10`;
  }, [referencePoint]);

  return (
    <StyledLegendItem keyCompany>
      <LegendMarker $color={KEY_COMPANY_COLOR} />
      <StyledLegendValue $color={ColorPalette.white50}>
        {ZX_COMPANY_EXCHANGE}:&nbsp;
      </StyledLegendValue>
      <StyledLegendValue $color={ColorPalette.white80}>
        {name}
      </StyledLegendValue>
      &nbsp;
      <StyledLegendValue $color={KEY_COMPANY_COLOR}>
        {formatPrice(data)}
      </StyledLegendValue>
      <StyledRobustnessScore>
        <StyledLegendValue $color={ColorPalette.white50}>
          with Robustness Score&nbsp;
        </StyledLegendValue>
        <StyledLegendValue $color={ColorPalette.white80}>
          {formatRobustness(data)}
        </StyledLegendValue>
      </StyledRobustnessScore>
    </StyledLegendItem>
  );
};

KeyCompanyLegendItem.propTypes = {
  chartElement: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  hoverDate: PropTypes.string,
};

KeyCompanyLegendItem.defaultProps = {
  hoverDate: "",
};

export default KeyCompanyLegendItem;
