import {
  FlexibleXYPlot,
  HorizontalGridLines,
  VerticalGridLines,
  VerticalBarSeries,
  XAxis,
  YAxis,
  Crosshair,
  MarkSeries,
} from "react-vis";
import { DateTime } from "luxon";
import React, { useCallback, useState, useMemo } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { ColorPalette } from "yuka";
import "react-vis/dist/style.css";

import { shortMoneyFormat } from "/src/utils/displayFormatUtils";

import LegendItem from "./LegendItem";
import NoDataPlaceholder from "./NoDataPlaceholder";
import {
  StyledGraphCard,
  ReportPeriodWrapper,
  StyledGraphHeader,
  StyledTypography50,
} from "./StyledComponents";

import {
  AXIS_STYLE,
  FULL_OPACITY,
  GRIDLINE_STYLE,
  HALF_OPACITY,
  MAX_PADDING_FACTOR,
  TIME_FRAME_MONTHLY,
} from "./constants";
import { createGraphArray, isFilledGraphEmpty } from "./graphUtils";

/**
 * Renders a Volume bar chart graph showing bid, offer, and total volume.
 */
const VolumeGraph = (props) => {
  const [volumeCrosshairValue, setVolumeCrosshairValue] = useState(null);

  const getVolumeCrosshairValue = useCallback(
    (dataSeries) => {
      if (!volumeCrosshairValue) {
        return null;
      }
      const matchingVolumeValue = _.find(_.flatten(dataSeries), {
        x: volumeCrosshairValue.x,
      });
      return matchingVolumeValue
        ? shortMoneyFormat(matchingVolumeValue.y)
        : null;
    },
    [volumeCrosshairValue]
  );

  const bidVolumeGraphData = useMemo(
    () =>
      createGraphArray(
        props.bidVolume,
        TIME_FRAME_MONTHLY,
        props.endDate,
        props.hasDataAccess,
        true
      ),
    [props.bidVolume, props.endDate, props.hasDataAccess]
  );
  const offerVolumeGraphData = useMemo(
    () =>
      createGraphArray(
        props.offerVolume,
        TIME_FRAME_MONTHLY,
        props.endDate,
        props.hasDataAccess,
        true
      ),
    [props.offerVolume, props.endDate, props.hasDataAccess]
  );
  const totalVolumeGraphData = useMemo(
    () =>
      createGraphArray(
        props.totalVolume,
        TIME_FRAME_MONTHLY,
        props.endDate,
        props.hasDataAccess,
        true
      ),
    [props.totalVolume, props.endDate, props.hasDataAccess]
  );

  const totalVolumeHoverValue = useMemo(
    () => getVolumeCrosshairValue(totalVolumeGraphData),
    [totalVolumeGraphData, getVolumeCrosshairValue]
  );
  const bidVolumeHoverValue = useMemo(
    () => getVolumeCrosshairValue(bidVolumeGraphData),
    [bidVolumeGraphData, getVolumeCrosshairValue]
  );
  const offerVolumeHoverValue = useMemo(
    () => getVolumeCrosshairValue(offerVolumeGraphData),
    [offerVolumeGraphData, getVolumeCrosshairValue]
  );

  const { buys, sells, total } = useMemo(
    () => ({
      buys: _.map(bidVolumeGraphData, (point) => {
        const opacity =
          point.x === volumeCrosshairValue?.x ? FULL_OPACITY : HALF_OPACITY;
        return {
          ...point,
          opacity,
          color: ColorPalette.buy,
        };
      }),
      sells: _.map(offerVolumeGraphData, (point) => {
        const opacity =
          point.x === volumeCrosshairValue?.x ? FULL_OPACITY : HALF_OPACITY;
        return {
          ...point,
          opacity,
          color: ColorPalette.sell,
        };
      }),
      total: _.map(totalVolumeGraphData, (point) => {
        const opacity =
          point.x === volumeCrosshairValue?.x ? FULL_OPACITY : HALF_OPACITY;
        return {
          ...point,
          opacity,
          color: ColorPalette.blue500,
        };
      }),
    }),
    [
      volumeCrosshairValue,
      bidVolumeGraphData,
      offerVolumeGraphData,
      totalVolumeGraphData,
    ]
  );

  const maxVolume =
    _.max(_.concat(_.map(props.totalVolume, (dataPoint) => dataPoint.value))) *
    MAX_PADDING_FACTOR;
  const volumeBoundaryPoints = [
    { x: 1, y: 0 },
    { x: 12, y: maxVolume },
  ];

  const START_DATE = DateTime.fromISO(props.endDate).minus({ years: 1 });
  const HEIGHT = 400;
  const GRAPH_HEIGHT = HEIGHT - 40;

  const reportPeriod = volumeCrosshairValue
    ? START_DATE.plus({
        months: volumeCrosshairValue?.x,
      }).toLocaleString({
        month: "short",
        year: "2-digit",
      })
    : "--";

  return (
    <StyledGraphCard
      title={
        <StyledGraphHeader>
          <span>
            Volume (<StyledTypography50>3-month aggregate</StyledTypography50>)
          </span>
          <ReportPeriodWrapper>
            <StyledTypography50>Report Period: </StyledTypography50>
            {reportPeriod}
          </ReportPeriodWrapper>
        </StyledGraphHeader>
      }
      $isEmpty={isFilledGraphEmpty([
        props.bidVolume,
        props.offerVolume,
        props.totalVolume,
      ])}
      legend={[
        {
          text: (
            <LegendItem
              precision={0}
              text="Total"
              value={totalVolumeHoverValue}
            />
          ),
          color: ColorPalette.blue500,
          tooltip:
            "Total amount of open interest (bids and asks) across three months",
        },
        {
          text: (
            <LegendItem precision={0} text="Bid" value={bidVolumeHoverValue} />
          ),
          color: ColorPalette.buy,
          tooltip: "Total amount of bid interest across three months",
        },
        {
          text: (
            <LegendItem
              precision={0}
              text="Ask"
              value={offerVolumeHoverValue}
            />
          ),
          color: ColorPalette.sell,
          tooltip: "Total amount of ask interest across three months",
        },
      ]}
    >
      {isFilledGraphEmpty([
        bidVolumeGraphData,
        offerVolumeGraphData,
        totalVolumeGraphData,
      ]) ? (
        <NoDataPlaceholder height={`${GRAPH_HEIGHT}px`} />
      ) : (
        <FlexibleXYPlot
          margin={{ top: 0, bottom: 20, left: 0, right: 52 }}
          onMouseLeave={() => {
            setVolumeCrosshairValue(null);
          }}
        >
          {!_.isNull(volumeCrosshairValue) && (
            <Crosshair
              values={[volumeCrosshairValue]}
              style={{ line: { background: ColorPalette.white50 } }}
            >
              <React.Fragment />
            </Crosshair>
          )}
          <VerticalBarSeries
            colorType="literal"
            data={buys}
            barWidth={0.4}
            onNearestX={(value) => {
              if (value.y) {
                setVolumeCrosshairValue(value);
                props.onNearestX(value);
              }
            }}
          />
          <VerticalBarSeries colorType="literal" data={sells} barWidth={0.4} />
          <VerticalBarSeries colorType="literal" data={total} barWidth={0.4} />
          <MarkSeries data={volumeBoundaryPoints} size={0} />
          <HorizontalGridLines tickTotal={5} style={GRIDLINE_STYLE} />
          <VerticalGridLines tickTotal={12} style={GRIDLINE_STYLE} />
          <XAxis
            style={AXIS_STYLE}
            tickSize={0}
            tickTotal={12}
            tickFormat={(value) =>
              START_DATE.plus({ months: value }).toLocaleString({
                month: "short",
                year: "2-digit",
              })
            }
          />
          <YAxis
            orientation="right"
            hideLine
            tickSize={0}
            tickTotal={5}
            tickFormat={(value) => shortMoneyFormat(value, 0)}
            style={AXIS_STYLE}
          />
        </FlexibleXYPlot>
      )}
    </StyledGraphCard>
  );
};

VolumeGraph.propTypes = {
  hasDataAccess: PropTypes.bool,
  endDate: PropTypes.string.isRequired,
  onNearestX: PropTypes.func,
  totalVolume: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        value: PropTypes.number,
      })
    )
  ).isRequired,
  bidVolume: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        value: PropTypes.number,
      })
    )
  ).isRequired,
  offerVolume: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        value: PropTypes.number,
      })
    )
  ).isRequired,
};

VolumeGraph.defaultProps = {
  hasDataAccess: false,
  onNearestX: _.noop,
};

export default VolumeGraph;
