import PropTypes from "prop-types";
import React, { useCallback, useState, useMemo } from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import {
  Crosshair,
  FlexibleXYPlot,
  MarkSeries,
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  YAxis,
  LineSeries,
} from "react-vis";
import "react-vis/dist/style.css";
import {
  body1,
  caption2,
  ColorPalette,
  FontColors,
  GraphCard,
  HyperLink,
  Modal,
  YukaColorPalette,
} from "yuka";

import { shortMoneyFormat } from "/src/utils/displayFormatUtils";

import LegendItem from "./LegendItem";
import NoDataPlaceholder from "./NoDataPlaceholder";
import RobustnessScoreLegendItem from "./RobustnessScoreLegendItem";
import ZXRobustnessScoreLabel from "./ZXRobustnessScoreLabel";

import {
  AXIS_STYLE,
  GRIDLINE_STYLE,
  NO_DATA_GRAPH_CARD_HEIGHT,
  TIME_FRAME_MONTHLY,
  TIME_FRAME_WEEKLY,
} from "./constants";
import { getTimeFrameParams } from "./timeFrameUtils";
import {
  createGraphArray,
  getMonthlyHoverPointsArray,
  getRobustnessTickValue,
  getWeeklyHoverPointsArray,
  isSplitGraphEmpty,
  splitZeroSeparator,
} from "./graphUtils";

import {
  ReportPeriodWrapper,
  StyledGraphHeader,
  StyledTypography50,
} from "./StyledComponents";

const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  ${caption2}
  ${FontColors.theme50}
`;

const StyledModal = styled(Modal)`
  width: 800px;
  ${body1}
`;

const LineGraphContainer = styled.div`
  height: ${(props) => props.$height}px;
`;

// Slightly different than the StyledGraphCard in StyledComponents.jsx
// because of the extra X-axis line
const LineGraphStyledGraphCard = styled(GraphCard)`
  flex-shrink: 0;
  ${(props) =>
    props.$isEmpty
      ? css`
          height: ${NO_DATA_GRAPH_CARD_HEIGHT}px;
        `
      : ""}

  svg:not(:root) {
    overflow: visible;
  }
`;

/**
 * Renders a price line graph for a given orderflow dataset.
 * Each entry in `props.dataSeriesArray` will result in one line in the graph.
 */
const CompanyProfileLineGraph = (props) => {
  const [crosshairValue, setCrosshairValue] = useState(null);
  const [robustnessModalIsShowing, setRobustnessModalIsShowing] =
    useState(false);

  const RobustnessScoreModal = () => (
    <StyledModal
      title="Robustness Score"
      onClose={() => setRobustnessModalIsShowing(false)}
      cancelText="Close"
    >
      <p>
        ZXData Robustness score is Zanbato's proprietary approach to objectively
        assign confidence levels to underlying data in constructing ZXData
        output metrics.
      </p>
      <p>
        The ZXData Robustness Score is calculated on a scale of 1 to 10 for each
        company, based on the average of the following metrics:
        <ul>
          <li>
            Number of executed trades, either executed on ZX or submitted by
            third party data partners
          </li>
          <li>Number buy and sell orders submitted to ZX</li>
          <li>Skewness of the order book</li>
        </ul>
      </p>
      <p>
        In general, the higher the number of executed trades and submitting
        orders, the higher the Robustness Score. Conversely, the lower the skew
        of the order book the higher the Robustness Score, in other words
        companies that have more asymmetric order books skewed to one side or
        the other will generally provide less reliable price signals.
      </p>
      <p>
        To be included in ZXData, a company must score 3 or above:
        <ul>
          <li>
            A score of 3-4 typically implies a company has 3-5 orders on each
            side of the market or a more asymmetric orderbook with 5+ orders.
            Most companies scoring 3 or 4 do not have a recently executed trade
            in the data set.
          </li>
          <li>
            A company with a score of 5 will typically have at least one
            executed trade or a more balanced orderbook with no recent executed
            trades.
          </li>
          <li>
            A company with a score of 6 or greater usually includes at least one
            executed trade in the data set, with the edge cases reflecting a
            deep orderbook of 10+ orders on both sides of the market.
          </li>
          <li>
            Companies receiving scores of 7 and above will typically reflect a
            deep orderbook as well as multiple executed trades.
          </li>
        </ul>
      </p>
      <p>
        The highest ZXData Robustness score that any given company currently has
        is a 9.
      </p>
    </StyledModal>
  );

  const getHoverValue = useCallback(
    (dataSeries) => _.find(_.flatten(dataSeries), { x: crosshairValue?.x }),
    [crosshairValue]
  );

  const formatHoverValue = (hoverValue) =>
    hoverValue ? shortMoneyFormat(hoverValue) : null;

  // create graph data for each entry in props.dataSeriesArray
  const processedData = _.map(props.dataSeriesArray, (dataSeries) => ({
    ...dataSeries,
    data: splitZeroSeparator(
      createGraphArray(
        dataSeries.graphData,
        props.timeFrame,
        props.endDate,
        props.hasDataAccess,
        props.isInMillions
      )
    ),
    color: dataSeries.color,
  }));
  const processedRobustnessScore = useMemo(
    () =>
      createGraphArray(
        props.robustnessScore,
        props.timeFrame,
        props.endDate,
        props.hasDataAccess,
        false
      ),
    [props.robustnessScore, props.timeFrame, props.endDate, props.hasDataAccess]
  );

  // if all data arrays are empty, display empty placeholder
  const isEmpty = isSplitGraphEmpty(_.map(processedData, "data"));

  // construct boundary points
  const MAX_PADDING_FACTOR = 1.05;
  const MIN_PADDING_FACTOR = 0.95;
  const maxValue = useMemo(
    () =>
      _.max(
        _.flatten(
          _.map(props.dataSeriesArray, (dataSeries) =>
            _.map(dataSeries.graphData, (dataPoint) =>
              _.toNumber(dataPoint.value)
            )
          )
        )
      ) * MAX_PADDING_FACTOR,
    [props.dataSeriesArray]
  );
  const minValue = useMemo(
    () =>
      _.min(
        _.flatten(
          _.map(props.dataSeriesArray, (dataSeries) =>
            _.map(dataSeries.graphData, (dataPoint) =>
              _.toNumber(dataPoint.value)
            )
          )
        )
      ) * MIN_PADDING_FACTOR,
    [props.dataSeriesArray]
  );
  const timeFrameParams = getTimeFrameParams(props.timeFrame, props.endDate);
  const boundaryPoints = [
    { x: timeFrameParams.minBoundaryXPoint, y: minValue },
    { x: timeFrameParams.maxBoundaryXPoint, y: maxValue },
  ];

  // used to ensure graph x-axis range is always 12 months even if
  // `props.percentBidByCount`/`props.percentBidByVolume` only contain 3 months worth of data
  // and to set crosshair values based on hovered region
  const hoverPointsArray =
    props.timeFrame === TIME_FRAME_WEEKLY
      ? getWeeklyHoverPointsArray(props.endDate, minValue)
      : getMonthlyHoverPointsArray(props.endDate, minValue);

  const selectedDate = useMemo(() => {
    return (
      crosshairValue &&
      timeFrameParams.startDate.plus({
        days: 2,
        weeks: crosshairValue.x,
      })
    );
  }, [crosshairValue, timeFrameParams]);

  const legendValues = useMemo(
    () => [
      ..._.map(processedData, (datum) => ({
        ...datum,
        text: (
          <LegendItem
            text={datum.name}
            value={formatHoverValue(getHoverValue(datum.data)?.y)}
          />
        ),
      })),
      {
        text: (
          <RobustnessScoreLegendItem
            value={getRobustnessTickValue(
              processedRobustnessScore,
              crosshairValue?.x
            )}
          />
        ),
        align: "right",
      },
    ],
    [processedData, processedRobustnessScore, getHoverValue, crosshairValue]
  );

  const GRAPH_BOTTOM_MARGIN_WITHOUT_ROBUSTNESS_AXIS = 24;
  const GRAPH_BOTTOM_MARGIN_WITH_ROBUSTNESS_AXIS = 70;

  const reportPeriod = selectedDate
    ? selectedDate.toLocaleString({
     month: "numeric",
     day: "numeric",
     year: "2-digit",
   })
    : "--";

  return (
    <LineGraphStyledGraphCard
      title={
        <StyledGraphHeader>
          <span>{props.title}</span>
          <ReportPeriodWrapper>
            <StyledTypography50>Report Period: </StyledTypography50>
            {reportPeriod}
          </ReportPeriodWrapper>
        </StyledGraphHeader>
      }
      legend={legendValues}
      $isEmpty={isEmpty}
    >
      {isEmpty ? (
        <NoDataPlaceholder />
      ) : (
        <React.Fragment>
          <LineGraphContainer $height={props.height}>
            <FlexibleXYPlot
              margin={{
                top: 0,
                bottom: props.includeRobustnessScoreAxis
                  ? GRAPH_BOTTOM_MARGIN_WITH_ROBUSTNESS_AXIS
                  : GRAPH_BOTTOM_MARGIN_WITHOUT_ROBUSTNESS_AXIS,
                left: 0,
                right: 52,
              }}
              onMouseLeave={() => {
                setCrosshairValue(null);
              }}
            >
              <MarkSeries data={boundaryPoints} size={0} />
              {!_.isNull(crosshairValue) && (
                <Crosshair
                  values={[crosshairValue]}
                  style={{ line: { background: ColorPalette.white50 } }}
                >
                  <React.Fragment />
                </Crosshair>
              )}
              <LineSeries
                color="rgba(0, 0, 0, 0)"
                data={hoverPointsArray}
                onNearestX={(value) => {
                  setCrosshairValue(value);
                  props.onNearestX(value);
                }}
                strokeWidth={1}
              />
              {_.map(processedData, (dataSeries) =>
                _.map(dataSeries.data, (data) => (
                  <LineSeries
                    key={dataSeries.color}
                    color={dataSeries.color}
                    data={data}
                    strokeWidth={1}
                  />
                ))
              )}
              <HorizontalGridLines style={GRIDLINE_STYLE} tickTotal={5} />
              <VerticalGridLines
                style={GRIDLINE_STYLE}
                tickTotal={12}
                tickValues={timeFrameParams.tickValues}
              />
              <XAxis
                style={AXIS_STYLE}
                tickSize={0}
                tickTotal={14}
                tickValues={timeFrameParams.tickValues}
                tickFormat={(value) =>
                  props.timeFrame === TIME_FRAME_MONTHLY
                    ? timeFrameParams.startDate
                        .plus({ months: value })
                        .toLocaleString({
                          month: "short",
                          year: "2-digit",
                        })
                    : timeFrameParams.startDate
                        .startOf("week")
                        .plus({ weeks: value, days: 4 })
                        .toLocaleString()
                }
              />
              {props.includeRobustnessScoreAxis && (
                <XAxis
                  style={AXIS_STYLE}
                  marginTop={50}
                  tickSize={0}
                  tickTotal={14}
                  tickValues={timeFrameParams.tickValues}
                  tickFormat={(value) => (
                    <ZXRobustnessScoreLabel
                      crosshairValue={crosshairValue}
                      value={getRobustnessTickValue(
                        processedRobustnessScore,
                        value
                      )}
                    />
                  )}
                />
              )}
              <YAxis
                orientation="right"
                hideLine
                tickSize={0}
                tickTotal={5}
                tickFormat={(value) => shortMoneyFormat(value)}
                style={AXIS_STYLE}
              />
              {!_.isNull(crosshairValue) && (
                <MarkSeries
                  colorType="literal"
                  size={4}
                  data={_.compact(
                    _.map(processedData, (dataSeries) => {
                      const hoverValue = getHoverValue(dataSeries.data);
                      return !_.isUndefined(hoverValue)
                        ? { ...hoverValue, color: dataSeries.color }
                        : null;
                    })
                  )}
                  stroke={YukaColorPalette.surface1}
                />
              )}
            </FlexibleXYPlot>
          </LineGraphContainer>
          {robustnessModalIsShowing && <RobustnessScoreModal />}
          {props.includeRobustnessScoreAxis && (
            <StyledFooter>
              ZXData Robustness score&nbsp;
              <HyperLink onClick={() => setRobustnessModalIsShowing(true)}>
                What is this?
              </HyperLink>
            </StyledFooter>
          )}
        </React.Fragment>
      )}
    </LineGraphStyledGraphCard>
  );
};

CompanyProfileLineGraph.propTypes = {
  endDate: PropTypes.string.isRequired,
  timeFrame: PropTypes.oneOf([TIME_FRAME_MONTHLY, TIME_FRAME_WEEKLY]),
  onNearestX: PropTypes.func,
  hasDataAccess: PropTypes.bool,
  includeRobustnessScoreAxis: PropTypes.bool,
  isInMillions: PropTypes.bool,
  dataSeriesArray: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
      tooltip: PropTypes.string,
      graphData: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  robustnessScore: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        date: PropTypes.string,
      })
    )
  ).isRequired,
  legendHeadline: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

CompanyProfileLineGraph.defaultProps = {
  timeFrame: TIME_FRAME_MONTHLY,
  onNearestX: _.noop,
  hasDataAccess: false,
  height: 340,
  includeRobustnessScoreAxis: false,
  isInMillions: false,
};

export default CompanyProfileLineGraph;
