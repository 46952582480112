import React from "react";

import { HyperLink, InputRow, LinkTypes } from "yuka";
import { FinalFormField } from "../hdYuka";

import { ROUTING_PATH } from "../routes/constants";

/**
 * Fields for accepting agreements to use the platform
 *
 * @returns {React.Element} AcceptAgreementsFields
 */
const AcceptAgreementsFields = () => {
  const required = (value) => (value ? undefined : "Required");

  return (
    <React.Fragment>
      <InputRow>
        <FinalFormField
          id="accept_tou"
          name="accept_tou"
          type="checkbox"
          label={
            <React.Fragment>
              I agree to the&nbsp;
              <HyperLink
                url={ROUTING_PATH.AGREEMENT("tou")}
                linkType={LinkTypes.EXTERNAL_LINK}
              >
                Terms of Use
              </HyperLink>
            </React.Fragment>
          }
          validate={required}
        />
      </InputRow>
      <InputRow>
        <FinalFormField
          id="accept_privacy"
          name="accept_privacy"
          type="checkbox"
          label={
            <React.Fragment>
              I agree to the&nbsp;
              <HyperLink
                url={ROUTING_PATH.AGREEMENT("privacy")}
                linkType={LinkTypes.EXTERNAL_LINK}
              >
                Privacy Policy
              </HyperLink>
            </React.Fragment>
          }
          validate={required}
        />
      </InputRow>
      <InputRow>
        <FinalFormField
          id="accept_nda"
          name="accept_nda"
          type="checkbox"
          label={
            <React.Fragment>
              I agree to the&nbsp;
              <HyperLink
                url={ROUTING_PATH.AGREEMENT("nda")}
                linkType={LinkTypes.EXTERNAL_LINK}
              >
                Non-Disclosure Agreement
              </HyperLink>
            </React.Fragment>
          }
          validate={required}
        />
      </InputRow>
    </React.Fragment>
  );
};

export default AcceptAgreementsFields;
