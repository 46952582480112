import PropTypes from "prop-types";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { ROUTING_PATH } from "../routes/constants";

import {
  body1,
  StyledHeadline1,
  FontColors,
  YukaThemeProvider,
  Button,
  ButtonStyles,
  Card,
} from "yuka";

import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import useWrite from "../api/useWrite";
import LoadingSpinner from "../utils/LoadingSpinner";

import { PARAMS_DICT } from "./constants";

const StyledAgreementsPanel = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 24px 24px 0;
  width: 100%;
  > :first-child {
    margin-bottom: 12px;
  }
  ${body1};
  ${FontColors.theme500}
`;

const StyledIFrame = styled.iframe`
  border: 0;
  height: 100%;
  width: 100%;
`;

const StyledAgreementButton = styled(Button)`
  margin: 20px;
  width: 300px;
`;

const StyledContainer = styled.div`
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
`;

const StyledCard = styled(Card)`
  margin: 10px 0 20px 0;
  overflow: hidden;
  min-height: 150px;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

/**
 * Component used to render the content of an agreement.
 * requires the title and html content of the agreement to render
 *
 * @param {object} props
 * @returns {Element}
 */
const AgreementContentView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const agreement = useFetch(API_ENDPOINTS.AGREEMENT(""), {
    agreement_type: _.get(PARAMS_DICT, params.type),
  });
  const signAgreement = useWrite(API_ENDPOINTS.SIGN_AGREEMENT(), false, {
    silent: true,
  });
  if (agreement.isLoading) {
    return <LoadingSpinner />;
  }

  const agreement_data = _.head(agreement.cleanedData);
  const agreement_files = _.head(agreement_data?.agreement_files);
  if (_.isNil(agreement_files)) {
    return <span>File not found!</span>;
  }
  const agreement_url = agreement_files.agreement_doc;

  const handleAcceptAgreement = () => {
    signAgreement.mutate(
      { agreement_type: _.get(PARAMS_DICT, params.type) },
      {
        onSuccess: () => {
          navigate(ROUTING_PATH.LOGIN());
        },
      }
    );
  };

  return (
    <StyledAgreementsPanel className="elevation1">
      {!agreement_data.accepted && (
        <YukaThemeProvider theme={{ componentVersion: 2 }}>
          <StyledCard>
            <StyledContainer>
              <div>
                To proceed, please review the ZXData agreement and click&nbsp;
                <b>Accept Agreement</b>
              </div>
              <StyledButtonWrapper>
                <StyledAgreementButton
                  buttonStyle={ButtonStyles.CTA}
                  onClick={handleAcceptAgreement}
                >
                  Accept Agreement
                </StyledAgreementButton>
              </StyledButtonWrapper>
            </StyledContainer>
          </StyledCard>
        </YukaThemeProvider>
      )}
      <div>
        <StyledHeadline1>{agreement_data.title}</StyledHeadline1>
      </div>
      {agreement_files.agreement_doc && (
        <StyledIFrame src={agreement_url} title={agreement_data.title} />
      )}
    </StyledAgreementsPanel>
  );
};

AgreementContentView.propTypes = {
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
};

AgreementContentView.defaultProps = {
  url: "",
};

export default AgreementContentView;
