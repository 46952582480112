import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FontColors, StyledCaption2 } from "yuka";

const StyledLabel = styled(StyledCaption2)`
  ${FontColors.theme50};
`;

const StyledValue = styled(StyledCaption2)`
  ${FontColors.theme80};
`;

const CompanyHeaderStat = ({ label, currentValue }) => (
  <React.Fragment>
    <StyledLabel>{label}</StyledLabel>
    <StyledValue>{currentValue}</StyledValue>
  </React.Fragment>
);

CompanyHeaderStat.propTypes = {
  label: PropTypes.string.isRequired,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default CompanyHeaderStat;
