import PropTypes from "prop-types";
import React from "react";

/**
 * Clickable logo in the header that goes back to the company page.
 * Using a SVG instead of a png due to issues with the Parcel URL reference
 * leading to a `Cannot use 'import.meta' outside a module` error
 *
 * @returns {React.Element}
 */
const SiteLogo = ({ size }) => (
  <svg
    height={size}
    viewBox="0 0 219 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M67.5407 30.8399H84.6707V36.5999H60.0007V30.8399L76.6107 8.75988H61.3907V2.99988H84.1007V8.75988L67.5407 30.8399Z"
      fill="white"
    />
    <path
      d="M101.251 25.4797L108.751 36.5997H116.411L105.071 19.7997L101.251 25.4797Z"
      fill="white"
    />
    <path
      d="M98.8306 10.5498L93.7406 2.99976H86.0806L95.0006 16.2198L98.8306 10.5498Z"
      fill="white"
    />
    <path
      d="M93.7406 36.5998H86.0806L108.751 2.99976H116.411L93.7406 36.5998Z"
      fill="white"
    />
    <path
      d="M118.411 36.5998L124.931 2.99976H134.911C139.271 2.99976 142.751 4.26976 145.361 6.81976C147.971 9.35976 149.271 12.8798 149.271 17.3598C149.271 23.1798 147.331 27.8498 143.441 31.3498C139.551 34.8498 134.651 36.6098 128.731 36.6098H118.411V36.5998ZM121.581 34.0998H128.971C133.871 34.0998 138.001 32.5998 141.381 29.5898C144.761 26.5798 146.441 22.5498 146.441 17.4898C146.441 13.6798 145.361 10.7298 143.201 8.62976C141.041 6.52976 138.151 5.48976 134.541 5.48976H127.201L121.581 34.0998Z"
      fill="white"
    />
    <path
      d="M161.361 37.3197C158.351 37.3197 155.941 36.3597 154.111 34.4397C152.291 32.5197 151.371 29.9797 151.371 26.8097C151.371 23.0997 152.561 19.9897 154.951 17.4697C157.331 14.9597 160.301 13.6997 163.851 13.6997C166.121 13.6997 168.081 14.2297 169.731 15.2797C171.381 16.3297 172.521 17.8097 173.161 19.6997H173.261L174.271 14.4197H176.771L172.451 36.5997H169.951L170.771 32.5697H170.671C168.301 35.7397 165.201 37.3197 161.361 37.3197ZM162.181 34.9197C165.121 34.9197 167.581 33.8997 169.551 31.8497C171.521 29.7997 172.501 27.2597 172.501 24.2197C172.501 21.7597 171.741 19.7897 170.221 18.3197C168.701 16.8497 166.641 16.1097 164.051 16.1097C161.231 16.1097 158.861 17.1397 156.921 19.2097C154.981 21.2697 154.021 23.8297 154.021 26.8697C154.021 29.3297 154.761 31.2897 156.231 32.7497C157.701 34.2097 159.691 34.9297 162.181 34.9297V34.9197Z"
      fill="white"
    />
    <path
      d="M187.481 16.5398L184.741 30.5598C184.641 31.0998 184.601 31.5998 184.601 32.0498C184.601 32.7198 184.821 33.2698 185.251 33.6798C185.681 34.0998 186.301 34.2998 187.101 34.2998H188.491L188.061 36.6998H186.521C185.141 36.6998 184.061 36.3198 183.261 35.5498C182.461 34.7798 182.061 33.7398 182.061 32.4298C182.061 31.9198 182.121 31.3098 182.251 30.6098L184.991 16.5498H180.331L180.761 14.4398H185.371L187.101 5.55975H189.601L187.871 14.4398H193.151L192.721 16.5498H187.491L187.481 16.5398Z"
      fill="white"
    />
    <path
      d="M202.65 37.3197C199.64 37.3197 197.23 36.3597 195.4 34.4397C193.58 32.5197 192.66 29.9797 192.66 26.8097C192.66 23.0997 193.85 19.9897 196.24 17.4697C198.62 14.9597 201.59 13.6997 205.14 13.6997C207.41 13.6997 209.37 14.2297 211.02 15.2797C212.67 16.3297 213.81 17.8097 214.45 19.6997H214.55L215.56 14.4197H218.06L213.74 36.5997H211.24L212.06 32.5697H211.96C209.59 35.7397 206.49 37.3197 202.65 37.3197ZM203.47 34.9197C206.41 34.9197 208.87 33.8997 210.84 31.8497C212.81 29.7997 213.79 27.2597 213.79 24.2197C213.79 21.7597 213.03 19.7897 211.51 18.3197C209.99 16.8497 207.93 16.1097 205.34 16.1097C202.52 16.1097 200.15 17.1397 198.21 19.2097C196.27 21.2697 195.31 23.8297 195.31 26.8697C195.31 29.3297 196.05 31.2897 197.52 32.7497C198.99 34.2097 200.98 34.9297 203.47 34.9297V34.9197Z"
      fill="white"
    />
    <circle cx="6.66715" cy="6.66715" r="6.66667" fill="white" />
    <circle cx="33.3341" cy="33.3332" r="6.66667" fill="white" />
    <rect
      x="28.4858"
      y="2.08649"
      width="13.3333"
      height="38.6667"
      transform="rotate(45 28.4858 2.08649)"
      fill="#00E6F6"
    />
    <circle cx="6.66667" cy="33.3332" r="6.66667" fill="white" />
    <circle cx="33.3337" cy="6.66667" r="6.66667" fill="white" />
  </svg>
);

SiteLogo.propTypes = {
  size: PropTypes.number,
};

SiteLogo.defaultProps = {
  size: 20,
};

const SiteLogoDataverse = ({ size }) => (
  <svg
    width="123"
    height={size}
    viewBox="0 0 123 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M112.863 17.4041V2.49609H123V5.16122H115.766V8.32606H122.328V10.9912H115.766V14.739H123V17.4041H112.863Z"
      fill="white"
    />
    <path
      d="M104.311 17.7374C102.607 17.7374 101.236 17.3418 100.196 16.5506C99.1707 15.7594 98.4993 14.6073 98.1816 13.0943L101.106 12.4696C101.264 13.2608 101.611 13.8924 102.145 14.3644C102.694 14.8363 103.423 15.0723 104.333 15.0723C105.142 15.0723 105.791 14.9127 106.282 14.5934C106.773 14.2603 107.019 13.8091 107.019 13.24C107.019 12.8236 106.918 12.4835 106.715 12.2198C106.528 11.956 106.203 11.7201 105.741 11.5118C105.279 11.2898 104.643 11.0607 103.835 10.8247L102.708 10.4916C102.333 10.3806 101.921 10.2348 101.474 10.0544C101.026 9.86003 100.6 9.60323 100.196 9.28397C99.7916 8.96471 99.4595 8.56911 99.1996 8.09716C98.9542 7.62521 98.8314 7.05609 98.8314 6.38981C98.8314 5.5986 99.0336 4.88374 99.4379 4.24522C99.8422 3.6067 100.434 3.10004 101.214 2.72526C101.994 2.35048 102.947 2.16309 104.073 2.16309C105.719 2.16309 107.004 2.53093 107.928 3.26662C108.852 4.0023 109.408 5.00866 109.596 6.2857L106.824 6.68131C106.679 6.09831 106.383 5.6333 105.936 5.28628C105.488 4.92538 104.882 4.74493 104.116 4.74493C103.38 4.74493 102.788 4.88374 102.34 5.16135C101.893 5.43897 101.669 5.80681 101.669 6.26488C101.669 6.73683 101.885 7.13244 102.319 7.4517C102.766 7.75707 103.459 8.04857 104.398 8.32619L105.481 8.63851C106.362 8.88836 107.134 9.20068 107.798 9.57547C108.463 9.93637 108.982 10.4083 109.358 10.9913C109.733 11.5604 109.921 12.2892 109.921 13.1776C109.921 14.1909 109.654 15.0376 109.12 15.7178C108.6 16.384 107.914 16.8907 107.062 17.2377C106.21 17.5708 105.293 17.7374 104.311 17.7374Z"
      fill="white"
    />
    <path
      d="M83.9287 17.4041V2.49609H90.4265C91.9715 2.49609 93.2061 2.91252 94.1303 3.74537C95.0688 4.56434 95.5381 5.71645 95.5381 7.20171C95.5381 8.22889 95.2277 9.11033 94.6068 9.84601C94.0003 10.5678 93.2278 11.0745 92.2892 11.366L92.2676 11.5117L95.863 17.2376V17.4041H92.6358L89.1486 11.8032H86.8311V17.4041H83.9287ZM86.8311 9.22137H90.4049C91.0691 9.22137 91.6033 9.04786 92.0076 8.70084C92.412 8.33994 92.6141 7.82635 92.6141 7.16006C92.6141 6.49378 92.4264 5.98713 92.051 5.64011C91.6755 5.27921 91.1341 5.09876 90.4265 5.09876H86.8311V9.22137Z"
      fill="white"
    />
    <path
      d="M70.54 17.4041V2.49609H80.6766V5.16122H73.4424V8.32606H80.0052V10.9912H73.4424V14.739H80.6766V17.4041H70.54Z"
      fill="white"
    />
    <path
      d="M58.0017 17.4043L52.7168 2.49623H55.8574L58.933 11.491L59.4745 13.3858H59.6478L60.2109 11.491L64.4011 0H67.5634L61.099 17.4043H58.0017Z"
      fill="white"
    />
    <path
      d="M49.4065 2.59572L54.6914 17.5038H51.5508L48.4752 8.50897L47.9337 6.61423H47.7604L47.1973 8.50897L43.0071 20H39.8448L46.3092 2.59572H49.4065Z"
      fill="white"
    />
    <path
      d="M32.6547 17.4041V5.16122H27.8896V2.49609H40.3221V5.16122H35.5571V17.4041H32.6547Z"
      fill="white"
    />
    <path
      d="M0 17.4041V2.49609H5.15492C7.68185 2.49609 9.60231 3.14155 10.9163 4.43247C12.2448 5.72339 12.909 7.56261 12.909 9.95012C12.909 12.3376 12.2448 14.1768 10.9163 15.4678C9.60231 16.7587 7.68185 17.4041 5.15492 17.4041H0ZM2.90235 14.739H5.02497C6.62776 14.739 7.85512 14.3573 8.70706 13.5938C9.55899 12.8304 9.98496 11.6158 9.98496 9.95012C9.98496 8.28441 9.55899 7.06984 8.70706 6.30639C7.85512 5.54294 6.62776 5.16122 5.02497 5.16122H2.90235V14.739Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9227 2.57275H22.9544L28.1516 17.3274H25.553L21.0488 5.52376L16.8911 17.3274H13.8594L19.4897 2.57277H19.9227L19.9227 2.57275Z"
      fill="white"
    />
  </svg>
);

SiteLogoDataverse.propTypes = {
  size: PropTypes.number,
};

SiteLogoDataverse.defaultProps = {
  size: 20,
};

export default SiteLogo;

export { SiteLogoDataverse };
