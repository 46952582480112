import { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import { ColorPalette } from "yuka";

const StyledToggleableIcon = styled.button`
  position: relative;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 0 solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  outline: none;
  margin: 0;
  padding: 0;
`;

/**
 * Displays an icon from props as a clickable element; similar to but visually different than
 * Yuka IconButton.
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const ToggleableIcon = (props) => {
  const [iconColor, setIconColor] = useState(ColorPalette.white30);

  return (
    <StyledToggleableIcon
      onClick={props.onClick}
      disabled={props.disabled}
      onMouseOver={() => setIconColor(ColorPalette.white80)}
      onMouseLeave={() => setIconColor(ColorPalette.white30)}
    >
      <props.icon size={props.size} color={iconColor} />
    </StyledToggleableIcon>
  );
};

ToggleableIcon.propTypes = {
  size: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

ToggleableIcon.defaultProps = {
  size: 16,
  disabled: false,
  onClick: _.noop,
};

export default ToggleableIcon;
