import React, { useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import { Modal, FontColors, StyledCaption2, List, ListItem } from "yuka";

import { INDICATOR_TYPES } from "./constants";

const StyledModalContent = styled.div`
  width: 600px;
  margin: 0 -16px;
`;

const StyledListItem = styled(ListItem)`
  ${(props) => props.$disabled && FontColors.theme30}
`;

const StyledTheme50 = styled.span`
  ${FontColors.theme50}
`;

const AddIndicatorModal = (props) => {
  const getTrailingContent = useCallback(
    (indicator) => {
      if (_.includes(props.selectedIndicators, indicator.name)) {
        return <StyledCaption2>Added</StyledCaption2>;
      }

      return _.includes(props.noDataIndicators, indicator.name) ? (
        <StyledCaption2>No data available</StyledCaption2>
      ) : null;
    },
    [props.selectedIndicators, props.noDataIndicators]
  );

  return (
    <Modal
      title={
        <span>
          Add Indicator <StyledTheme50>for</StyledTheme50> {props.companyName}
        </span>
      }
      onClose={() => props.setModal(null)}
    >
      <StyledModalContent>
        <List divider>
          {_.map(INDICATOR_TYPES, (indicator) => (
            <StyledListItem
              $disabled={_.includes(props.noDataIndicators, indicator.name)}
              key={indicator.name}
              onClick={
                _.includes(props.noDataIndicators, indicator.name)
                  ? null
                  : () => props.onSelectIndicator(indicator.name)
              }
              text={indicator.label}
              trailingContent={getTrailingContent(indicator)}
            />
          ))}
        </List>
      </StyledModalContent>
    </Modal>
  );
};

AddIndicatorModal.propTypes = {
  setModal: PropTypes.func.isRequired,
  onSelectIndicator: PropTypes.func.isRequired,
  selectedIndicators: PropTypes.arrayOf(PropTypes.string).isRequired,
  noDataIndicators: PropTypes.arrayOf(PropTypes.string).isRequired,
  companyName: PropTypes.string.isRequired,
};

export default AddIndicatorModal;
