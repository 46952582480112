import { useRef, useEffect, useMemo, useCallback, useState } from "react";
import styled from "styled-components";
import { createChart, CrosshairMode, LineStyle } from "lightweight-charts";
import { ColorPalette } from "yuka";
import PropTypes from "prop-types";
import _ from "lodash";

import roundDecimal from "/src/utils/roundDecimal";

import Legend from "./SuperchartLegend";

import {
  KEY_COMPANY_COLOR,
  CHART_RIGHT_OFFSET_MULTIPLIER,
  SERIES_TYPES,
  INDICATOR_COLORS,
} from "./constants";

const TRANSPARENT = "rgba(0, 0, 0, 0)";
const LINE_WIDTH = 1;

const ONE_MILLION = 1000000;

const StyledChartContainer = styled.div`
  position: relative;
  height: 100%;

  // lightweight-charts does not underline the price labels axis container, this does that
  table {
    border-collapse: collapse;
    > :first-child {
      border-bottom: 1px solid ${ColorPalette.white15};
    }
  }
`;

/**
 * Renders the Superchart graph. Uses the lightweight-charts library to render a line chart
 * with the provided data on the key company selected, the indicators toggled, and the comparisons
 * added.
 *
 * This component mainly sets up the lightweight-charts object and configures
 * the options to our specs, and also saves some of the library data to React state in order
 * to set up the functions required for the legend of the chart.
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const SuperchartGraph = (props) => {
  const chartContainerRef = useRef();
  const chartSeries = useRef({}); // stores the lightweight-charts line series objects
  const visibilityBySeries = useRef({});
  const [hoverDate, setHoverDate] = useState(null);

  const volumeSeriesData = useMemo(
    () =>
      _.map(props.primaryCompany.data, (datum) => ({
        time: datum.time,
        value: datum.volume * ONE_MILLION,
      })),
    [props.primaryCompany.data]
  );

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    const getRightOffset = () => {
      // Offset is done in terms of the granularity of the data currently graphed, so we calculate
      // a fixed percentage of the largest price dataset
      const biggestDataSetLength = _.max([
        ..._.map(props.comparisons, (comp) => comp.data.length),
        props.primaryCompany.data.length,
      ]);

      return biggestDataSetLength * CHART_RIGHT_OFFSET_MULTIPLIER;
    };

    const priceFormatter = (num) =>
      _.isEmpty(props.comparisons)
        ? `$${roundDecimal(num)}`
        : `${roundDecimal(num)}%`;

    const chart = createChart(chartContainerRef.current, {
      autoSize: true,
      layout: {
        background: { color: TRANSPARENT },
        textColor: ColorPalette.faintWhite,
      },
      grid: {
        horzLines: {
          color: ColorPalette.white05,
        },
        vertLines: {
          visible: false,
        },
      },
      rightPriceScale: {
        borderVisible: false,
      },
      handleScroll: false,
      handleScale: false,
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      timeScale: {
        rightOffset: getRightOffset(),
        borderVisible: false,
      },
    });
    chart.subscribeCrosshairMove((param) => {
      if (param.time) {
        setHoverDate(param.time);
      } else {
        setHoverDate(null);
      }
    });
    chart.timeScale().fitContent();

    const createLineSeries = (
      { id, name, color, data },
      lineVisible = false
    ) => {
      const series = chart.addLineSeries({
        color: color,
        lineWidth: LINE_WIDTH,
        //disabling built-in price lines
        lastValueVisible: false,
        priceLineVisible: false,
        // If a comp is set to invisible and then the chart is redrawn, invisibility remains
        visible:
          id in visibilityBySeries.current
            ? visibilityBySeries.current[id]
            : true,
        priceFormat: { type: "custom", formatter: priceFormatter },
      });
      series.setData(data);

      const priceLine = {
        color: ColorPalette.blue300,
        axisLabelColor: color,
        lineWidth: LINE_WIDTH,
        lineStyle: LineStyle.Dashed,
        lineVisible,
        axisLabelVisible: true,
        title: _.toUpper(name),
        price: _.get(_.last(data), "value"),
      };
      series.createPriceLine(priceLine);

      return series;
    };

    // props.primaryCompany comes with both its absolute ($) price data and its % data, use the
    // former if there are no comparisons to chart
    const keyCompanyPriceData = _.map(
      props.primaryCompany.data,
      ({ time, absolute, percent }) => ({
        time,
        value: props.comparisons.length === 0 ? absolute : percent,
      })
    );

    // primary/key company line series
    createLineSeries(
      {
        ...props.primaryCompany,
        data: keyCompanyPriceData,
        color: ColorPalette.accent,
      },
      true
    );

    // key company indicator line series
    _.forEach(props.indicators, (indicator, index) => {
      chartSeries.current[indicator.id] = createLineSeries({
        id: indicator.id,
        name: "", // indicators are not named on the graph
        color: INDICATOR_COLORS[index % INDICATOR_COLORS.length],
        data: _.map(indicator.data, ({ time, absolute, percent }) => ({
          time,
          value: props.comparisons.length === 0 ? absolute : percent,
        })),
      });
    });

    _.forEach(props.comparisons, (comp) => {
      chartSeries.current[comp.id] = createLineSeries({
        ...comp,
        data: _.map(comp.data, ({ time, percent }) => ({
          time,
          value: percent,
        })),
      });
    });

    // volume histogram
    if (props.displayVolume) {
      const volumeSeries = chart.addHistogramSeries({
        scaleMargins: { top: 0.75, bottom: 0 },
        priceFormat: { type: "volume" },
        priceScaleId: "",
        // apply 20% opacity
        color: `${KEY_COMPANY_COLOR}30`,
      });
      volumeSeries.priceScale().applyOptions({
        scaleMargins: { top: 0.75, bottom: 0 },
      });
      volumeSeries.setData(volumeSeriesData);
    }

    window.addEventListener("resize", handleResize);

    /**
     * Cleanup function for the chart creation useEffect; removes the current chart in preparation
     * for when a new chart is created with new data on a later component render.
     *
     * VERY IMPORTANT:
     *
     * Because of the nature of this create/destroy cycle, any React state or side
     * effects that have anything to do with lightweight-charts data will almost certainly have to
     * be cleaned up here--otherwise you run the risk of getting a cryptic error down the road.
     */
    return () => {
      window.removeEventListener("resize", handleResize);
      chartSeries.current = {};
      chart.remove();
    };
  }, [props.primaryCompany, props.comparisons, props.indicators]);

  const setVisibility = useCallback(
    (seriesId, visible) => {
      if (seriesId in chartSeries.current) {
        chartSeries.current[seriesId].applyOptions({ visible });
        visibilityBySeries.current[seriesId] = visible;
      }
    },
    [props.comparisons.length]
  );

  const removeSeries = useCallback(
    (type, seriesId) => {
      const removeSeries =
        type === SERIES_TYPES.INDICATOR
          ? props.removeIndicator
          : props.removeComp;
      removeSeries(seriesId);
      delete visibilityBySeries.current[seriesId];
    },
    [props.comparisons, props.indicators]
  );

  return (
    <StyledChartContainer ref={chartContainerRef}>
      <Legend
        keyCompany={props.primaryCompany}
        indicators={props.indicators}
        comparisons={props.comparisons}
        hoverDate={hoverDate}
        setVisibility={setVisibility}
        removeSeries={removeSeries}
      />
    </StyledChartContainer>
  );
};

SuperchartGraph.propTypes = {
  primaryCompany: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        time: PropTypes.string,
        absolute: PropTypes.number,
        percent: PropTypes.number,
        robustness: PropTypes.number,
        volume: PropTypes.number,
      })
    ),
  }).isRequired,
  comparisons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          time: PropTypes.string,
          value: PropTypes.number,
        })
      ),
      color: PropTypes.string,
      exchange: PropTypes.string,
    })
  ),
  indicators: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          time: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    })
  ),
  removeComp: PropTypes.func.isRequired,
  removeIndicator: PropTypes.func.isRequired,
  displayVolume: PropTypes.bool,
};

SuperchartGraph.defaultProps = {
  comparisons: [],
  displayVolume: false,
};

export default SuperchartGraph;
