import { useFormState } from "react-final-form";
import React, { useMemo } from "react";
import PropTypes from "prop-types";

import ValuationPriceComponentRow from "./ValuationPriceComponentRow";
import useLatestHDFundingRound from "./useLatestHDFundingRound";
import useNoDataErrorState from "./useNoDataErrorState";
import { useComponent } from "./useComponents";

const FundingRoundPriceComponentRow = (props) => {
  const { values } = useFormState();
  const { currentValue } = useComponent(values, props.id);

  const [hdFundingRound, hdFundingRoundIsLoading] = useLatestHDFundingRound(
    props.companyId,
    values.valuation_date,
    !props.initialValue
  );

  const computedValue = useMemo(
    () =>
      props.initialValue
        ? props.initialValue
        : Number(hdFundingRound?.price_per_share) || null,
    [props.initialValue, hdFundingRound?.price_per_share]
  );

  const error = useNoDataErrorState(
    props.initialValue || !values.valuation_date || hdFundingRoundIsLoading,
    computedValue || currentValue
  );

  return (
    <ValuationPriceComponentRow
      {...props}
      errorText={error}
      editable={!props.initialValue}
      initialValue={computedValue}
    />
  );
};

FundingRoundPriceComponentRow.propTypes = {
  id: PropTypes.number,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  companyId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FundingRoundPriceComponentRow;
