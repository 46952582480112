import styled, { css } from "styled-components";
import { FontColors } from "yuka";

import { EMPTY_VALUE_PLACEHOLDER } from "../constants";
import PropTypes from "prop-types";

const StyledEventName = styled.span`
  ${({ $isSubRound }) =>
    $isSubRound
      ? css`
          padding-left: 16px;
          ${FontColors.theme50}
        `
      : css``}
`;

const FundingRoundEventCellRenderer = ({ value: fundingRound }) => {
  return (
    <StyledEventName $isSubRound={fundingRound.parent_funding_round}>
      {fundingRound.event || EMPTY_VALUE_PLACEHOLDER}
    </StyledEventName>
  );
};

FundingRoundEventCellRenderer.propTypes = {
  value: PropTypes.shape({
    event: PropTypes.string,
    parent_funding_round: PropTypes.shape({}),
  }).isRequired,
};

export default FundingRoundEventCellRenderer;
