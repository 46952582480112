import { useEffect } from "react";
import PropTypes from "prop-types";

import { GraphContainer } from "./StyledComponents";
import OverallTradingPriceGraph from "./OverallTradingPriceGraphView";
import VolumeGraph from "./VolumeGraphView";

import {
  ORDER_FLOW_TIME_FRAME_MONTHLY,
  ORDER_FLOW_TIME_FRAME_WEEKLY,
} from "../../utils/constants";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";

/**
 * Renders the "Trading Price" graphs in Company Profile.
 */
const VolumeTradingGraphView = (props) => {
  useEffect(() => {
    if (props.companyName) {
      MixpanelEvents.viewCompanyPriceAndVolumeGraph(props.companyName);
    }
  }, [props.companyName]);

  return (
    <GraphContainer>
      <OverallTradingPriceGraph
        companyId={props.companyId}
        hasDataAccess={props.hasDataAccess}
        includeRobustnessScoreAxis
        timeFrame={props.timeFrame}
      />
      <VolumeGraph
        companyId={props.companyId}
        hasDataAccess={props.hasDataAccess}
      />
    </GraphContainer>
  );
};

VolumeTradingGraphView.propTypes = {
  companyId: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  hasDataAccess: PropTypes.bool,
  timeFrame: PropTypes.oneOf([
    ORDER_FLOW_TIME_FRAME_MONTHLY,
    ORDER_FLOW_TIME_FRAME_WEEKLY,
  ]),
};

VolumeTradingGraphView.defaultProps = {
  hasDataAccess: false,
  timeFrame: ORDER_FLOW_TIME_FRAME_MONTHLY,
};

export default VolumeTradingGraphView;
