import styled from "styled-components";
import {
  body2,
  caption1,
  caption2,
  FontColors,
  headline3,
  HyperLink,
} from "yuka";

const ActionSpan = styled.span`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Caption2HyperLink = styled(HyperLink)`
  ${caption2}
`;

const StyledContainer = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 450px;

  form {
    margin: 0;
  }
`;

const StyledFooter = styled.div`
  ${caption1}
  ${FontColors.theme30}
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

const StyledLoginHeader = styled.div`
  ${FontColors.theme80}
  ${headline3}
  margin-bottom: 10px;
`;

const StyledLoginHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
`;

const StyledLoginSubHeader = styled.div`
  ${body2}
  ${FontColors.theme50}
`;

export {
  ActionSpan,
  Caption2HyperLink,
  StyledContainer,
  StyledFooter,
  StyledLoginHeader,
  StyledLoginHeaderContainer,
  StyledLoginSubHeader,
};
